import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import {
	SettingsCard,
	SettingsCardTitle,
} from '@/features/settings/components/card'
import { TenantSettingsSelect } from '@/features/tenant/components/tenant-settings-select'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import QueryBoundary from '@/components/common/query-boundary'
import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import TableSkeleton from '@/components/skeletons/table-skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

const NotificationsSettings = lazy(
	() => import('@/features/notifications/components/settings'),
)

export const Route = createFileRoute('/_dashboard/settings/notifications')({
	component: NotificationsRoute,
	pendingComponent: NotificationsLoading,
	errorComponent: NotificationsError,
})

function NotificationsRoute() {
	return (
		<>
			<div className="flex flex-col items-start justify-start gap-2">
				<p className="text-muted-foreground">Notifications for:</p>
				<TenantSettingsSelect />
			</div>
			<SettingsCard className="flex-col items-start sm:flex-row">
				<SettingsCardTitle>Notifications</SettingsCardTitle>

				<QueryBoundary
					fallback={({ resetError }) => (
						<ErrorBoundaryComponent resetError={resetError} />
					)}
				>
					<Suspense fallback={<TableSkeleton rows={5} />}>
						<NotificationsSettings />
					</Suspense>
				</QueryBoundary>
			</SettingsCard>
		</>
	)
}

function NotificationsLoading() {
	return (
		<>
			<div className="flex flex-col items-start justify-start gap-2">
				<p className="text-muted-foreground">Notifications for:</p>
				<TenantSettingsSelect />
			</div>
			<SettingsCard className="flex-col items-start sm:flex-row">
				<SettingsCardTitle>Notifications</SettingsCardTitle>

				<TableSkeleton rows={5} />
			</SettingsCard>
		</>
	)
}

function NotificationsError({ error }: ErrorComponentProps) {
	const err = error as XiorError

	const { reset } = useErrorBoundary()

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<>
			<div className="flex flex-col items-start justify-start gap-2">
				<p className="text-muted-foreground">Notifications for:</p>
				<TenantSettingsSelect />
			</div>
			<SettingsCard className="flex-col items-start sm:flex-row">
				<SettingsCardTitle>Notifications</SettingsCardTitle>

				<ErrorBoundaryComponent resetError={reset} />
			</SettingsCard>
		</>
	)
}
