import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'

import { useAuthNextSteps } from '@/features/auth/hooks/useAuthNextSteps'
import { useAuthForgotPassword } from '@/features/auth/mutations'

import { LoadingButton } from '@/components/buttons/loading-button'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { schema } from './validation'

import type { ForgotPasswordFormValues } from './validation'

export const ForgotPasswordForm = () => {
	const [isLoading, setIsLoading] = useState(false)

	const { mutate: resetPassword } = useAuthForgotPassword()
	const { handleAuthNextStep } = useAuthNextSteps()

	const form = useForm<ForgotPasswordFormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			email: '',
		},
	})

	const hasErrors = Object.keys(form.formState.errors).length > 0

	const onSubmit = form.handleSubmit(async (data) => {
		// Prevent multiple form submissions
		if (isLoading) return

		const email = data?.email

		setIsLoading(true)

		resetPassword(
			{
				email,
			},
			{
				onSuccess: (responseData) =>
					handleAuthNextStep(responseData, {
						email,
					}),
				onSettled: () => {
					setIsLoading(false)
				},
				onError: (error) => {
					form.setError('email', {
						type: 'custom',
						message: error.message,
					})
				},
			},
		)
	})

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit}
				className="my-4 flex w-full flex-col items-start justify-center"
			>
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem className="mb-6 w-full">
							<FormControl>
								<Input
									type="email"
									{...field}
									placeholder="Email address"
									className="bg-transparent px-4 py-6 aria-[invalid=true]:border-destructive"
									data-testid="email"
									autoFocus
									required
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<LoadingButton
					isLoading={isLoading}
					data-testid="form-button"
					type="submit"
					disabled={hasErrors}
					variant="outline"
					className="w-full bg-bl-primary text-white hover:bg-bl-primary hover:bg-opacity-65"
				>
					Get New Password
				</LoadingButton>
			</form>
		</Form>
	)
}
