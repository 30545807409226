import { useState } from 'react'

import { periodToDateRange } from '@/lib/date'

import DatePickerWithRange from '@/components/common/date-range-picker'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'

import { useWidgetActions, useWidgetPeriod } from '../store'
import { periods } from '../types'
import { getPeriodLabel } from '../utils'

import type { Period } from '../types'
import type { DateRange } from 'react-day-picker'

type PeriodSelectProps = {
	start_time: string
	end_time: string
	onCustomPeriodChange: (start_time: string, end_time: string) => void
}
export const PeriodSelect = ({
	start_time,
	end_time,
	onCustomPeriodChange,
}: PeriodSelectProps) => {
	const period = useWidgetPeriod()

	const [userPeriod, setUserPeriod] = useState<DateRange | undefined>(undefined)

	const { setPeriod, setFilters } = useWidgetActions()

	const periodOptions: Period[] = [
		periods.Values.LAST_7_DAYS,
		periods.Values.LAST_30_DAYS,
		periods.Values.LAST_60_DAYS,
		periods.Values.LAST_90_DAYS,
		periods.Values.LAST_180_DAYS,
		periods.Values.CUSTOM,
	]

	const handlePeriodChange = (period: Period) => {
		setPeriod(period)

		switch (period) {
			case periods.Values.CUSTOM:
				onCustomPeriodChange(
					userPeriod?.from?.toISOString() ?? '',
					userPeriod?.to?.toISOString() ?? '',
				)
				setFilters({
					start_time:
						userPeriod?.from?.toISOString() ??
						periodToDateRange('LAST_7_DAYS').start.toISOString(),
					end_time:
						userPeriod?.to?.toISOString() ??
						periodToDateRange('LAST_7_DAYS').end.toISOString(),
				})
				break
			default:
				onCustomPeriodChange(
					periodToDateRange(period).start.toISOString(),
					periodToDateRange(period).end.toISOString(),
				)
				break
		}
	}

	return (
		<div className="flex w-full flex-col gap-2 sm:flex-row">
			<Select onValueChange={handlePeriodChange} value={period}>
				<SelectTrigger className="w-full sm:w-[200px]">
					<SelectValue placeholder="Period" defaultValue={period} />
				</SelectTrigger>
				<SelectContent>
					{periodOptions.map((period) => (
						<SelectItem key={period} value={period}>
							{getPeriodLabel(period)}
						</SelectItem>
					))}
				</SelectContent>
			</Select>
			{period === 'CUSTOM' && (
				<DatePickerWithRange
					startDate={start_time ?? periodToDateRange('LAST_7_DAYS').start}
					endDate={end_time ?? periodToDateRange('LAST_7_DAYS').end}
					onUpdate={(date) => {
						setFilters({
							start_time: date?.from?.toISOString(),
							end_time: date?.to?.toISOString(),
						})
						setUserPeriod(date)
						onCustomPeriodChange(
							date?.from?.toISOString() ?? '',
							date?.to?.toISOString() ?? '',
						)
					}}
					className="w-full"
				/>
			)}
		</div>
	)
}
