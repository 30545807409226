import { lazy } from 'react'

import { createFileRoute, Outlet } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'

import { z } from 'zod'

import { activityQueries } from '@/features/activity/queries'
import { ACTIVITY_STATUS } from '@/features/activity/types'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const UnauthorizedContent = lazy(
  () => import('@/components/environment/unauthorized-content'),
)

const searchSchema = z.object({
  q: z.string().optional(),
  filters: z
    .object({
      status: ACTIVITY_STATUS.optional(),
      type: z.enum(['playbook', 'standalone']).optional(),
    })
    .optional(),
  action: z.string().optional(),
  page: z.number().optional(),
})

export const Route = createFileRoute('/_dashboard/activity')({
  validateSearch: zodValidator(searchSchema),

  loader: async ({ context: { queryClient } }) => {
    const tenant = getCurrentTenant() as string

    queryClient.prefetchInfiniteQuery(activityQueries.list(tenant))
  },

  component: ActionsActivityRoute,
  pendingComponent: ActionsActivityRouteSkeleton,
  errorComponent: ActionsActivityError,
})

/**
 * Actions Activity Route
 */
function ActionsActivityRoute() {
  return (
    <Section>
      <title>Activity | BitLyft Air®</title>
      <SectionTitle>Activity</SectionTitle>
      <div className="flex flex-col gap-4">
        <Outlet />
      </div>
    </Section>
  )
}

/**
 * Loading state for Actions Activity
 */
function ActionsActivityRouteSkeleton() {
  return (
    <Section>
      <title>Activity | BitLyft Air®</title>
      <SectionTitle>Activity</SectionTitle>
      {/*  Search Bar Skeleton */}
      <Skeleton className="my-2 h-12 w-full" />

      {/*  Table Skeleton */}
      <TableSkeleton rows={24} />
      <TableSkeleton />
    </Section>
  )
}

/**
 * Error component for Actions Activity
 */
function ActionsActivityError({ error }: ErrorComponentProps) {
  const { reset } = useErrorBoundary()
  const err = error as XiorError

  // Show default unauthorized content if the error is 401
  if (err.response?.status === 401) {
    return <UnauthorizedContent />
  }

  return <ErrorBoundaryComponent resetError={reset} />
}
