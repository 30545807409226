import { useState } from 'react'

import { toast } from 'sonner'

import { useHandle449Error } from '@/features/confirmation-dialog/hooks/useHandleConfirmationErrors'

import { LoadingButton } from '@/components/buttons/loading-button'

import { useDeleteMe } from '../mutations'

export const DeleteAccount = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const { mutate: deleteMe } = useDeleteMe()

	const handle449Error = useHandle449Error()

	const handleDeleteAccount = async () => {
		setIsLoading(true)

		deleteMe(undefined, {
			onSuccess: () => {
				setIsLoading(false)
				toast('Account deleted successfully!')
			},
			onError: (error) => {
				handle449Error(error, (headers) => {
					setIsLoading(true)

					// Retry the mutation with confirmation headers
					deleteMe(headers, {
						onSuccess: () => {
							setIsLoading(false)
							toast('Account deleted successfully!')
						},
					})
				})

				setIsLoading(false)
			},
			onSettled: () => {
				setIsLoading(false)
			},
		})
	}

	return (
		<div>
			<LoadingButton
				isLoading={isLoading}
				type="button"
				variant="destructive"
				className="flex items-center justify-center rounded-md"
				onClick={handleDeleteAccount}
			>
				Delete Account
			</LoadingButton>

			<p className="my-2 text-sm text-muted-foreground">
				This action is irreversible.
			</p>
		</div>
	)
}
