import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'

import AlertsFilters from '@/features/graylog/alerts/components/filters'
import { graylogQueries } from '@/features/graylog/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { GridSkeleton } from '@/components/skeletons/grid-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

const WidgetList = lazy(
	() => import('@/features/graylog/alerts/components/widget-list'),
)

export const Route = createFileRoute('/_dashboard/graylog/alerts')({
	loader: async ({ context: { queryClient } }) => {
		const tenant = getCurrentTenant() as string

		queryClient.prefetchQuery(graylogQueries.triggeredAlerts(tenant))
	},

	component: AlertsRoute,
	pendingComponent: AlertsRouteSkeleton,
	errorComponent: AlertsRouteError,
})

function AlertsRoute() {
	return (
		<div className="flex w-full flex-col items-start justify-start">
			<title>Alerts | Graylog | BitLyft Air®</title>

			<Suspense fallback={<Skeleton className="mb-2 h-[70px] w-full" />}>
				<AlertsFilters />
			</Suspense>

			<ErrorBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<GridSkeleton length={6} />}>
					<WidgetList />
				</Suspense>
			</ErrorBoundary>
		</div>
	)
}

function AlertsRouteSkeleton() {
	return (
		<div className="grid grid-cols-1 gap-2 p-2 sm:grid-cols-2 md:grid-cols-3">
			{Array.from({ length: 6 }).map((_, i) => (
				<Skeleton key={i} className="my-2 w-full" />
			))}
		</div>
	)
}

function AlertsRouteError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()

	const err = error as XiorError

	if (err.response?.status === 403) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
