import { api } from '@/lib/api'

import { type NotificationsDTO, type NotificationsFilters } from './types'

import type {
	NotificationDetailsDTO,
	NotificationPreferences,
	NotificationPreferencesDTO,
} from './types'

/**
 * Fetch the current user's notifications
 * @param {NotificationsFilters} filters - Filters to apply to the notifications
 */
export const fetchNotifications = async (filters?: NotificationsFilters) => {
	const baseURL = '/me/notifications/list'

	const searchParams = new URLSearchParams()

	// We only want to fetch dashboard notifications
	searchParams.set('notification_type', 'dashboard')
	searchParams.set('page_size', '100')

	if (filters) {
		Object.entries(filters).forEach(([key, value]) => {
			if (key) {
				searchParams.set(key, value.toString())
			}
		})
	}

	const { data } = await api.get<NotificationsDTO>(
		`${baseURL}?${searchParams.toString()}`,
	)

	return data
}

/**
 * Fetch the current user's notification details
 * @param {string} notificationId - The ID of the notification to fetch
 */
export const fetchNotificationDetails = async (notificationId: string) => {
	const { data } = await api.get<NotificationDetailsDTO>(
		`/me/notifications/full/${notificationId}?metadata_format=friendly`,
	)

	return data
}

/**
 * Mark notifications as read
 * @param {string[]} notificationIds - The IDs of the notifications to mark as read
 */
export const markNotificationsAsRead = async (notificationIds: string[]) => {
	const { data } = await api.post<{ marked_read_successfully: string[] }>(
		`/me/notifications/mark_read`,
		{
			notification_ids: notificationIds,
		},
	)

	return data
}

/**
 * Fetch the current user's notification preferences
 * @param {string} tenant - The tenant to fetch the preferences for
 */
export const fetchNotificationPreferences = async (tenant: string) => {
	const { data } = await api.get<NotificationPreferencesDTO>(
		`/me/notifications/preferences?tenant=${tenant}`,
	)

	return data
}

/**
 * Update the current user's notification preferences
 * @param {NotificationPreferences} preferences - The new notification preferences
 */
export const updateNotificationPreferences = async (
	preferences: NotificationPreferences[],
) => {
	const { data } = await api.post<string>('/me/notifications/preferences', {
		preferences,
	})

	return data
}
