import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import { useWidgetFilters } from './alerts/store'
import {
	fetchAutomationSettingsList,
	fetchEventSources,
	fetchGraylogAutomationSettingsDetails,
	fetchGraylogEvents,
	fetchGraylogTriggeredAlerts,
} from './api'

import type { TriggerAlertFilters } from './types'

/**
 * Query keys for Graylog
 */
export const graylogKeys = {
	all: ['graylog'] as const,
	events: (tenant: string) => [...graylogKeys.all, 'events', tenant] as const,
	sources: (tenant: string) =>
		[...graylogKeys.events(tenant), 'sources'] as const,
	triggeredAlerts: (tenant: string, filters?: TriggerAlertFilters) =>
		[...graylogKeys.all, 'triggeredAlerts', tenant, filters] as const,
	automations: () => [...graylogKeys.all, 'automations'] as const,
	details: () => [...graylogKeys.automations(), 'details'] as const,
	detail: (tenant: string, rule_id: string | null) =>
		[...graylogKeys.details(), tenant, rule_id] as const,
	settings: (tenant: string) =>
		[...graylogKeys.automations(), tenant, 'settings'] as const,
}

/**
 * Query options for Graylog
 */
export const graylogQueries = {
	events: (tenant: string) =>
		queryOptions({
			queryKey: graylogKeys.events(tenant),
			queryFn: () => fetchGraylogEvents(tenant),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 30, 'm'),
		}),

	triggeredAlerts: (tenant: string, filters?: TriggerAlertFilters) =>
		queryOptions({
			queryKey: graylogKeys.triggeredAlerts(tenant, filters),
			queryFn: () => fetchGraylogTriggeredAlerts(tenant, filters),
			retry: defaultQueryRetry,
			refetchInterval: time(5, 'm'),
			gcTime: time(10, 'm'),
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
		}),

	automationDetail: (tenant: string, rule_id: string | null) =>
		queryOptions({
			queryKey: graylogKeys.detail(tenant, rule_id),
			queryFn: () => fetchGraylogAutomationSettingsDetails(tenant, rule_id),
			retry: defaultQueryRetry,
			gcTime: time(30, 'm'),
			staleTime: ({ state }) => successStaleTime(state, 15, 'm'),
			enabled: !!tenant && !!rule_id,
		}),

	automationSettings: (tenant: string) =>
		queryOptions({
			queryKey: graylogKeys.settings(tenant),
			queryFn: () => fetchAutomationSettingsList(tenant),
			retry: defaultQueryRetry,
			gcTime: time(10, 'm'),
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
		}),

	eventSources: (tenant: string) =>
		queryOptions({
			queryKey: graylogKeys.sources(tenant),
			queryFn: () => fetchEventSources(tenant),
			retry: defaultQueryRetry,
			gcTime: time(10, 'm'),
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
		}),
}

/**
 * Custom hook to fetch Graylog events using ReactQuery
 */
export const useGraylogEvents = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(graylogQueries.events(tenant))
}

/**
 * Custom hook to fetch Triggered Alerts from Graylog
 */
export const useGraylogTriggeredAlerts = () => {
	const tenant = useCurrentTenant()
	const filters = useWidgetFilters()

	return useSuspenseQuery(graylogQueries.triggeredAlerts(tenant, filters))
}

/**
 * Custom hook to fetch Graylog Automation Settings Details
 */
export const useAutomationDetails = (rule_id: string | null) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(graylogQueries.automationDetail(tenant, rule_id))
}

/**
 * Custom hook to fetch Graylog Automation Settings
 */
export const useAutomationSettings = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(graylogQueries.automationSettings(tenant))
}

/**
 * Custom hook to fetch Graylog Event Sources
 */
export const useEventSources = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(graylogQueries.eventSources(tenant))
}
