import { useIntegrationList } from '../integrations/queries'
import { getIntegrationId } from '../integrations/utils'

import { useAutomationSettings } from './queries'

/**
 * Custom hook to fetch the Graylog instance name
 * @returns {string | null} The friendly name of the Graylog integration
 */
export const useGraylogInstance = () => {
	// Get the user list of integrations
	const { data: integrations } = useIntegrationList()

	// Get the Graylog integration ID
	const graylogIntegrationID = getIntegrationId('graylog')

	// Check if the user has a Graylog integration
	const isGraylog = integrations?.some(
		(integration) => integration.definition_id === graylogIntegrationID,
	)

	// If the user does not have a Graylog integration, return null
	if (!isGraylog) {
		return null
	}

	// Return the friendly name of the Graylog integration
	return (
		integrations?.find(
			(integration) => integration.definition_id === graylogIntegrationID,
		) ?? null
	)
}

/**
 * Custom hook to fetch the Graylog mapped actions
 *
 * Get the list of events mapped to this action
 *
 * @param action - The action name
 * @returns The events on this action
 */
export const useGraylogMappedAction = (action: string) => {
	const { data: automations } = useAutomationSettings()

	const eventsOnThisPlaybook = automations?.filter((automation) => {
		return automation.actions?.responses?.[0]?.playbook_name === action
	})

	const eventsOnThisAction = automations?.filter((automation) => {
		return automation.actions?.responses?.[0]?.action_name === action
	})

	return { eventsOnThisPlaybook, eventsOnThisAction }
}

/**
 * Custom hook to fetch the Graylog mapped events
 *
 * Get the list of actions mapped to this event
 *
 * @param event_id - The event ID
 * @returns The actions on this event
 */
export const useGraylogMappedEvents = (event_id?: string) => {
	const { data: automations } = useAutomationSettings()

	if (!event_id) return []

	const actionsOnThisEvent = automations?.filter(
		(automation) => automation.event_category_id === event_id,
	)

	return actionsOnThisEvent
}
