import { QRCodeSVG } from 'qrcode.react'

import { getRouteApi, Link } from '@tanstack/react-router'

import { CopyButton } from '@/components/buttons/copy-button'
import { Button } from '@/components/ui/button'

const Route = getRouteApi('/_auth')

export const SetupOTPForm = () => {
	const { sharedSecret, totpSecret } = Route.useSearch({
		select: (search) => ({
			sharedSecret: search?.sharedSecret,
			totpSecret: search?.totpSecret,
		}),
	})

	return (
		<div className="my-4 flex w-full flex-col items-center justify-center">
			<QRCodeSVG
				value={totpSecret as string}
				size={128}
				bgColor="#1f2023"
				fgColor="#ffffff"
			/>

			{sharedSecret && (
				<div className="my-4 flex flex-col items-center justify-center text-center">
					<p className="mb-2 text-sm font-light leading-5 text-text-secondary">
						Or use the manual entry method:
					</p>
					<div className="relative flex flex-row items-center justify-center gap-2 rounded-md bg-background p-2 px-4 font-mono text-sm">
						<p className="max-w-72 truncate">{sharedSecret}</p>
						<CopyButton text={sharedSecret} />
					</div>
				</div>
			)}

			<Button
				type="button"
				className="mt-4 w-full bg-bl-primary text-white hover:bg-bl-primary hover:bg-opacity-65"
				asChild
			>
				<Link to="/totp">Continue</Link>
			</Button>

			<Button
				variant="ghost"
				type="button"
				className="mt-2 w-full rounded-md bg-slate-600 bg-opacity-100 px-6 py-3 text-white transition-all duration-200 hover:bg-opacity-60"
				asChild
			>
				<Link to="/login">Back</Link>
			</Button>
		</div>
	)
}
