import {
	infiniteQueryOptions,
	keepPreviousData,
	queryOptions,
	useQuery,
	useSuspenseInfiniteQuery,
	useSuspenseQuery,
} from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import { fetchActivities, fetchActivityDetails, getActionStatus } from './api'

/**
 * Query keys for Activity
 * - list: Fetch all actions
 * - details: Fetch a single action
 * - status: Fetch the status of an action, should use polling
 */
export const activityKeys = {
	all: ['activity'] as const,
	lists: () => [...activityKeys.all, 'lists'] as const,
	list: (tenant: string, status?: string, type?: 'adhoc' | 'workflow') =>
		[...activityKeys.lists(), tenant, status, type, 'no-cache'] as const,
	details: (tenant: string) =>
		[...activityKeys.all, 'details', tenant] as const,
	detail: (tenant: string, action_uuid: string | null) =>
		[...activityKeys.details(tenant), action_uuid, 'no-cache'] as const,
	status: (tenant: string, action_uuid: string | null) =>
		[
			...activityKeys.detail(tenant, action_uuid),
			'status',
			'no-cache',
		] as const,
}

/**
 * Query Options for Activity
 * - status: Fetch the status of an action, should use polling
 */
export const activityQueries = {
	list: (tenant: string, status?: string, type?: 'adhoc' | 'workflow') =>
		infiniteQueryOptions({
			queryKey: activityKeys.list(tenant, status, type),
			queryFn: ({ pageParam }) =>
				fetchActivities(tenant, pageParam, status, type),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 1, 'm'),
			gcTime: time(5, 'm'),
			initialPageParam: 1,
			placeholderData: keepPreviousData,
			getNextPageParam: (lastPage, _all, lastPageParam) => {
				if (lastPage.length === 0) {
					return undefined
				}
				return lastPageParam + 1
			},
			getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => {
				if (firstPageParam <= 1) {
					return undefined
				}
				return firstPageParam - 1
			},
		}),

	detail: (tenant: string, action_uuid: string | null) =>
		queryOptions({
			queryKey: activityKeys.detail(tenant, action_uuid),
			queryFn: () => fetchActivityDetails(tenant, action_uuid),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 1, 'm'),
			gcTime: time(5, 'm'),
		}),

	status: (tenant: string, action_uuid: string | null) =>
		queryOptions({
			queryKey: activityKeys.status(tenant, action_uuid),
			queryFn: () => getActionStatus(tenant, action_uuid),
			staleTime: ({ state }) => successStaleTime(state, 15, 'm'),
			gcTime: time(15, 'm'),

			// Refetch every 4 seconds if the action is not complete
			refetchInterval: ({ state: { data } }) => {
				// Is considered complete if the status is success or error
				const isComplete =
					data?.summary?.status !== 'success' &&
					data?.summary?.status !== 'error'

				// If the action is complete, don't refetch
				return isComplete ? time(4, 's') : 0
			},
		}),
}

/**
 * Custom hook to fetch activity logs using ReactQuery
 * @param {string | undefined} tenant - The tenant ID
 * @param {function} select - Optional function to select a subset of data
 */
export const useActivities = (status?: string, type?: 'adhoc' | 'workflow') => {
	const tenant = useCurrentTenant()

	return useSuspenseInfiniteQuery(activityQueries.list(tenant, status, type))
}

/**
 * Custom hook to fetch action activity details using ReactQuery
 * @param {string | null} identifier - The identifier of the activity
 */
export const useActivityDetails = (identifier: string | null) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(activityQueries.detail(tenant as string, identifier))
}

/**
 * Custom hook to fetch the status of an action every 4 seconds if it is not complete
 * @param tenant - The tenant to fetch the action status for
 * @param action_uuid - The UUID of the action to fetch the status for
 * @returns The query result
 */
export const useActivityStatus = (
	tenant: string,
	action_uuid: string | null,
	enabled: boolean = false,
) =>
	useQuery({
		...activityQueries.status(tenant, action_uuid),
		enabled: !!action_uuid && enabled,
	})
