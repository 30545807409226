import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import {
	fetchNotificationDetails,
	fetchNotificationPreferences,
	fetchNotifications,
} from './api'

import type { NotificationsFilters } from './types'

export const notificationsKeys = {
	all: ['notifications'] as const,
	lists: () => [...notificationsKeys.all, 'list'] as const,
	list: (filters?: NotificationsFilters) =>
		[...notificationsKeys.lists(), filters, 'no-cache'] as const,
	details: () => [...notificationsKeys.all, 'details'] as const,
	detail: (notificationId: string) =>
		[...notificationsKeys.details(), notificationId] as const,
	preferences: () => [...notificationsKeys.all, 'preferences'] as const,
	preference: (tenant: string) =>
		[...notificationsKeys.preferences(), tenant] as const,
}

export const notificationsQuery = {
	list: (filters?: NotificationsFilters) =>
		queryOptions({
			queryKey: notificationsKeys.list(filters),
			queryFn: () => fetchNotifications(filters),
			staleTime: ({ state }) => successStaleTime(state, 1, 'm'),
			gcTime: time(2, 'm'),
			throwOnError: false,
			refetchInterval: time(30, 's'),
		}),

	detail: (notificationId: string, enabled: boolean = false) =>
		queryOptions({
			queryKey: notificationsKeys.detail(notificationId),
			queryFn: () => fetchNotificationDetails(notificationId),
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
			gcTime: time(10, 'm'),
			enabled,
		}),

	preferences: (tenant: string) =>
		queryOptions({
			queryKey: notificationsKeys.preference(tenant),
			queryFn: () => fetchNotificationPreferences(tenant),
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
			gcTime: time(10, 'm'),
		}),
}

/**
 * Fetch the current user's notifications
 * @param {NotificationsFilters} filters - Filters to apply to the notifications
 */
export const useNotifications = (filters?: NotificationsFilters) => {
	return useSuspenseQuery(notificationsQuery.list(filters))
}

/**
 * Fetch the current user's notification details
 * @param {string} notificationId - The ID of the notification to fetch
 * @param {boolean} enabled - Whether the query should be enabled
 */
export const useNotificationDetails = (
	notificationId: string,
	enabled: boolean = false,
) => {
	return useQuery(notificationsQuery.detail(notificationId, enabled))
}

/**
 * Fetch the current user's notification preferences
 */
export const useNotificationPreferences = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(notificationsQuery.preferences(tenant))
}
