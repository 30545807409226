import { memo } from 'react'

import { useNavigate, useRouterState } from '@tanstack/react-router'

import { HasRole } from '@/features/auth/components/has-role'
import { Roles } from '@/features/auth/types'

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'

import { TabItem } from './tab-item'

type Tabs = 'alerts' | 'event-definitions' | 'automation-settings'

const TabsMenu = () => {
	const navigate = useNavigate()
	const routerState = useRouterState()

	/**
	 * Extract the tab from the current route path
	 * We get the third element [2], because we just want the things after /graylog
	 * but not the last item (e.g. /new or /edit/$id)
	 */
	const currentTab = routerState.location.pathname.split('/')[2] as Tabs

	const handleTabChange = (tab: Tabs) => {
		// Navigate with startTransition
		navigate({
			to: `/graylog/${tab}`,
		})
	}

	return (
		<div className="mb-6">
			{/* Desktop */}
			<ul className="hidden h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground sm:inline-flex">
				<TabItem to="/graylog/alerts" onClick={() => handleTabChange('alerts')}>
					Alerts
				</TabItem>
				<TabItem
					to="/graylog/event-definitions"
					onClick={() => handleTabChange('event-definitions')}
				>
					Event Definitions
				</TabItem>
				<HasRole role={Roles.enum.Analyst}>
					<TabItem
						to="/graylog/automation-settings"
						onClick={() => handleTabChange('automation-settings')}
					>
						Automation Settings
					</TabItem>
				</HasRole>
			</ul>

			{/* Mobile */}
			<div className="flex sm:hidden">
				<Select value={currentTab} onValueChange={handleTabChange}>
					<SelectTrigger>
						<SelectValue placeholder="Select Tab" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="alerts">Alerts</SelectItem>
						<SelectItem value="event-definitions">Event Definitions</SelectItem>
						<SelectItem value="automation-settings">
							Automation Settings
						</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</div>
	)
}

export const GraylogTabs = memo(TabsMenu)
