import { useCallback } from 'react'

import { XiorError } from 'xior'

import { sentry } from '@/lib/sentry'

import { useConfirmationActions } from '../store'

import type { ConfirmationHeaders, ConfirmationResponse } from '../types'

/**
 * Handles 449 errors (manual confirmation required) by opening a confirmation dialog and retrying the mutation
 * @returns a function that handles 449 errors
 */
export const useHandle449Error = () => {
	const { open } = useConfirmationActions()

	return useCallback(
		(error: unknown, retryMutation: (headers: ConfirmationHeaders) => void) => {
			if (is449Error(error)) {
				// Mark the error as handled so Sentry ignores it
				sentry.withScope((scope) => {
					scope.setFingerprint(['confirmation-dialog-449'])
					scope.setLevel('info') // or you can use setTag to mark it as handled
				})

				const errorData = error.response?.data as ConfirmationResponse

				open({
					title: 'Confirmation Required',
					confirmationData: errorData,
					onConfirm: retryMutation,
				})
				return true // Error was handled
			}
			return false // Error was not handled
		},
		[open],
	)
}

const is449Error = (err: unknown): err is XiorError =>
	err instanceof XiorError && err.response?.status === 449 && err.response?.data
