import { forwardRef } from 'react'

import { Loader2 } from 'lucide-react'

import { Button } from '@/components/ui/button'

import type { ButtonProps } from '@/components/ui/button'

type LoadingButtonProps = ButtonProps & {
	isLoading: boolean
}

/**
 * A button that shows a loading spinner when the button is clicked.
 *
 * Accept the same props as shadcn Button
 *
 * @param {boolean} isLoading - Whether the button is loading
 *
 * @example
 * <LoadingButton isLoading={true}>Submit</LoadingButton>
 */
export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
	({ isLoading, children, ...props }, ref) => {
		return (
			<Button disabled={isLoading || props.disabled} {...props} ref={ref}>
				{isLoading ? (
					<Loader2 className="h-6 w-6 animate-spin repeat-infinite" />
				) : (
					children
				)}
			</Button>
		)
	},
)
