import { useIntegrationList } from '../queries'
import { getIntegrationId } from '../utils'

export const useCheckIntegrations = () => {
	const { data: integrations } = useIntegrationList()

	const graylog = getIntegrationId('Graylog')

	const hasIntegrations = integrations?.length > 0
	const hasGraylog = integrations?.some(
		(integration) => integration.definition_id === graylog,
	)

	return { hasIntegrations, hasGraylog }
}
