import { z } from 'zod'

/**
 * System Roles
 */
export const SYSTEM_ROLES = {
	Admin: 'Admin',
	SystemManager: 'System Manager',
	Analyst: 'Analyst',
	ReadOnly: 'Read Only',
} as const

export const ROLE_HIERARCHY = {
	'Admin': 4,
	'System Manager': 3,
	'Analyst': 2,
	'Read Only': 1,
} as const

export const Roles = z.nativeEnum(SYSTEM_ROLES)
export type Roles = z.infer<typeof Roles>

/**
 * Responses from AWS Amplify Auth
 * @see https://docs.amplify.aws/react/build-a-backend/auth/connect-your-frontend/sign-in/#using-the-signin-api
 */
export const SIGN_IN_STEPS = z.enum([
	'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
	'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
	'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
	'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
	'CONFIRM_SIGN_IN_WITH_SMS_CODE',
	'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
	'CONFIRM_RESET_PASSWORD_WITH_CODE',
	'RESET_PASSWORD',
	'CONFIRM_SIGN_UP',
	'DONE',
])

export const RESET_PASSWORD_STEPS = z.enum([
	'CONFIRM_RESET_PASSWORD_WITH_CODE',
	'DONE',
])

export type SIGN_IN_STEPS = z.infer<typeof SIGN_IN_STEPS>
export type RESET_PASSWORD_STEPS = z.infer<typeof RESET_PASSWORD_STEPS>

/**
 * Form Types
 */

export const LoginFormStates = z.enum([
	'login',
	'otp',
	'setup-password',
	'setup-totp',
	'reset-password',
	'forgot-password',
	'confirmation-code',
	'reset-password',
])
export type LoginFormStates = z.infer<typeof LoginFormStates>

export const ForgotPasswordFormStates = z.enum([
	'forgot-password',
	'confirmation-code',
	'reset-password',
])
export type ForgotPasswordFormStates = z.infer<typeof ForgotPasswordFormStates>
