import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { cn } from '@/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'sonner'

import { LoadingButton } from '@/components/buttons/loading-button'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { useUpdateMe } from '../mutations'
import { useMe } from '../queries'

import { schema } from './manage-account-validation'

import type { ManageAccountFormValues } from './manage-account-validation'

export const ManageAccount = () => {
	const { mutate, isPending } = useUpdateMe()
	const {
		data: { user },
	} = useMe()

	const [isLoading, setIsLoading] = useState(isPending)

	const form = useForm<ManageAccountFormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			fname: user?.fname || '',
			lname: user?.lname || '',
			uname: user?.uname || '',
		},
	})

	const onSubmit = form.handleSubmit(async (data) => {
		// Prevent multiple form submissions
		if (isLoading) return

		// Set loading state to true
		setIsLoading(true)
		mutate(
			{
				fname: data.fname,
				lname: data.lname,
				uname: data.uname,
			},
			{
				onSuccess: () => {
					toast('Profile updated successfully!', {
						description: 'Your profile has been updated',
					})
				},
				onSettled: () => {
					setIsLoading(false)
				},
			},
		)
	})

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit}
				className="flex w-full flex-col items-start justify-center gap-4 px-2"
			>
				<div className="flex w-full flex-col items-start justify-start gap-2 sm:w-1/2">
					<FormField
						control={form.control}
						name="fname"
						render={({ field }) => (
							<FormItem className="mb-6 w-full">
								<FormLabel>First Name</FormLabel>
								<FormControl>
									<Input
										type="text"
										{...field}
										placeholder="First Name"
										className={cn(
											'w-full rounded-md border border-text-secondary border-opacity-50 bg-transparent p-4 text-sm text-text-primary text-opacity-65 placeholder-text-secondary',
											{
												'border-red-500': form.formState.errors?.fname,
											},
										)}
										required
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="lname"
						render={({ field }) => (
							<FormItem className="mb-6 w-full">
								<FormLabel>Last Name</FormLabel>
								<FormControl>
									<Input
										type="text"
										{...field}
										placeholder="Last Name"
										className={cn(
											'w-full rounded-md border border-text-secondary border-opacity-50 bg-transparent p-4 text-sm text-text-primary text-opacity-65 placeholder-text-secondary',
											{
												'border-red-500': form.formState.errors?.lname,
											},
										)}
										required
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="uname"
						render={({ field }) => (
							<FormItem className="mb-6 w-full">
								<FormLabel>Email</FormLabel>
								<FormControl>
									<Input
										type="email"
										{...field}
										placeholder="Email"
										className={cn(
											'w-full rounded-md border border-text-secondary border-opacity-50 bg-transparent p-4 text-sm text-text-primary text-opacity-65 placeholder-text-secondary',
											{
												'border-red-500': form.formState.errors?.uname,
											},
										)}
										required
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				<LoadingButton
					isLoading={isLoading}
					type="submit"
					variant="outline"
					className="flex items-center justify-center rounded-md"
				>
					Save
				</LoadingButton>
			</form>
		</Form>
	)
}
