import { useEffect, useMemo, useState } from 'react'

import { getRouteApi, Link } from '@tanstack/react-router'

import { delay } from '@/utils'
import { ChevronsUpDown, Search } from 'lucide-react'

import { clearQueryCache } from '@/lib/react-query'

import { useAppActions } from '@/features/app/store'
import { useMe } from '@/features/user/queries'
import {
	useCurrentRole,
	useCurrentTenant,
	useUserActions,
} from '@/features/user/store'
import { useUpdateSearch } from '@/hooks/useUpdateSearch'

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
	useSidebar,
} from '@/components/ui/sidebar'

import { ScrollArea } from '../ui/scroll-area'

import { SidebarTenantSearch } from './sidebar-tenant-search'

import type { Tenant } from '@/features/user/types/api'

const Route = getRouteApi('/_dashboard')

export function SidebarTenantSelect() {
	// Get list of allowed tenants from ReactQuery cache
	const {
		data: { tenant_roles, allowed_tenants },
	} = useMe()

	const searchTenant = Route.useSearch({
		select: (search) => search.tenant,
	})

	const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null)
	// Enable dialog for searching tenants
	const [isSearching, setIsSearching] = useState<boolean>(false)

	const tenants = allowed_tenants.sort((a, b) =>
		a.fullname.localeCompare(b.fullname),
	)

	const currentTenantID = useCurrentTenant()

	const { setIsLoading } = useAppActions()
	const { setCurrentRole, setCurrentTenant } = useUserActions()

	const { isMobile } = useSidebar()
	const { update } = useUpdateSearch()

	/**
	 * If the current tenant ID is set, find the tenant and set the selected tenant
	 * This is used to set the initial selected tenant when the component is mounted
	 */
	useEffect(() => {
		if (currentTenantID) {
			const currentTenant = tenants?.find(
				(tenant) => tenant.id === currentTenantID,
			)
			setSelectedTenant(currentTenant || null)
		}
	}, [currentTenantID])

	/**
	 * Handle the selection of a tenant
	 * @param tenant - The tenant to select
	 */
	const handleSelect = async (tenant: Tenant | null) => {
		setIsLoading(true)
		// Change the selected tenant and role
		setSelectedTenant(tenant)

		// Update store values (and localStorage)
		setCurrentRole(tenant_roles[tenant?.id ?? ''] ?? null)
		setCurrentTenant(tenant?.id ?? undefined)

		// Clear the query cache from previous tenant
		clearQueryCache()

		await delay(1000)
		setIsLoading(false)
	}

	/**
	 * If the tenant in the search params is different from the current tenant, set the selected tenant
	 */
	useEffect(() => {
		// If the tenant in the search params is different from the current tenant, set the selected tenant
		if (searchTenant && searchTenant !== currentTenantID) {
			// Find the tenant in the list of tenants
			const foundTenant = tenants?.find((t) => t.id === searchTenant)

			// If the tenant is found, set the selected tenant
			if (foundTenant) {
				handleSelect(foundTenant)
			}

			// Remove the tenant from the search params
			update({ tenant: undefined })
		}

		return () => {
			update({ tenant: undefined })
		}
	}, [searchTenant])

	return (
		<>
			<SidebarMenu>
				<SidebarMenuItem>
					{tenants.length > 1 ? (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<SidebarMenuButton
									size="lg"
									className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
								>
									<CurrentTenant selectedTenant={selectedTenant} />
									<ChevronsUpDown className="ml-auto" />
								</SidebarMenuButton>
							</DropdownMenuTrigger>
							<DropdownMenuContent
								className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
								align="start"
								side={isMobile ? 'bottom' : 'right'}
								sideOffset={4}
							>
								<DropdownMenuItem
									className="cursor-pointer gap-2 p-2"
									onClick={() => setIsSearching(true)}
								>
									<div className="flex size-6 items-center justify-center rounded-md border bg-background">
										<Search className="size-3" />
									</div>
									<div className="font-medium text-muted-foreground">
										Search Tenant
									</div>
								</DropdownMenuItem>

								<DropdownMenuSeparator />

								<DropdownMenuLabel className="text-xs text-muted-foreground">
									Tenants
								</DropdownMenuLabel>
								<ScrollArea className="h-56">
									{tenants.map((team) => (
										<DropdownMenuItem
											key={team.fullname}
											onClick={() => handleSelect(team)}
											className="cursor-pointer gap-2 p-2"
											disabled={team.id === selectedTenant?.id}
										>
											{team.fullname}
										</DropdownMenuItem>
									))}
								</ScrollArea>
							</DropdownMenuContent>
						</DropdownMenu>
					) : (
						<SidebarMenuButton
							size="lg"
							className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
							asChild
						>
							<Link to="/graylog">
								<CurrentTenant selectedTenant={selectedTenant} />
							</Link>
						</SidebarMenuButton>
					)}
				</SidebarMenuItem>
			</SidebarMenu>

			<SidebarTenantSearch
				isSearching={isSearching}
				setIsSearching={setIsSearching}
				tenants={tenants}
				selectedTenant={selectedTenant}
				handleSelect={handleSelect}
			/>
		</>
	)
}

function CurrentTenant({ selectedTenant }: { selectedTenant: Tenant | null }) {
	const tenantInitials = useMemo(() => {
		return (
			selectedTenant?.fullname
				?.split(' ')
				.map((name) => name[0])
				.join('')
				.slice(0, 2) // limit to 2 characters
				.toUpperCase() ?? '??'
		)
	}, [selectedTenant?.fullname])

	const currentRole = useCurrentRole()

	return (
		<>
			<div className="flex aspect-square size-8 items-center justify-center rounded-md bg-sidebar-primary text-xs uppercase text-sidebar-primary-foreground">
				{tenantInitials}
			</div>

			<div className="grid flex-1 text-left text-sm leading-tight">
				<span className="truncate font-semibold">
					{selectedTenant?.fullname}
				</span>
				<span className="truncate text-xs">{currentRole}</span>
			</div>
		</>
	)
}
