import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { removeOldestQuery } from '@tanstack/react-query-persist-client'

import { time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { appVersion } from '../config/constants'

import { sentry } from './sentry'

import type { PersistQueryClientOptions } from '@tanstack/react-query-persist-client'
import type { XiorError } from 'xior'

/**
 * React Query Default Options
 * This will set the default stale time for all queries to 5 minutes
 * This can be overridden on a per-query basis
 */
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
			gcTime: time(5, 'm'),
			throwOnError: (error, query) => {
				const err = error as XiorError
				const { state, queryKey } = query

				// Pass error to Sentry
				sentry.captureException(err, {
					contexts: {
						response: err?.response?.data,
					},
					extra: {
						origin: 'react-query-error',
						page: window.location.href,
						request: err?.request?.url,
						queryState: JSON.stringify(state, null, 2),
						queryKey: queryKey.join(','),
					},
				})

				return true
			},
		},
		mutations: {
			onError: (error) => {
				const err = error as XiorError

				// Pass error to Sentry
				sentry.captureException(err, {
					contexts: {
						response: err?.response?.data,
					},
					extra: {
						origin: 'react-query-mutation-error',
						page: window.location.href,
					},
				})
			},
		},
	},
})

/**
 * Persist Query Client
 * This will persist the query client to the browser's local storage, allowing
 * the user to continue from where they left off after a page reload
 *
 * @see https://tanstack.com/query/latest/docs/framework/react/plugins/persistQueryClient
 */
export const persister = createSyncStoragePersister({
	storage: window.localStorage,
	key: '@bitlyft/query-cache',
	retry: removeOldestQuery,
	throttleTime: time(30, 's'),
	serialize: (data) =>
		JSON.stringify({
			version: appVersion,
			data,
		}),
	deserialize: (cachedString) => {
		const cached = JSON.parse(cachedString)

		if (cached.version !== appVersion) {
			return undefined
		}

		return cached.data
	},
})

export const persistOptions: PersistQueryClientOptions = {
	persister,
	queryClient,
	buster: appVersion,
	dehydrateOptions: {
		// Queries that should not be persisted
		shouldDehydrateQuery: (query) => {
			const hasNoCacheKey = query.queryKey.includes('no-cache')

			// Don't persist if the query key has no-cache key
			return !hasNoCacheKey
		},
	},
}

export const clearQueryCache = async () => {
	// Cancel any pending queries
	queryClient.cancelQueries()

	// Remove the persisted client
	await persister.removeClient()

	// Clear the query cache
	queryClient.clear()

	// Remove all queries
	queryClient.removeQueries()
}
