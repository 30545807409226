import { EllipsisVertical } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { useMarkNotificationsAsRead } from '../mutations'
import { useNotifications } from '../queries'

export const NotificationMenu = () => {
	const {
		data: { notifications },
	} = useNotifications({
		is_read: false,
	})

	const { mutate: markAllAsRead } = useMarkNotificationsAsRead()

	const handleMarkAllAsRead = () => {
		markAllAsRead(notifications?.map((n) => n.id) ?? [])
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild className="z-50">
				<Button variant="ghost" size="icon">
					<EllipsisVertical className="size-4" />
				</Button>
			</DropdownMenuTrigger>

			<DropdownMenuContent className="z-50">
				<DropdownMenuLabel>Actions</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuItem
					onClick={handleMarkAllAsRead}
					className="cursor-pointer hover:bg-muted"
					disabled={notifications?.length === 0}
				>
					Mark all as read
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default NotificationMenu
