import { useEffect } from 'react'

import { getRouteApi } from '@tanstack/react-router'

import { useDebounce } from '@uidotdev/usehooks'

import { useUpdateSearch } from '@/hooks/useUpdateSearch'

import { SearchBar } from '@/components/common/search-bar'

import { useTenantContacts } from '../queries'

import type { TenantContact } from '../types'

const Route = getRouteApi('/_dashboard/settings/contact-directory/')

type ContactDirectorySearchProps = {
	query: string
	onQueryChange: (query: string) => void
	onEditContact: (contact: TenantContact) => void
}

const ContactDirectorySearch = ({
	query,
	onQueryChange,
	onEditContact,
}: ContactDirectorySearchProps) => {
	const { data } = useTenantContacts()

	// Update the query state after 500ms of no input
	const debouncedQuery = useDebounce(query, 500)

	const { update } = useUpdateSearch()
	const search = Route.useSearch()

	/**
	 * SEARCH AND URL
	 */

	// If page has a search query, update the query state (search bar)
	useEffect(() => {
		if (search.q) {
			onQueryChange(search.q)
		}
	}, [search.q])

	// If the query state changes, update the search state (URL)
	useEffect(() => {
		update({ q: debouncedQuery || undefined })
	}, [debouncedQuery])

	useEffect(() => {
		if (search.user) {
			onEditContact(
				data?.find((contact) => contact.name === search.user) as TenantContact,
			)
		}
	}, [search?.user])

	return (
		<div className="flex w-full flex-row items-start justify-start gap-2">
			<SearchBar
				query={query}
				setQuery={onQueryChange}
				placeholder="Search for Email, Name or Title"
			/>
		</div>
	)
}

export default ContactDirectorySearch
