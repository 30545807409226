import { z } from 'zod'

export const PRIORITIES = {
	HIGH: 1,
	MEDIUM: 2,
	LOW: 3,
} as const

const alertPriority = z.nativeEnum(PRIORITIES)
export type AlertPriority = z.infer<typeof alertPriority>

export type AlertRequest = {
	enabled_alerts: Alert[]
	last_updated: string
	tenant: string
}

export type Alert = {
	id: number
	name: string
	description: string
	query: string
	search_interval_seconds: number
	threshold_operator: string | null
	threshold_value: number | null
	enabled: boolean
	is_essential_policy: boolean
	reference_link: string | null
	alert_type?: string
	severity?: string
}

export type TriggerAlert = {
	name: string
	alert_id: string
	description: string
	query: string
	timestamp: string
	message: string
	fields?: {
		[key: string]: string
	}
	is_essential_policy: boolean
	reference_link: string
	alert_type: string
	priority?: AlertPriority
}

export const periods = z.enum([
	'LAST_7_DAYS',
	'LAST_30_DAYS',
	'LAST_60_DAYS',
	'LAST_90_DAYS',
	'LAST_180_DAYS',
	'CUSTOM',
])
export type Period = z.infer<typeof periods>

export type Widget = {
	id: string
	type: 'number' | 'table' | 'graph'
	data?: {
		name?: string
		period?: Period
		size?: 1 | 2 | 3
		priority?: AlertPriority
	}
}
