import { cn } from '@/utils'

import { Skeleton } from '../ui/skeleton'

type GridSkeletonProps = {
	length: number
	className?: string
}

/**
 * Grid skeleton component for displaying a grid of skeletons
 */
export const GridSkeleton = ({ length, className }: GridSkeletonProps) => {
	return (
		<div
			className={cn(
				'grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4',
				className,
			)}
		>
			{Array.from({ length }).map((_, index) => (
				<Skeleton key={index} className="h-auto min-h-[200px] w-full" />
			))}
		</div>
	)
}
