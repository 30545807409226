import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type SheetPanel = {
	title: string
	description?: string
	content: React.ReactNode | null
	isOpen: boolean
	className?: string
	side?: 'top' | 'bottom' | 'left' | 'right'
	onClose?: () => void
	onOpen?: () => void
}

type PanelState = {
	panel: SheetPanel
}

type PanelActions = {
	createPanel: (panel: Omit<SheetPanel, 'isOpen'>) => void
	close: () => void
	open: () => void
	reset: () => void
}

type PanelStore = PanelState & {
	actions: PanelActions
}

const initialState: PanelState = {
	panel: {
		title: '',
		description: '',
		content: null,
		isOpen: false,
		className: '',
		side: 'right',
	},
}

const panelStore = createWithEqualityFn<PanelStore>()(
	(set, get) => ({
		// Set initial state
		...initialState,

		actions: {
			createPanel: (panel: Omit<SheetPanel, 'isOpen'>) =>
				set((state) => ({ ...state, panel: { ...state.panel, ...panel } })),
			close: () => {
				const { onClose } = get().panel

				if (typeof onClose === 'function') {
					onClose()
				}

				return set((state) => ({
					...state,
					panel: {
						...state.panel,
						isOpen: false,
						content: null,
						className: '',
						side: 'right',
					},
				}))
			},
			open: () => {
				const { onOpen } = get().panel

				if (typeof onOpen === 'function') {
					onOpen()
				}

				return set((state) => ({
					...state,
					panel: { ...state.panel, isOpen: true },
				}))
			},
			reset: () => set(initialState),
		},
	}),
	shallow,
)

export const usePanel = () => panelStore((state) => state.panel)
export const useIsPanelOpen = () => panelStore((state) => state.panel.isOpen)
export const usePanelActions = () => panelStore((state) => state.actions)
