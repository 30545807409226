import { useRef } from 'react'

import { rankItem } from '@tanstack/match-sorter-utils'
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'

import { cn } from '@/utils'
import { ArrowDown, ArrowUp } from 'lucide-react'

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table'

import type {
	ColumnDef,
	FilterFn,
	Row,
	RowData,
	TableOptions,
} from '@tanstack/react-table'

type DataTableProps<TData, TValue> = {
	columns: ColumnDef<TData, TValue>[]
	data: TData[]
	options?: Partial<TableOptions<TData>>
}

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<RowData> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value)

	// Store the itemRank info
	addMeta({
		itemRank,
	})

	// Return if the item should be filtered in/out
	return itemRank.passed
}

/**
 * DataTable using TanstackTable
 *
 * A generic DataTable component that uses TanstackTable for rendering any table.
 *
 * @param {ColumnDef<TData, TValue>[]} columns - The columns of the table
 * @param {TData[]} data - The data of the table
 * @param {Partial<TableOptions<TData>>} options - The options of the table
 *
 * @see https://ui.shadcn.com/docs/components/data-table#basic-table
 */
export function DataTable<TData, TValue>({
	columns,
	data,
	options,
}: DataTableProps<TData, TValue>) {
	const parentRef = useRef<HTMLTableElement>(null)

	const table = useReactTable({
		data,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
		},
		globalFilterFn: 'fuzzy',
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		...options,
	})

	/**
	 * Row Virtualization
	 * This is used to render the rows in a virtualized way
	 * This allows for better performance and smooth scrolling
	 */
	const { rows } = table.getRowModel()
	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 75,
	})

	return (
		<Table wrapperClassName="overflow-clip" ref={parentRef}>
			<TableHeader className="sticky top-0 z-10 bg-muted text-muted-foreground">
				{table.getHeaderGroups().map((headerGroup) => (
					<TableRow key={headerGroup.id}>
						{/* Render the header cells based on the column definition */}
						{headerGroup.headers.map((header) => {
							return (
								<TableHead
									key={header.id}
									colSpan={header.colSpan}
									style={{ width: `${header.getSize()}px` }}
								>
									{/*  If the header is a placeholder, return null */}
									{header.isPlaceholder ? null : (
										<>
											<div
												// Add event handlers for sorting and filtering
												{...{
													onClick: header.column.getToggleSortingHandler(),
													className: cn(
														'flex flex-row items-center justify-between gap-2 select-none',
														{
															'cursor-pointer': header.column.getCanSort(),
														},
													),
												}}
											>
												{/* Render the header content */}
												{flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}

												{/* Render the sorting indicator */}
												{{
													asc: (
														<ArrowDown className="h-4 w-4 text-text-primary" />
													),
													desc: (
														<ArrowUp className="h-4 w-4 text-text-primary" />
													),
												}[header.column.getIsSorted() as string] ?? null}
											</div>
										</>
									)}
								</TableHead>
							)
						})}
					</TableRow>
				))}
			</TableHeader>

			<TableBody>
				{table.getRowModel().rows?.length ? (
					// Render the rows based on the row model
					rowVirtualizer.getVirtualItems().map((virtualRow) => {
						const row = rows[virtualRow.index] as Row<TData>
						return (
							<TableRow
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{
											<>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</>
										}
									</TableCell>
								))}
							</TableRow>
						)
					})
				) : (
					<TableRow>
						<TableCell colSpan={columns.length} className="h-24 text-center">
							No results.
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	)
}
