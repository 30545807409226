import { createFileRoute, Link } from '@tanstack/react-router'

import { Divider, Subtitle, Title } from '@/features/auth/components/card'
import { AuthErrorComponent } from '@/features/auth/components/error-component'
import { ForgotPasswordForm } from '@/features/auth/components/forms/forgot-password'
import { AuthPendingComponent } from '@/features/auth/components/pending-component'

export const Route = createFileRoute('/_auth/forgot-password')({
	component: ForgotPasswordRoute,
	pendingComponent: AuthPendingComponent,
	errorComponent: AuthErrorComponent,
})

function ForgotPasswordRoute() {
	return (
		<>
			<title>Forgot Password | BitLyft Air®</title>
			<Title>Forgot Password</Title>
			<Subtitle>Enter your email address to reset your password.</Subtitle>

			<ForgotPasswordForm />

			<Divider />

			<Link to="/login">Return to Sign In</Link>
		</>
	)
}
