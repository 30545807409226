import { tz, TZDate } from '@date-fns/tz'
import {
	addDays,
	addMonths,
	addYears,
	format,
	formatDistanceToNow,
	isToday,
	isValid,
	isYesterday,
	parse,
	parseISO,
	subDays,
	subMonths,
	subYears,
} from 'date-fns'
import { enCA, enUS, ptBR } from 'date-fns/locale'
import { z } from 'zod'

import type { Period } from '@/features/graylog/alerts/types'
import type { Locale } from 'date-fns'

/**
 * Map of supported locales
 */
const localeMap: Record<string, Locale> = {
	'en-US': enUS,
	'pt-BR': ptBR,
	'en-CA': enCA,
}

/**
 * Default format patterns by locale
 */
const defaultDateFormats: Record<string, string> = {
	'en-US': 'MM/dd/yyyy',
	'pt-BR': 'dd/MM/yyyy',
	'en-CA': 'yyyy-MM-dd',
}

/**
 * Default date time format patterns by locale
 */
const defaultDateTimeFormats: Record<string, string> = {
	'en-US': 'MM/dd/yyyy hh:mm a',
	'pt-BR': 'dd/MM/yyyy HH:mm',
	'en-CA': 'yyyy-MM-dd h:mm a',
}

/**
 * Get user's locale from browser or fallback to en-US
 */
export const getUserLocale = () => {
	const browserLocale = navigator.language
	return localeMap[browserLocale] ? browserLocale : 'en-US'
}

/**
 * Get the current locale object
 */
export const getCurrentLocale = () => {
	return localeMap[getUserLocale()] || enUS
}

/**
 * Format a date based on user's locale
 */
export const formatDate = (
	date: Date | string | number,
	formatStr?: string,
) => {
	const locale = getCurrentLocale()
	const userLocale = getUserLocale()

	// Convert to Date if string or number
	const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)

	if (!isValid(dateObj)) {
		return ''
	}

	return format(dateObj, formatStr || defaultDateFormats[userLocale], {
		locale,
	})
}

/**
 * Format a date with time based on user's locale
 */
export const formatDateTime = (
	date: Date | string | number,
	formatStr?: string,
) => {
	const locale = getCurrentLocale()
	const userLocale = getUserLocale()

	const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)

	if (!isValid(dateObj)) {
		return ''
	}

	return format(dateObj, formatStr || defaultDateTimeFormats[userLocale], {
		locale,
	})
}

/**
 * Parse a date string based on user's locale
 */
export const parseDate = (dateStr: string, formatStr?: string) => {
	const locale = getCurrentLocale()
	const userLocale = getUserLocale()

	return parse(
		dateStr,
		formatStr || defaultDateFormats[userLocale],
		new Date(),
		{ locale },
	)
}

/**
 * Date manipulation helpers
 */
export const dateUtils = {
	addDays: (date: Date, amount: number) => addDays(date, amount),
	addMonths: (date: Date, amount: number) => addMonths(date, amount),
	addYears: (date: Date, amount: number) => addYears(date, amount),
	subDays: (date: Date, amount: number) => subDays(date, amount),
	subMonths: (date: Date, amount: number) => subMonths(date, amount),
	subYears: (date: Date, amount: number) => subYears(date, amount),
}

/**
 * Format relative time (e.g., "2 hours ago")
 */
export const formatRelativeTime = (date: Date | string | number) => {
	const locale = getCurrentLocale()
	const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)

	if (!isValid(dateObj)) {
		return ''
	}

	return formatDistanceToNow(dateObj, {
		addSuffix: true,
		locale,
	})
}

/**
 * Common date ranges
 */
export const dateRanges = {
	today: () => {
		const now = new Date()
		return { start: now, end: now }
	},
	last7Days: () => {
		const end = new Date()
		const start = subDays(end, 6) // 7 days including today
		return { start, end }
	},
	last30Days: () => {
		const end = new Date()
		const start = subDays(end, 29) // 30 days including today
		return { start, end }
	},
	last60Days: () => {
		const end = new Date()
		const start = subDays(end, 59) // 60 days including today
		return { start, end }
	},
	last90Days: () => {
		const end = new Date()
		const start = subDays(end, 89) // 90 days including today
		return { start, end }
	},
	last180Days: () => {
		const end = new Date()
		const start = subDays(end, 179) // 180 days including today
		return { start, end }
	},
	thisMonth: () => {
		const now = new Date()
		const start = new Date(now.getFullYear(), now.getMonth(), 1)
		const end = new Date(now.getFullYear(), now.getMonth() + 1, 0)
		return { start, end }
	},
	lastMonth: () => {
		const now = new Date()
		const start = new Date(now.getFullYear(), now.getMonth() - 1, 1)
		const end = new Date(now.getFullYear(), now.getMonth(), 0)
		return { start, end }
	},
}

/**
 * Get user's timezone from browser
 */
export const getUserTimeZone = () => {
	return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 * Convert UTC date to user's local timezone and format
 */
export const formatUTCToLocal = (
	utcDate: Date | string | number,
	formatStr?: string,
) => {
	const locale = getCurrentLocale()
	const userLocale = getUserLocale()
	const userTimeZone = getUserTimeZone()

	// Convert to Date if string or number
	const dateObj =
		typeof utcDate === 'string' ? parseISO(utcDate) : new Date(utcDate)

	if (!isValid(dateObj)) {
		return ''
	}

	// Create a TZDate in the user's timezone
	const tzDate = new TZDate(dateObj, userTimeZone)

	return format(tzDate, formatStr || defaultDateFormats[userLocale], {
		locale,
	})
}

/**
 * Convert UTC date to user's local timezone with time and format
 */
export const formatUTCToLocalDateTime = (
	utcDate: Date | string | number,
	formatStr?: string,
) => {
	const locale = getCurrentLocale()
	const userLocale = getUserLocale()
	const userTimeZone = getUserTimeZone()

	const dateObj =
		typeof utcDate === 'string' ? parseISO(utcDate) : new Date(utcDate)

	if (!isValid(dateObj)) {
		return ''
	}

	const tzDate = new TZDate(dateObj, userTimeZone)

	return format(tzDate, formatStr || defaultDateTimeFormats[userLocale], {
		locale,
	})
}

/**
 * Convert local date to UTC
 */
export const localToUTC = (localDate: Date | string | number) => {
	const userTimeZone = getUserTimeZone()
	const dateObj =
		typeof localDate === 'string' ? parseISO(localDate) : new Date(localDate)

	if (!isValid(dateObj)) {
		return new Date()
	}

	const tzDate = new TZDate(dateObj, userTimeZone)

	// Create a TZDate in UTC
	return new TZDate(tzDate, 'UTC')
}

export const periodToDateRange = (period: Period) => {
	switch (period) {
		case 'LAST_7_DAYS':
			return dateRanges.last7Days()
		case 'LAST_30_DAYS':
			return dateRanges.last30Days()
		case 'LAST_60_DAYS':
			return dateRanges.last60Days()
		case 'LAST_90_DAYS':
			return dateRanges.last90Days()
		case 'LAST_180_DAYS':
			return dateRanges.last180Days()
		case 'CUSTOM':
			return { start: new Date(), end: new Date() }
	}
}

/**
 * Format a date in a specific timezone
 */
export const formatInZone = (
	date: Date | string | number,
	timeZone: string,
	formatStr?: string,
) => {
	const locale = getCurrentLocale()
	const userLocale = getUserLocale()
	const dateObj = typeof date === 'string' ? parseISO(date) : new Date(date)

	if (!isValid(dateObj)) {
		return ''
	}

	const tzDate = new TZDate(dateObj, timeZone)

	return format(tzDate, formatStr || defaultDateTimeFormats[userLocale], {
		locale,
		in: tz(timeZone),
	})
}

/**
 * Zod schemas for dates
 */
export const zodDate = z.union([
	z.date(),
	z.string().transform((date, ctx) => {
		const parsed = parseISO(date)
		if (!isValid(parsed)) {
			ctx.addIssue({
				code: z.ZodIssueCode.invalid_date,
				message: 'Invalid date format. Expected ISO date string.',
			})
			return z.NEVER
		}
		return parsed
	}),
])

export type ZodDate = z.infer<typeof zodDate>

/**
 * Zod schema for dates in user's locale format
 */
export const zodLocalDate = z.string().transform((date, ctx) => {
	const userLocale = getUserLocale()
	const parsed = parse(date, defaultDateFormats[userLocale], new Date())

	if (!isValid(parsed)) {
		ctx.addIssue({
			code: z.ZodIssueCode.invalid_date,
			message: `Invalid date format. Expected format: ${defaultDateFormats[userLocale]}`,
		})
		return z.NEVER
	}
	return parsed
})

export type ZodLocalDate = z.infer<typeof zodLocalDate>
/**
 * Zod schema for UTC dates that converts to user's timezone
 */
export const zodUTCDate = z.union([
	z.date(),
	z.string().transform((date, ctx) => {
		const parsed = parseISO(date)
		if (!isValid(parsed)) {
			ctx.addIssue({
				code: z.ZodIssueCode.invalid_date,
				message: 'Invalid UTC date format. Expected ISO date string.',
			})
			return z.NEVER
		}
		return new TZDate(parsed, getUserTimeZone())
	}),
])

export type ZodUTCDate = z.infer<typeof zodUTCDate>

/**
 * Format a timestamp to a readable date string
 */
export const formatGroupTimestamp = (timestamp: string): string => {
	const date = new Date(parseInt(timestamp))

	if (isToday(date)) {
		return `Today at ${format(date, 'h:mm a')}`
	} else if (isYesterday(date)) {
		return `Yesterday at ${format(date, 'h:mm a')}`
	} else {
		return format(date, 'MMM d, yyyy h:mm a')
	}
}
