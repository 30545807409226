import { memo, useMemo } from 'react'

import { useMe } from '@/features/user/queries'
import { useCurrentRole, useCurrentTenant } from '@/features/user/store'

import { Separator } from '../ui/separator'
import { SidebarTrigger } from '../ui/sidebar'

import { Breadcrumbs } from './breadcrumbs'

/**
 * Header component
 * - Contains the logo, search input, tenant select, theme toggle, notifications, and user menu
 */
export const Header = memo(() => {
	const {
		data: { allowed_tenants },
	} = useMe()

	const tenant = useCurrentTenant()
	const role = useCurrentRole()

	const tenantName = useMemo(() => {
		return allowed_tenants.find((t) => t.id === tenant)?.fullname ?? '??'
	}, [allowed_tenants, tenant])

	return (
		<header className="flex h-16 shrink-0 items-center gap-2 border-b px-4">
			<SidebarTrigger />
			<Separator orientation="vertical" className="mr-2 h-4" />
			<Breadcrumbs />

			<div className="flex-auto" />

			<div className="hidden flex-col items-end text-sm sm:flex">
				<span className="truncate font-semibold">{tenantName}</span>
				<span className="truncate text-xs">{role}</span>
			</div>
		</header>
	)
})
