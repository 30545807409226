import { lazy } from 'react'

import { createFileRoute, Link } from '@tanstack/react-router'

import { ChevronLeft } from 'lucide-react'

import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import NewIntegrationForm from '@/features/integrations/components/new-integration-form'
import {
	integrationQueries,
	useIntegrationDefinition,
} from '@/features/integrations/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useTitle } from '@/hooks/useTitle'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { SectionTitle } from '@/components/environment/section'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const Route = createFileRoute('/_dashboard/integrations/new/$id')({
	beforeLoad: () => roleAuthGuard(Roles.enum.SystemManager),

	loader: async ({ context: { queryClient }, params }) => {
		const { id } = params
		const tenant = getCurrentTenant() as string

		queryClient.prefetchQuery(integrationQueries.definition(tenant, id))
	},

	component: RouteComponent,
	errorComponent: NewIntegrationError,
	pendingComponent: NewIntegrationSkeleton,
})

function RouteComponent() {
	useTitle('New Integration')

	const { id } = Route.useParams()
	const { data } = useIntegrationDefinition(id)

	return (
		<>
			<SectionTitle className="flex items-center gap-4">
				<Button variant="outline" size="icon" asChild>
					<Link to="/integrations/new">
						<ChevronLeft size="16" />
					</Link>
				</Button>
				New Integration - {data?.name}
			</SectionTitle>
			<h3 className="-mt-4 mb-4 text-lg font-medium text-muted-foreground">
				{data?.description}
			</h3>

			<div className="flex w-full rounded-lg bg-secondary/40 p-6 sm:max-w-screen-sm">
				<NewIntegrationForm
					input_schema={data?.config_schema ?? {}}
					integration_id={id}
				/>
			</div>
		</>
	)
}

function NewIntegrationSkeleton() {
	return (
		<>
			<SectionTitle className="flex items-center gap-4">
				<Button variant="outline" size="icon" asChild>
					<Link to="/integrations/new">
						<ChevronLeft size="16" />
					</Link>
				</Button>
				New Integration
			</SectionTitle>

			<Skeleton className="h-[400px] w-full max-w-screen-sm" />
		</>
	)
}

function NewIntegrationError({ error, reset }: ErrorComponentProps) {
	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
