import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Link } from '@tanstack/react-router'

import { zodResolver } from '@hookform/resolvers/zod'

import { useAuthNextSteps } from '@/features/auth/hooks/useAuthNextSteps'
import { useAuthSetup } from '@/features/auth/mutations'

import { LoadingButton } from '@/components/buttons/loading-button'
import { PasswordInput } from '@/components/common/password-input'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'

import { schema } from './validation'

import type { SetupPasswordFormValues } from './validation'

export const SetupPasswordForm = () => {
	const [isLoading, setIsLoading] = useState(false)

	const { mutate: setupPassword } = useAuthSetup()
	const { handleAuthNextStep } = useAuthNextSteps()

	const form = useForm<SetupPasswordFormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	})

	const onSubmit = form.handleSubmit(async (data) => {
		if (isLoading) return

		setIsLoading(true)

		setupPassword(
			{
				password: data.password,
			},
			{
				onSuccess: (data) => handleAuthNextStep(data),
				onError: (error) => {
					form.setError('password', {
						type: 'custom',
						message: error.message,
					})
				},
				onSettled: () => {
					setIsLoading(false)
				},
			},
		)
	})

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit}
				className="my-4 flex w-full flex-col items-center justify-center"
			>
				<PasswordInput form={form} name="password" label="New Password" />
				<PasswordInput
					form={form}
					name="confirmPassword"
					label="Confirm Password"
					isConfirm
				/>

				<LoadingButton
					isLoading={isLoading}
					data-testid="form-button"
					type="submit"
					variant="outline"
					className="w-full bg-bl-primary text-white hover:bg-bl-primary hover:bg-opacity-65"
				>
					Set Password
				</LoadingButton>
				<Button
					variant="ghost"
					type="button"
					className="mt-2 w-full rounded-md bg-slate-600 bg-opacity-100 px-6 py-3 text-white transition-all duration-200 hover:bg-opacity-60"
					asChild
				>
					<Link to="/login">Back</Link>
				</Button>
			</form>
		</Form>
	)
}
