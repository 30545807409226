import { useTenantBillingInformation } from '../queries'

export const BillingDetails = () => {
	const { data, isError } = useTenantBillingInformation()

	if (!data || isError) return <div>No billing data found.</div>

	return (
		<div className="w-full">
			<div className="flex flex-row items-center justify-between gap-4">
				<p className="text-sm font-semibold">Name</p>
				<p className="text-sm">{data?.contact_name}</p>
				<p className="text-sm">{data?.contact_phone}</p>
				<p className="text-sm">{data?.contact_email}</p>
			</div>
			<div className="my-4 w-full border-b border-border" />
			<div className="mb-2 flex flex-row items-center justify-start gap-4">
				<p className="w-24 text-sm font-semibold">Address</p>
				<p className="text-sm">{data?.address}</p>
			</div>

			<div className="mb-2 flex flex-row items-center justify-start gap-4">
				<p className="w-24 text-sm font-semibold">City</p>
				<p className="text-sm">{data?.city}</p>
			</div>

			<div className="mb-2 flex flex-row items-center justify-start gap-4">
				<p className="w-24 text-sm font-semibold">State</p>
				<p className="text-sm">{data?.state}</p>
			</div>

			<div className="mb-2 flex flex-row items-center justify-start gap-4">
				<p className="w-24 text-sm font-semibold">ZIP Code</p>
				<p className="text-sm">{data?.zip_code}</p>
			</div>
		</div>
	)
}
