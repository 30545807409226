import { Logo } from '@/components/environment/logo'

type AuthLayoutProps = {
	children: React.ReactNode
}
export const AuthLayout = ({ children }: AuthLayoutProps) => {
	return (
		<div className="flex h-screen w-full items-center justify-center px-8 sm:px-0">
			<div className="flex w-full flex-col items-center justify-stretch rounded-md bg-foreground p-8 shadow-lg sm:max-w-[500px]">
				<Logo className="h-11 w-11" />

				{children}
			</div>
		</div>
	)
}
