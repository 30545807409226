import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import { fetchReport } from './api'

export const reportKeys = {
	all: ['report'] as const,
	report: (tenant: string, start_time?: string, end_time?: string) =>
		[...reportKeys.all, tenant, start_time, end_time, 'no-cache'] as const,
}

export const reportQueries = {
	report: (tenant: string, start_time?: string, end_time?: string) =>
		queryOptions({
			queryKey: reportKeys.report(tenant, start_time, end_time),
			queryFn: () => fetchReport(tenant as string, start_time, end_time),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 1, 'd'),
		}),
}

/**
 * Custom hook to fetch Report
 * @param {string} tenant - The tenant ID
 * @param {string} start_time - The start time
 * @param {string} end_time - The end time
 */
export const useGetReport = (
	tenant?: string,
	start_time?: string,
	end_time?: string,
) => {
	const currentTenant = useCurrentTenant()
	const selectedTenant = tenant ? tenant : currentTenant

	return useSuspenseQuery(
		reportQueries.report(selectedTenant, start_time, end_time),
	)
}
