import LastUpdated from '@/components/common/last-updated'

import { useGraylogTriggeredAlerts } from '../../queries'

export const FilterLastUpdated = () => {
	const { refetch, dataUpdatedAt } = useGraylogTriggeredAlerts()

	return <LastUpdated lastUpdated={dataUpdatedAt} refetch={refetch} />
}

export default FilterLastUpdated
