import { ipv4Mask, ipv6Mask, regexMask } from '@/utils'
import { z } from 'zod'

import type { InputProperty, InputSchema } from './schema-form/types'
import type { MaskitoOptions } from '@maskito/core'

/**
 * Get the fields from the input schema, adding optional property to each field
 * @param {ActionInputSchema} inputSchema - The input schema to get the required fields from
 * @returns {InputProperty[]}
 */
export function getRequiredFields(inputSchema: InputSchema | undefined) {
	if (!inputSchema) {
		return []
	}

	// Convert the input schema to an array of fields
	const fields = Object.entries(inputSchema?.properties || {})

	// Add optional property to each field
	fields.forEach(([key, item]) => {
		if (inputSchema?.required?.includes(key)) {
			item.required = true
		} else {
			item.required = false
		}
	})

	return fields
}

/**
 * Validates a URL
 * @param {string} value - The URL to validate
 * @returns {boolean} True if the URL is valid, false otherwise
 */
export function validateURL(value: string): boolean {
	try {
		const validURL = z.string().url().safeParse(value)

		if (!validURL.success) {
			return false
		}

		return true
	} catch (error) {
		return false
	}
}

/**
 * Validates an email
 * @param {string} value - The email to validate
 * @returns {boolean} True if the email is valid, false otherwise
 */
export function validateEmail(value: string): boolean {
	const validEmail = z.string().email().safeParse(value)

	if (!validEmail.success) {
		return false
	}

	return true
}

/**
 * Validates a UUID
 * @param {string} value - The UUID to validate
 * @returns {boolean} True if the UUID is valid, false otherwise
 */
export function validateUUID(value: string): boolean {
	const validUUID = z.string().uuid().safeParse(value)

	if (!validUUID.success) {
		return false
	}

	return true
}

/**
 * Validates an IPv4 address
 * @param {string} value - The IPv4 address to validate
 * @returns {boolean} True if the IPv4 address is valid, false otherwise
 */
export function validateIP(value: string): boolean {
	const validIP = z.string().ip().safeParse(value)

	if (!validIP.success) {
		return false
	}

	return true
}

/**
 * Checks if the input has any null values
 * @param {InputProperty} item - The input to check
 * @returns {boolean} True if the input has any null values, false otherwise
 */
export function inputHasNullValues(item: InputProperty) {
	const values = Object.values(item.anyOf || [])

	const hasNullValues = values.some((value) => {
		return value.type === 'null'
	})
	return hasNullValues
}

/**
 * Gets the nullable input
 * @param {InputProperty[]} items - The items to check
 * @returns {InputProperty[]} The nullable input
 */
export function getNullableInput(items: InputProperty[]) {
	const otherValues = items.filter((value) => {
		if (value.type !== 'null') {
			const newVal = value
			newVal.nullable = true
			return newVal
		}
	})

	return otherValues
}

/**
 * Checks if the label should be displayed
 * @param {InputProperty} item - The item to check
 * @returns {boolean} True if the label should be displayed, false otherwise
 */
export function shouldDisplayLabel(item: InputProperty) {
	return item.type !== 'boolean'
}

/**
 * Checks if the message should be displayed
 * @param {InputProperty} item - The item to check
 * @returns {boolean} True if the message should be displayed, false otherwise
 */
export function shouldDisplayMessage(item: InputProperty) {
	return !item.anyOf
}

/**
 * Gets the mask for the input based on the item
 * @param {InputProperty} item - The item to get the mask for
 * @returns {MaskitoOptions | null} - The mask for the input
 */
export function _getInputMask(item: InputProperty): MaskitoOptions | null {
	if (item.format === 'ipv4' || item.items?.format === 'ipv4') {
		return ipv4Mask
	}

	if (item.format === 'ipv6' || item.items?.format === 'ipv6') {
		return ipv6Mask
	}

	if (item.pattern) {
		return regexMask(new RegExp(item.pattern))
	}

	if (item.items?.pattern) {
		return regexMask(new RegExp(item.items.pattern))
	}

	return null
}
