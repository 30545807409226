import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { fetchAuthSession } from 'aws-amplify/auth'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import type { QueryClient } from '@tanstack/react-query'
import type { AuthSession } from 'aws-amplify/auth'

export const authKeys = {
	all: ['auth'] as const,
	cognito: () => [...authKeys.all, 'cognito', 'no-cache'] as const,
}

export const authQueries = {
	cognitoAuth: () =>
		queryOptions({
			queryKey: authKeys.cognito(),
			queryFn: () => fetchAuthSession(),
			retry: defaultQueryRetry,
			gcTime: time(30, 'm'),
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
		}),
}

/**
 * Custom hook to get the cognito auth data (used inside React components)
 */
export const useAuthCognito = () => {
	return useSuspenseQuery(authQueries.cognitoAuth())
}

/**
 * Check if the user is authenticated in AWS Cognito (used outside React components)
 * @param queryClient - The query client to use
 */
export const isUserAuthenticated = async (queryClient: QueryClient) => {
	// Ensure the cognito auth data is loaded
	await queryClient.ensureQueryData(authQueries.cognitoAuth())

	// Get the cognito auth data
	const data = queryClient.getQueryData<AuthSession>(authKeys.cognito())

	// Return true if the user is authenticated
	const userSub = (data as AuthSession)?.userSub
	return userSub !== undefined
}
