import { lazy } from 'react'

import TableSkeleton from '@/components/skeletons/table-skeleton'

import { Title } from './card'

const ThemeToggle = lazy(() => import('@/components/common/theme-toggle'))

export const AuthPendingComponent = () => {
	return (
		<>
			<Title>Loading</Title>

			<TableSkeleton rows={2} />

			<div className="fixed right-4 top-4">
				<ThemeToggle />
			</div>
		</>
	)
}
