import { api } from '@/lib/api'

import type {
	ActionData,
	ActionDetails,
	ActionListRequest,
	RunAsynchronousActionDTO,
	SynchronousActionDTO,
} from './types'

/**
 * Fetch all action
 * @param {string} tenant - The tenant ID
 */
export const fetchActionList = async (tenant: string) => {
	if (!tenant) return []

	const {
		data: { actions },
	} = await api.get<ActionListRequest>(
		`/api/tenants/${tenant}/soar/available_actions`,
	)

	if (!actions) return []

	return actions
}

const defaultActionDetails: ActionDetails = {
	definition_id: '',
	description: '',
	input_schema: undefined,
	output_schema: undefined,
	vendor: '',
	product: '',
	friendly_name: '',
	action_id: '',
}

/**
 * Fetch details of an action
 * @param {string} tenant - The tenant ID
 */
export const fetchActionDetails = async (
	tenant: string,
	action_id: string,
): Promise<ActionDetails> => {
	if (!tenant) return defaultActionDetails

	const { data } = await api.get<ActionDetails>(
		`/api/tenants/${tenant}/soar/available_actions/${action_id}`,
	)

	return data
}

/**
 * Run an asynchronous action
 */
export const runAsynchronousAction = async (
	tenant: string,
	module_data: ActionData,
) => {
	const { data } = await api.post<RunAsynchronousActionDTO>(
		`/api/tenants/${tenant}/soar/actions_activity`,
		module_data,
	)

	return data
}

/**
 * Run a synchronous action
 */
export const runSynchronousAction = async (
	tenant: string,
	module_data: ActionData,
) => {
	const { data } = await api.post<SynchronousActionDTO>(
		`/api/tenants/${tenant}/soar/synchronous_action`,
		module_data,
	)

	return data
}
