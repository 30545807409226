import { api } from '@/lib/api'

import {
	type SynchronousActionEventDTO,
	type SynchronousActionTriggeredAlertDTO,
	type TriggerAlertFilters,
} from './types'

import type {
	AutomationData,
	AutomationSettings,
	Rule,
	RuleDTO,
} from './automation-settings/types'
import type {
	GraylogEventSource,
	GraylogEventSourceInput,
	GraylogEventSourceOutput,
} from './types'

const DEFAULT_EVENTS: SynchronousActionEventDTO = {
	action_output: {
		events: [],
	},
	datetime: '',
}

/**
 * Get Graylog events
 * @param {string} tenant - The tenant ID
 */
export const fetchGraylogEvents = async (tenant: string) => {
	if (!tenant) {
		return DEFAULT_EVENTS
	}

	const { data } = await api.post<SynchronousActionEventDTO>(
		`/api/tenants/${tenant}/soar/synchronous_action`,
		{
			action_name: 'graylog.get_event_definitions',
			action_input: {},
			connector_id: null,
		},
	)

	return data
}

const DEFAULT_TRIGGERED_ALERTS: SynchronousActionTriggeredAlertDTO = {
	action_output: {
		alerts: {
			events: [],
			total_events: 0,
			context: {
				event_definitions: {},
				streams: {},
			},
			duration: 0,
			parameters: {
				page: 0,
				per_page: 0,
				query: '',
				sort_by: '',
				sort_direction: '',
				sort_unmapped_type: null,
				timerange: { range: 0, type: '' },
			},
			used_indices: [],
		},
	},
	datetime: '',
}

/**
 * Fetch Graylog Triggered Alerts
 * @param {string} tenant - The tenant ID
 * @param {TriggerAlertFilters} filters - The filters to apply
 */
export const fetchGraylogTriggeredAlerts = async (
	tenant: string,
	filters?: TriggerAlertFilters,
) => {
	if (!tenant) {
		return DEFAULT_TRIGGERED_ALERTS
	}

	/**
	 * The action `get_triggered_alerts` accepts the following inputs:
	 * - limit: number
	 * - start_time: string
	 * - end_time: string
	 * @see AIR-automation-runtime\wrapper\airautomation\integrations\graylog\graylog_actions\event_definition_actions.py line 88
	 */
	const inputs: Record<string, string | number> = {
		limit: filters?.limit ?? 100,
	}

	// If start_time is provided, add it to the inputs
	// if not, should not be included in the request
	if (filters?.start_time) {
		inputs.start_time = filters.start_time
	}

	// If end_time is provided, add it to the inputs
	// if not, should not be included in the request
	if (filters?.end_time) {
		inputs.end_time = filters.end_time
	}

	const { data } = await api.post<SynchronousActionTriggeredAlertDTO>(
		`/api/tenants/${tenant}/soar/synchronous_action`,
		{
			action_name: 'graylog.get_triggered_alerts',
			action_input: inputs,
			connector_id: null,
		},
	)

	return data
}

const DEFAULT_RULE: Rule = {
	id: '',
	tenant: '',
	name: '',
	event_category_id: '',
	actions: {
		responses: [],
	},
	enabled: true,
}

/**
 * Fetch Graylog Automation Settings Details
 * @param {string} tenant - The tenant ID
 * @param {string} rule_id - The rule ID
 */
export const fetchGraylogAutomationSettingsDetails = async (
	tenant: string,
	rule_id: string | null,
): Promise<Rule> => {
	if (!tenant || !rule_id) return DEFAULT_RULE

	const {
		data: { rule },
	} = await api.get<RuleDTO>(
		`/api/tenants/${tenant}/event/automation_rules/${rule_id}`,
	)

	return rule
}

/**
 * Fetch Graylog Automation Settings
 * @param {string} tenant - The tenant ID
 */
export const fetchAutomationSettingsList = async (
	tenant: string,
): Promise<Rule[]> => {
	if (!tenant) return []

	const {
		data: { rules },
	} = await api.get<AutomationSettings>(
		`/api/tenants/${tenant}/event/automation_rules`,
	)

	return rules
}

/**
 * Create a Graylog Automation
 * @param {string} tenant - The tenant ID
 * @param {AutomationData} automation_data - The automation data
 */
export const createAutomation = async (
	tenant: string,
	automation_data: AutomationData,
) => {
	const { data } = await api.post<AutomationData>(
		`/api/tenants/${tenant}/event/automation_rules`,
		automation_data,
	)

	return data
}

/**
 * Update a Graylog Automation
 * @param {string} tenant - The tenant ID
 * @param {string} rule_id - The rule ID
 * @param {AutomationData} automation_data - The automation data
 */
export const updateAutomation = async (
	tenant: string,
	rule_id: string,
	automation_data: AutomationData,
) => {
	const { data } = await api.patch<AutomationData>(
		`/api/tenants/${tenant}/event/automation_rules/${rule_id}`,
		automation_data,
	)

	return data
}

/**
 * Delete a Graylog Automation
 * @param {string} tenant - The tenant ID
 * @param {string} rule_id - The rule ID
 */
export const deleteAutomation = async (tenant: string, rule_id: string) => {
	const { data } = await api.delete(
		`/api/tenants/${tenant}/event/automation_rules/${rule_id}`,
	)

	return data
}

/**
 * Fetch Graylog Event Sources
 * @param {string} tenant - The tenant ID
 */
export const fetchEventSources = async (tenant: string) => {
	if (!tenant) return []

	const { data } = await api.get<GraylogEventSource[]>(
		`/api/${tenant}/event/sources`,
	)

	return data
}

/**
 * Create a Graylog Event Source
 * @param {string} tenant - The tenant ID
 * @param {GraylogEventSourceInput} event_source - The event source
 */
export const createEventSource = async (
	tenant: string,
	event_source: GraylogEventSourceInput,
) => {
	const { data } = await api.post<GraylogEventSourceOutput>(
		`/api/${tenant}/event/sources/new`,
		event_source,
	)

	return data
}

export const removeEventSource = async (
	tenant: string,
	event_source_id: string,
) => {
	if (!tenant) {
		return DEFAULT_EVENTS
	}

	const { data } = await api.post(
		`/api/tenants/${tenant}/soar/synchronous_action`,
		{
			action_name: 'graylog.remove_notification_from_event_definition',
			action_input: {
				event_definition_title: event_source_id,
				notification_title: 'Bitlyft AIR',
			},
			connector_id: null,
		},
	)

	return data
}
