import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import {
	fetchAvailableIntegrations,
	fetchIntegrationDefinition,
	fetchIntegrationDetails,
	fetchTenantIntegrations,
} from './api'

export const integrationKeys = {
	all: ['integrations'] as const,
	availableList: () => [...integrationKeys.all, 'available'] as const,
	available: (tenant: string) =>
		[...integrationKeys.availableList(), tenant] as const,
	lists: () => [...integrationKeys.all, 'lists'] as const,
	list: (tenant: string) =>
		[...integrationKeys.lists(), tenant, 'no-cache'] as const,
	details: () => [...integrationKeys.all, 'details'] as const,
	detail: (tenant: string, integration_id: string) =>
		[...integrationKeys.details(), tenant, integration_id] as const,
	definitions: () => [...integrationKeys.all, 'definitions'] as const,
	definition: (tenant: string, integration_id: string) =>
		[...integrationKeys.definitions(), tenant, integration_id] as const,
}

export const integrationQueries = {
	available: (tenant: string) =>
		queryOptions({
			queryKey: integrationKeys.available(tenant),
			queryFn: () => fetchAvailableIntegrations(tenant),
			retry: defaultQueryRetry,
			select: (data) => {
				const ignoredIntegrations = ['logcollection', 'ingestion']

				return data.filter(
					(integration) =>
						!ignoredIntegrations.includes(integration.product.toLowerCase()),
				)
			},
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 30, 'm'),
			enabled: !!tenant,
		}),

	list: (tenant: string) =>
		queryOptions({
			queryKey: integrationKeys.list(tenant),
			queryFn: () => fetchTenantIntegrations(tenant),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 30, 'm'),
			select: (data) => {
				/**
				 * Remove integrations that will be deleted in the future
				 */
				const ignoredIntegrations = ['logcollection', 'siem', 'ingestion']

				return data.filter(
					(integration) => !ignoredIntegrations.includes(integration.name),
				)
			},
		}),

	detail: (tenant: string, integration_id: string) =>
		queryOptions({
			queryKey: integrationKeys.detail(tenant, integration_id),
			queryFn: () => fetchIntegrationDetails(tenant, integration_id),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 30, 'm'),
		}),

	definition: (tenant: string, integration_id: string) =>
		queryOptions({
			queryKey: integrationKeys.definition(tenant, integration_id),
			queryFn: () => fetchIntegrationDefinition(tenant, integration_id),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 30, 'm'),
		}),
}

/**
 * Custom hook to fetch the current tenant's integrations using ReactQuery
 */
export const useAvailableIntegrations = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(integrationQueries.available(tenant))
}

/**
 * Custom hook to fetch the current tenant's integrations list
 */
export const useIntegrationList = () => {
	const tenant = useCurrentTenant()
	return useSuspenseQuery(integrationQueries.list(tenant))
}

/**
 * Custom hook to fetch the current tenant's integrations details
 * @param integration_id - The ID of the integration
 */
export const useIntegrationDetails = (integration_id: string) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(integrationQueries.detail(tenant, integration_id))
}

/**
 * Custom hook to fetch the current tenant's integrations definition
 * @param integration_id - The ID of the integration
 */
export const useIntegrationDefinition = (integration_id: string) => {
	const tenant = useCurrentTenant() as string

	return useSuspenseQuery(integrationQueries.definition(tenant, integration_id))
}
