import { api } from '@/lib/api'

import type {
	CollapsibleData,
	PlaybookData,
	PlaybookDTO,
	PlaybookList,
	PlaybookPromptDecision,
	PlaybookPromptDecisionData,
	PlaybookShort,
	Prompt,
	RunPlaybookData,
	WorkflowDefinition,
} from './types'

/**
 * Fetch playbook list
 * @param {string} tenant - The tenant ID
 */
export const fetchTenantPlaybooks = async (
	tenant: string,
): Promise<PlaybookShort[]> => {
	if (!tenant) return []

	const {
		data: { playbooks },
	} = await api.get<PlaybookList>(`/api/tenants/${tenant}/soar/playbooks`)

	return playbooks
}

const EMPTY_PLAYBOOK_INFO: WorkflowDefinition = {
	inputs: [],
	metadata: {
		name: '',
		description: null,
		author: '',
		updated_by: '',
	},
	actions: [],
	outputs: null,
	exec_options: null,
}

/**
 * Fetch playbook information
 * @param {string} tenant - The tenant ID
 * @param {string} playbookName - The playbook name
 */
export const fetchPlaybookInfo = async (
	tenant: string,
	playbookName: string,
) => {
	if (!tenant || !playbookName) return EMPTY_PLAYBOOK_INFO

	const { data } = await api.get<WorkflowDefinition>(
		`/api/tenants/${tenant}/soar/playbooks/${playbookName}`,
	)

	return data
}

const EMPTY_PLAYBOOK_DETAILS: PlaybookDTO = {
	playbook: {
		soar_object_type: 'WorkflowInstance',
		tenant_id: '',
		workflow_instance_id: '',
		trigger_info: {
			trigger_type: 'manual',
			username: '',
			user_tenant_id: '',
			user_tenant_name: '',
		},
		definition: {
			inputs: [],
			metadata: {
				name: '',
				description: '',
				author: '',
				updated_by: '',
			},
			actions: [],
			outputs: [],
			exec_options: {
				max_concurrent_actions: 0,
			},
		},
	},
	summary: {
		trigger_info: undefined,
		actions_summary: {
			actions_summary: [],
		},
		workflow_uuid: '',
		workflow_name: '',
		status: 'pending',
		tenant: '',
		time_created: '',
		time_updated: '',
	},
}

/**
 * Fetch playbook activity details
 * @param {string} tenant - The tenant ID
 */
export const fetchPlaybookDetails = async (
	tenant: string,
	identifier: string | null,
) => {
	if (!tenant || !identifier) return EMPTY_PLAYBOOK_DETAILS

	const { data } = await api.get<PlaybookDTO>(
		`/api/tenants/${tenant}/soar/playbook_activity/${identifier}`,
	)

	return data
}

const EMPTY_COLLAPSIBLE_DATA: CollapsibleData = {
	summary: {
		status: 'pending',
		action_uuid: '',
		workflow_action_id: '',
		action_name: '',
	},
	results: {
		action_output: undefined,
		error_info: undefined,
		action_name: '',
		context: {
			action_uuid: '',
			adhoc: false,
			attempt_count: 0,
			tenant_id: '',
			time_scheduled: '',
			workflow_action_id: '',
			workflow_instance_id: '',
		},
	},
}

/**
 * Fetch action activity details
 * @param {string} tenant - The tenant ID
 */
export const fetchCollapsibleData = async (
	tenant: string,
	playbook_id: string,
	workflow_action_id: string,
): Promise<CollapsibleData> => {
	if (!tenant || !playbook_id || !workflow_action_id)
		return EMPTY_COLLAPSIBLE_DATA

	const actionID = encodeURIComponent(workflow_action_id)

	const { data } = await api.get<CollapsibleData>(
		`/api/tenants/${tenant}/soar/playbook_activity/${playbook_id}/actions/${actionID}`,
	)

	return data
}

const EMPTY_PROMPT: Prompt = {
	prompt: {
		options: [],
	},
}

/**
 * Fetch playbook action prompt
 * @param {string} tenant - The tenant ID
 */
export const fetchPlaybookActionPrompt = async (
	tenant: string,
	playbook_id: string | null,
	workflow_action_id: string | null,
) => {
	if (!tenant || !playbook_id || !workflow_action_id) return EMPTY_PROMPT

	const { data } = await api.get<Prompt>(
		`/api/tenants/${tenant}/soar/playbook_activity/${playbook_id}/prompt/${workflow_action_id}`,
	)

	return data
}

/**
 * Run a playbook
 */
export const runPlaybook = async (
	tenant: string,
	playbook_data: PlaybookData,
) => {
	const { data } = await api.post<RunPlaybookData>(
		`/api/tenants/${tenant}/soar/playbook_activity`,
		playbook_data,
	)

	return data
}

/**
 * Run a playbook
 */
export const postPlaybookPromptDecision = async (
	tenant: string,
	playbook_id: string,
	workflow_action_id: string,
	playbook_data: PlaybookPromptDecisionData,
) => {
	const { data } = await api.post<PlaybookPromptDecision>(
		`/api/tenants/${tenant}/soar/playbook_activity/${playbook_id}/prompt/${workflow_action_id}`,
		playbook_data,
	)

	return data
}

/**
 * Check the status of the module
 * @param action_uuid - The update URI of the module
 */
export async function getPlaybookStatus(
	tenant: string,
	workflow_instance_id: string,
): Promise<PlaybookDTO> {
	return new Promise((resolve, reject) => {
		// TODO: implement max retries
		// Check every 2 seconds
		const checkInterval = setInterval(async () => {
			try {
				const { data } = await api.get<PlaybookDTO>(
					`/api/tenants/${tenant}/soar/playbook_activity/${workflow_instance_id}`,
				)

				// If the module is finished, clear the interval and resolve the promise
				if (data?.summary.status !== 'pending') {
					clearInterval(checkInterval)
					resolve(data)
				}
			} catch (error) {
				console.log(error)
				clearInterval(checkInterval)
				reject(error)
			}
		}, 4000)
	})
}
