import { createFileRoute, Link } from '@tanstack/react-router'

import { Divider, Subtitle, Title } from '@/features/auth/components/card'
import { AuthErrorComponent } from '@/features/auth/components/error-component'
import { ConfirmCodeForm } from '@/features/auth/components/forms/confirmation-code'
import { AuthPendingComponent } from '@/features/auth/components/pending-component'

export const Route = createFileRoute('/_auth/confirm-code')({
	component: ConfirmCodeRoute,
	pendingComponent: AuthPendingComponent,
	errorComponent: AuthErrorComponent,
})

function ConfirmCodeRoute() {
	return (
		<>
			<title>Confirm Code | BitLyft Air®</title>
			<Title>Confirmation Code</Title>
			<Subtitle>Enter the code sent to your email</Subtitle>

			<ConfirmCodeForm />

			<Divider />

			<Link to="/login">Return to Sign In</Link>
		</>
	)
}
