import { lazy, Suspense, useState } from 'react'

import QueryBoundary from '@/components/common/query-boundary'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import NotificationMenu from './menu'

const NotificationList = lazy(() => import('./list'))

export const NotificationPanel = () => {
	const [tab, setTab] = useState<'unread' | 'read'>('unread')

	return (
		<div className="flex flex-col items-start justify-start gap-2 pr-3">
			<Tabs
				value={tab}
				className="w-full"
				onValueChange={(value) => setTab(value as 'unread' | 'read')}
			>
				<div className="flex flex-row items-center justify-start gap-2">
					<TabsList>
						<TabsTrigger value="unread">Unread</TabsTrigger>
						<TabsTrigger value="read">Read</TabsTrigger>
					</TabsList>

					<div className="flex-auto" />

					{tab === 'unread' && <NotificationMenu />}
				</div>

				<TabsContent value="unread">
					<QueryBoundary fallback={() => <div>Error!</div>}>
						<Suspense fallback={<TableSkeleton rows={4} />}>
							<NotificationList read={false} />
						</Suspense>
					</QueryBoundary>
				</TabsContent>

				<TabsContent value="read">
					<QueryBoundary fallback={() => <div>Error!</div>}>
						<Suspense fallback={<TableSkeleton rows={4} />}>
							<NotificationList read={true} />
						</Suspense>
					</QueryBoundary>
				</TabsContent>
			</Tabs>
		</div>
	)
}

export default NotificationPanel
