import { useState } from 'react'

import { time } from '@/utils'
import { useCopyToClipboard } from '@uidotdev/usehooks'
import { Check, Copy } from 'lucide-react'

import { Button } from '../ui/button'

type CopyButtonProps = {
	text: string
}

/**
 * A button that copies text to the clipboard
 * @param {string} text - The text to copy
 * @returns {JSX.Element} - The CopyButton component
 */
export const CopyButton = ({ text }: CopyButtonProps) => {
	const [_, copyToClipboard] = useCopyToClipboard()
	const [canCopy, setCanCopy] = useState<boolean>(true)

	const handleCopy = () => {
		setCanCopy(false)
		copyToClipboard(text)

		setTimeout(
			() => {
				setCanCopy(true)
			},
			time(2, 's'),
		)
	}

	return (
		<Button
			type="button"
			size="icon"
			className="aspect-square"
			variant="outline"
			onClick={handleCopy}
		>
			{canCopy ? (
				<Copy
					data-testid="copy-icon"
					className="h-4 w-4 duration-200 animate-in"
				/>
			) : (
				<Check
					data-testid="check-icon"
					className="h-4 w-4 animate-pulse text-green-500 duration-200 direction-normal repeat-1"
				/>
			)}
		</Button>
	)
}
