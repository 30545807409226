import { z } from 'zod'

export const schema = z
	.object({
		password: z
			.string()
			.min(16, "Password doesn't meet the requirements")
			.refine((val) => {
				const hasNumber = /\d/.test(val)
				const hasLowerCase = /[a-z]/.test(val)
				const hasUpperCase = /[A-Z]/.test(val)
				const hasSpecial = /[^$*.[\]{}()?!"@#%&/\\,><':;|_~`=+-]/.test(val)

				return hasNumber && hasLowerCase && hasUpperCase && hasSpecial
			}),
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: 'Passwords do not match',
		path: ['confirmPassword'],
	})

export type ResetPasswordFormValues = z.infer<typeof schema>
