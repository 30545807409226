import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'

import { actionQueries } from '@/features/actions/queries'
import { roleAuthGuard } from '@/features/auth/helpers'
import { Roles } from '@/features/auth/types'
import { graylogQueries } from '@/features/graylog/queries'
import { playbookQueries } from '@/features/playbooks/queries'
import { getCurrentTenant } from '@/features/user/store'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const AutomationSettingsDetailsForm = lazy(
	() => import('@/features/graylog/automation-settings/component/form'),
)
const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const Route = createFileRoute(
	'/_dashboard/graylog/automation-settings/edit/$id',
)({
	beforeLoad: () => roleAuthGuard(Roles.enum.Analyst),

	loader: async ({ context: { queryClient }, params }) => {
		const { id } = params

		const tenant_id = getCurrentTenant() as string

		// Prefetch list of events and remediations
		queryClient.prefetchQuery(actionQueries.list(tenant_id))
		queryClient.prefetchQuery(graylogQueries.events(tenant_id))
		queryClient.prefetchQuery(playbookQueries.list(tenant_id))

		// Fetch event details
		return await queryClient.ensureQueryData(
			graylogQueries.automationDetail(tenant_id, id),
		)
	},

	component: AutomationSettingsEvent,
	pendingComponent: AutomationSettingsEventFallback,
	errorComponent: AutomationSettingsError,
})

function AutomationSettingsEvent() {
	const { id } = Route.useParams()

	return (
		<div className="flex w-full flex-col items-start justify-start gap-2">
			<ErrorBoundary>
				<Suspense fallback={<TableSkeleton rows={10} />}>
					<AutomationSettingsDetailsForm ruleId={id} />
				</Suspense>
			</ErrorBoundary>
		</div>
	)
}

function AutomationSettingsEventFallback() {
	return (
		<div className="flex w-full flex-row items-center justify-between">
			<Skeleton className="h-12 w-1/2" />
			<Skeleton className="h-12 w-1/2" />
		</div>
	)
}

/**
 * Error component for Automation Settings
 */
function AutomationSettingsError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
