import { useEffect } from 'react'

import { useRouter } from '@tanstack/react-router'

import type { LinkProps } from '@tanstack/react-router'

/**
 * Preloads a route
 * @param route - The route to preload
 * @see https://tanstack.com/router/latest/docs/framework/react/guide/preloading#preloading-manually
 */
export const usePreloadRoute = (route: Pick<LinkProps, 'to'>) => {
	const router = useRouter()

	useEffect(() => {
		async function preload() {
			try {
				await router.preloadRoute({
					to: route.to,
				})
			} catch (error) {
				console.error(error)
			}
		}

		preload()
	}, [router, route])
}
