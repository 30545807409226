import { sentry } from '@/lib/sentry'

import { time } from '../date'

import type { QueryState } from '@tanstack/react-query'
import type { XiorError } from 'xior'

/**
 * Helper function to calculate the stale time for a successful query
 * @param state - The query state
 * @param amount - The amount of time
 * @param unit - The unit of time
 */
export const successStaleTime = (
	state: QueryState<any, any>,
	amount: number,
	unit: 's' | 'm' | 'h' | 'd',
) => {
	const err = state.error as XiorError

	// Don't cache if the query is not successful
	if (state.status !== 'success') return 0

	// Don't cache if there's an error with status code >= 400
	if (
		err &&
		'response' in err &&
		err.response?.status &&
		err.response?.status >= 400
	) {
		// If something fails, send an error to Sentry
		sentry.captureException(err, {
			extra: {
				origin: 'successStaleTime',
				queryState: JSON.stringify(state),
			},
		})

		return 0
	}

	return time(amount, unit)
}
