import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'

import { sentry } from '@/lib/sentry'

import { useAuthNextSteps } from '@/features/auth/hooks/useAuthNextSteps'
import { useAuthLogIn } from '@/features/auth/mutations'

import { LoadingButton } from '@/components/buttons/loading-button'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { schema } from './validation'

import type { LoginFormValues } from './validation'

export const LoginForm = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const { handleAuthNextStep } = useAuthNextSteps()
	const { mutate: signIn } = useAuthLogIn()

	const form = useForm<LoginFormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			email: '',
			password: '',
		},
	})

	const onSubmit = form.handleSubmit((data) => {
		setIsLoading(true)

		signIn(
			{
				email: data.email,
				password: data.password,
			},
			{
				onSuccess: (responseData) =>
					handleAuthNextStep(responseData, {
						email: data?.email,
					}),
				onError: (error) => {
					sentry.captureException(error, {
						extra: {
							origin: 'login-form',
							page: window.location.href,
						},
					})

					setIsLoading(false)

					form.setError('password', {
						message: error.message,
					})
				},
				onSettled: () => {
					setIsLoading(false)
				},
			},
		)
	})

	return (
		<Form {...form}>
			<form
				role="form"
				onSubmit={onSubmit}
				className="my-4 flex w-full flex-col items-start justify-center"
			>
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem className="mb-6 w-full">
							<FormControl>
								<Input
									{...field}
									type="email"
									data-testid="email"
									className="bg-transparent px-4 py-6 aria-[invalid=true]:border-destructive"
									placeholder="Email address"
									autoFocus
									required
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem className="mb-6 w-full">
							<FormControl>
								<Input
									{...field}
									type="password"
									className="bg-transparent px-4 py-6 aria-[invalid=true]:border-destructive"
									placeholder="Password"
									data-testid="password"
									required
								/>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>
				<LoadingButton
					isLoading={isLoading}
					data-testid="form-button"
					type="submit"
					variant="outline"
					disabled={isLoading}
					className="w-full bg-bl-primary text-white hover:bg-bl-primary hover:bg-opacity-65"
				>
					Login
				</LoadingButton>
			</form>
		</Form>
	)
}
