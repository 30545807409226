import { Link } from '@tanstack/react-router'

import { cn } from '@/utils'

import { formatUTCToLocal } from '@/lib/date'

import { HasRole } from '@/features/auth/components/has-role'
import { Roles } from '@/features/auth/types'

import { Button } from '@/components/ui/button'
import {
	Tooltip,
	TooltipArrow,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip'

import { useIntegrationDetails } from '../queries'

import type { Integration } from '../types'

type IntegrationStatusCardProps = {
	integration: Integration
}

export const IntegrationStatusCard = ({
	integration,
}: IntegrationStatusCardProps) => {
	const { data: connector } = useIntegrationDetails(integration.id)

	const isConnected = connector?.test_connection_status
	const lastUpdated = connector?.test_connection_time
		? formatUTCToLocal(connector.test_connection_time)
		: null

	return (
		<div className="flex h-auto min-h-[200px] w-full flex-col items-start justify-center gap-4 rounded-md border border-foreground bg-background p-6">
			<h3 className="text-2xl font-bold leading-tight">{integration?.name}</h3>
			<h4 className="text-sm leading-tight text-muted-foreground">
				{connector?.name}
			</h4>
			<div className="flex flex-row items-start gap-2">
				<TooltipProvider delayDuration={0}>
					<Tooltip>
						<TooltipTrigger asChild>
							<div className="flex items-center gap-2 rounded-lg bg-foreground p-2">
								<div
									className={cn('size-3 animate-pulse rounded-full', {
										'bg-green-500': isConnected,
										'bg-red-500': !isConnected,
									})}
								/>

								<p className="text-sm leading-none text-muted-foreground">
									{isConnected ? 'Connected' : 'Disconnected'}
								</p>
							</div>
						</TooltipTrigger>
						<TooltipContent sticky="always" side="top">
							Last updated: {lastUpdated ?? 'N/A'}
							<TooltipArrow />
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</div>

			<HasRole role={Roles.enum.SystemManager}>
				<Button variant="outline" size="sm" className="self-end" asChild>
					<Link
						to="/integrations/$id"
						params={{ id: integration.id }}
						preload="intent"
					>
						Manage
					</Link>
				</Button>
			</HasRole>
		</div>
	)
}
