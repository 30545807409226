import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import type { ClassValue } from 'clsx'

/**
 * Helper function to merge tailwind classes
 * @param {ClassValue[]} inputs - classes to merge
 * @returns merged className string
 */
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}
