import { Link } from '@tanstack/react-router'

import { CircleAlert } from 'lucide-react'

import { HasRole } from '@/features/auth/components/has-role'
import { Roles } from '@/features/auth/types'

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'

import { useCheckIntegrations } from '../hooks/useCheckIntegrations'

export const NoIntegrationsAlert = () => {
	const { hasIntegrations } = useCheckIntegrations()

	if (hasIntegrations) return null

	return (
		<div className="mt-4 w-full px-6">
			<Alert className="flex w-full flex-col items-center justify-between gap-4 sm:flex-row">
				<div className="flex items-start gap-2">
					<CircleAlert className="size-4 stroke-primary" />

					<div className="flex flex-col">
						<AlertTitle>Heads up!</AlertTitle>
						<AlertDescription>
							Your BitLyft Air® account has no integrations connected.
						</AlertDescription>
					</div>
				</div>

				<HasRole role={Roles.enum.SystemManager}>
					<Button variant="outline" asChild className="w-full sm:w-auto">
						<Link to="/integrations">Connect an integration</Link>
					</Button>
				</HasRole>
			</Alert>
		</div>
	)
}
