import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import type {
	ConfirmationHeaders,
	ConfirmationResponse,
	ConfirmationState,
} from './types'

type ConfirmationActions = {
	open: (params: {
		title?: string
		confirmationData: ConfirmationResponse
		onConfirm: (headers: ConfirmationHeaders) => void
		onClose?: () => void
	}) => void
	close: () => void
}

type ConfirmationStore = {
	state: ConfirmationState
	actions: ConfirmationActions
}

const initialState: ConfirmationState = {
	isOpen: false,
	warning: '',
	requiresPassphrase: false,
	onConfirm: () => undefined,
}

export const useConfirmationStore = createWithEqualityFn<ConfirmationStore>()(
	(set, get) => ({
		state: initialState,
		actions: {
			open: ({ title, confirmationData, onConfirm, onClose }) => {
				set({
					state: {
						isOpen: true,
						title,
						warning: confirmationData.warning,
						requiresPassphrase: !!confirmationData.passphrase,
						confirmationData,
						onConfirm,
						onClose,
					},
				})
			},
			close: () => {
				const { onClose } = get().state
				onClose?.()
				set({ state: initialState })
			},
		},
	}),
	shallow,
)

// Selectors with specific return types for better type inference
export const useConfirmationState = () =>
	useConfirmationStore((store) => store.state)

export const useConfirmationActions = () =>
	useConfirmationStore((store) => store.actions)
