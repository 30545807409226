import LastUpdated from '@/components/common/last-updated'

import { useActivities } from '../queries'

export const ActivityLastUpdated = () => {
	const { refetch, dataUpdatedAt } = useActivities()

	return <LastUpdated lastUpdated={dataUpdatedAt} refetch={refetch} />
}

export default ActivityLastUpdated
