import { queryOptions, useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import {
	fetchCollapsibleData,
	fetchPlaybookActionPrompt,
	fetchPlaybookDetails,
	fetchPlaybookInfo,
	fetchTenantPlaybooks,
} from './api'

import type { PlaybookStatus } from './types'

export const playbookKeys = {
	all: ['playbooks'] as const,
	lists: () => [...playbookKeys.all, 'list'] as const,
	list: (tenant: string) => [...playbookKeys.lists(), tenant] as const,
	infos: () => [...playbookKeys.all, 'info'] as const,
	info: (tenant: string, playbookName: string) =>
		[...playbookKeys.infos(), tenant, playbookName] as const,
	details: () => [...playbookKeys.all, 'details'] as const,
	detail: (tenant: string, playbookName: string | null) =>
		[...playbookKeys.details(), tenant, playbookName, 'no-cache'] as const,
	collapsibles: () => [...playbookKeys.all, 'collapsibles'] as const,
	collapsible: (
		tenant: string,
		playbookName: string,
		workflowActionId: string,
	) =>
		[
			...playbookKeys.collapsibles(),
			tenant,
			playbookName,
			workflowActionId,
			'no-cache',
		] as const,
	prompts: () => [...playbookKeys.all, 'prompts'] as const,
	prompt: (tenant: string, playbookName: string, workflowActionId: string) =>
		[
			...playbookKeys.prompts(),
			tenant,
			playbookName,
			workflowActionId,
			'no-cache',
		] as const,
}

export const playbookQueries = {
	list: (tenant: string) =>
		queryOptions({
			queryKey: playbookKeys.list(tenant),
			queryFn: () => fetchTenantPlaybooks(tenant),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 1, 'h'),
			gcTime: time(1, 'h'),
		}),

	info: (tenant: string, playbookName: string) =>
		queryOptions({
			queryKey: playbookKeys.info(tenant, playbookName),
			queryFn: () => fetchPlaybookInfo(tenant, playbookName),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 1, 'h'),
			gcTime: time(1, 'h'),
		}),

	detail: (tenant: string, playbookName: string | null) =>
		queryOptions({
			queryKey: playbookKeys.detail(tenant, playbookName),
			queryFn: () => fetchPlaybookDetails(tenant, playbookName),
			retry: defaultQueryRetry,
			refetchInterval: ({ state }) => {
				const waitingStatuses: PlaybookStatus[] = [
					'running',
					'pending',
					'waiting',
				]

				// If the playbook is in a waiting state, refetch every 5 seconds
				const shouldRefetch = waitingStatuses.includes(
					state?.data?.summary.status as PlaybookStatus,
				)

				const hasPendingActions =
					state?.data?.summary.actions_summary.actions_summary.some(
						(action) =>
							action.status === 'pending' || action.status === 'running',
					)

				return shouldRefetch || hasPendingActions ? time(5, 's') : false
			},
			staleTime: ({ state }) => {
				if (state.status !== 'success') return 0

				const cacheableStatuses = ['success', 'error']

				const hasPendingActions =
					state?.data?.summary.actions_summary.actions_summary.some(
						(action) =>
							action.status === 'pending' || action.status === 'running',
					)

				// If the playbook is in a success or error state, cache the data for 1 hour
				const canCache =
					cacheableStatuses.includes(state.data?.summary.status ?? '') &&
					!hasPendingActions

				return canCache ? time(1, 'h') : time(10, 's')
			},
			gcTime: time(1, 'h'),
		}),

	collapsible: (
		tenant: string,
		playbookName: string,
		workflowActionId: string,
	) =>
		queryOptions({
			queryKey: playbookKeys.collapsible(
				tenant,
				playbookName,
				workflowActionId,
			),
			queryFn: () =>
				fetchCollapsibleData(tenant, playbookName, workflowActionId),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => successStaleTime(state, 2, 'm'),
			gcTime: time(30, 'm'),
			enabled: !!tenant && !!playbookName && !!workflowActionId,
		}),

	prompt: (tenant: string, playbookName: string, workflowActionId: string) =>
		queryOptions({
			queryKey: playbookKeys.prompt(tenant, playbookName, workflowActionId),
			queryFn: () =>
				fetchPlaybookActionPrompt(tenant, playbookName, workflowActionId),
			retry: defaultQueryRetry,
			staleTime: ({ state }) => {
				if (state.status !== 'success') return 0

				const canCache = !!state.data
				return canCache ? time(1, 'h') : time(10, 's')
			},
			gcTime: time(1, 'h'),
			enabled: !!tenant && !!playbookName && !!workflowActionId,
		}),
}

/**
 * Custom hook to fetch Playbooks
 */
export const usePlaybooks = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(playbookQueries.list(tenant))
}

/**
 * Custom hook to fetch Playbook Activity
 */
export const usePlaybookInfo = (playbookName: string) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(playbookQueries.info(tenant, playbookName))
}

/**
 * Custom hook to fetch Playbook Details
 */
export const usePlaybookDetails = (playbookName: string | null) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(playbookQueries.detail(tenant, playbookName))
}

/**
 * Custom hook to fetch Playbook Collapsible Data
 */
export const usePlaybookCollapsibleData = (
	playbookName: string,
	workflowActionId: string,
) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(
		playbookQueries.collapsible(tenant, playbookName, workflowActionId),
	)
}

/**
 * Custom hook to fetch playbook action prompt using ReactQuery
 */
export const usePlaybookActionPrompt = (
	playbookName: string,
	workflowActionId: string,
) => {
	const tenant = useCurrentTenant()

	return useQuery(
		playbookQueries.prompt(tenant as string, playbookName, workflowActionId),
	)
}
