import { lazy } from 'react'

import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { createFileRoute, Outlet } from '@tanstack/react-router'

import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'

import type { XiorError } from 'xior'

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const Route = createFileRoute('/_dashboard/integrations')({
	component: IntegrationsRoute,
	errorComponent: IntegrationsErrorRoute,
})

function IntegrationsRoute() {
	return (
		<Section>
			<Outlet />
		</Section>
	)
}

function IntegrationsErrorRoute({ error }: { error: Error }) {
	const err = error as XiorError
	const { reset } = useQueryErrorResetBoundary()

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<Section>
			<SectionTitle>Integrations</SectionTitle>

			<ErrorBoundaryComponent resetError={reset} />
		</Section>
	)
}
