import { useMutation, useQueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { useNavigate } from '@tanstack/react-router'

import { persister } from '@/lib/react-query'

import { tenantKeys } from '../tenant/queries'

import { deleteMe, updateMe } from './api'
import { userKeys } from './queries'
import { useCurrentTenant } from './store'

import type { ConfirmationHeaders } from '../confirmation-dialog/types'
import type { User } from './types/api'

export const userMutations = {
	all: ['user'] as const,
	update: () => [...userMutations.all, 'update'] as const,
	delete: () => [...userMutations.all, 'delete'] as const,
}

/**
 * Custom hook to fetch the current user's data using ReactQuery
 */
export const useUpdateMe = () => {
	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (user_data: Partial<User>) => updateMe(user_data),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: userKeys.me(),
			})
			queryClient.invalidateQueries({
				queryKey: tenantKeys.users(tenant),
			})

			// We bust the cache by setting a new buster value
			persistQueryClient({
				queryClient,
				persister,
				buster: new Date().toISOString(),
			})
		},
	})
}

/**
 * Custom hook to delete the current user's data using ReactQuery
 */
export const useDeleteMe = () => {
	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()
	const navigate = useNavigate()

	return useMutation({
		mutationFn: (headers?: ConfirmationHeaders) => deleteMe(headers),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: userKeys.me(),
			})
			queryClient.invalidateQueries({
				queryKey: tenantKeys.users(tenant),
			})
		},
		onSuccess: () => {
			navigate({
				to: '/logout',
			})
		},
	})
}
