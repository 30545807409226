import { useEffect, useState } from 'react'

import { cn, time } from '@/utils'
import { RefreshCcw } from 'lucide-react'

import { formatRelativeTime } from '@/lib/date'

import { LoadingButton } from '../buttons/loading-button'

type LastUpdatedProps = {
	lastUpdated: string | number | Date
	refetch: () => void
	className?: string
}

/**
 * Component to display the last updated time and a refresh button
 * @param {string | number | Date} lastUpdated - the date to display
 * @param {() => void} refetch - the function to call when the refresh button is clicked
 * @param {string} className - the class name to apply to the component
 */
export const LastUpdated = ({
	lastUpdated,
	refetch,
	className,
}: LastUpdatedProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [parsedDate, setParsedDate] = useState<string | null>(
		formatRelativeTime(lastUpdated),
	)

	const handleRefetch = async () => {
		setIsLoading(true)
		await refetch()
		setParsedDate(formatRelativeTime(lastUpdated))
		setIsLoading(false)
	}

	useEffect(() => {
		// Update the parsed date every minute
		const interval = setInterval(
			() => {
				setParsedDate(formatRelativeTime(lastUpdated))
			},
			time(1, 'm'),
		)

		return () => clearInterval(interval)
	}, [lastUpdated])

	return (
		<div
			className={cn(
				'flex items-center justify-between gap-4 self-end',
				className,
			)}
		>
			<p className="text-muted-foreground" data-testid="last-updated-text">
				Last updated: {parsedDate}
			</p>
			<LoadingButton
				isLoading={isLoading}
				variant="outline"
				size="icon"
				onClick={handleRefetch}
				className="h-8 w-8"
				title="Refresh"
			>
				<RefreshCcw className="h-4 w-4" data-testid="refresh-icon" />
			</LoadingButton>
		</div>
	)
}

export default LastUpdated
