import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import { fetchOnboardingInProgress, fetchWhitelistIP } from './api'

export const wizardKeys = {
	all: ['wizard'] as const,
	whitelistIP: (tenant: string) => [...wizardKeys.all, 'whitelistIP', tenant],
	onboardingInProgress: (tenant: string) => [
		...wizardKeys.all,
		'onboarding-in-progress',
		tenant,
	],
}

export const wizazrdQueries = {
	whitelistIP: (tenant: string) =>
		queryOptions({
			queryKey: wizardKeys.whitelistIP(tenant),
			queryFn: () => fetchWhitelistIP(tenant),
			retry: defaultQueryRetry,
			gcTime: time(30, 'm'),
			staleTime: ({ state }) => successStaleTime(state, 15, 'm'),
			enabled: !!tenant,
		}),

	onboardingInProgress: (tenant: string) =>
		queryOptions({
			queryKey: wizardKeys.onboardingInProgress(tenant),
			queryFn: () => fetchOnboardingInProgress(tenant),
			retry: defaultQueryRetry,
			gcTime: time(15, 'm'),
			staleTime: ({ state }) => successStaleTime(state, 5, 'm'),
			enabled: !!tenant,
		}),
}

/**
 * Custom hook to fetch the current tenant's whitelist IP using ReactQuery
 */
export const useWizardWhitelistIP = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(wizazrdQueries.whitelistIP(tenant))
}

/**
 * Custom hook to fetch the current tenant's onboarding in progress using ReactQuery
 */
export const useWizardOnboardingInProgress = () => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery(wizazrdQueries.onboardingInProgress(tenant))
}
