import { Suspense, useTransition } from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'

import { RotateCcw } from 'lucide-react'

import { sentry } from '@/lib/sentry'

import QueryBoundary from '@/components/common/query-boundary'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'

import { EventList } from './event-list'
import MappedEvents from './mapped-events'

import type { XiorError } from 'xior'

type SettingsFormProps = {
	handleClose: () => void
}

const Route = getRouteApi('/_dashboard/actions/')

export const SettingsForm = ({ handleClose }: SettingsFormProps) => {
	const [_, startTransition] = useTransition()

	const action = Route.useSearch({
		select: (search) =>
			search.settings ? decodeURIComponent(search.settings) : '',
	})

	const navigate = useNavigate()

	const handleSelectEvent = (event: string) => {
		handleClose()

		startTransition(() => {
			navigate({
				to: '/graylog/automation-settings/new',
				search: {
					event_id: event,
					action_name: action,
				},
				replace: true,
			})
		})
	}

	return (
		<div className="flex w-full flex-col items-start justify-start gap-8">
			<QueryBoundary
				fallback={({ resetError, error }) => (
					<ErrorFallback reset={resetError} error={error} />
				)}
			>
				<Suspense fallback={<Skeleton className="h-10 w-full" />}>
					<EventList handleSelectEvent={handleSelectEvent} />
				</Suspense>
			</QueryBoundary>

			<QueryBoundary
				fallback={({ resetError, error }) => (
					<ErrorFallback reset={resetError} error={error} />
				)}
			>
				<Suspense fallback={<Skeleton className="h-10 w-full" />}>
					<MappedEvents action={action} handleClose={handleClose} />
				</Suspense>
			</QueryBoundary>

			<Button onClick={handleClose} variant="outline" className="my-8">
				Close
			</Button>
		</div>
	)
}

function ErrorFallback({
	reset,
	error,
}: {
	reset: () => void
	error: unknown
}) {
	const err = error as XiorError

	sentry.captureException(err, {
		extra: {
			origin: 'playbook-settings-panel',
			url: window.location.href,
		},
	})

	return (
		<div className="flex w-full flex-col items-center justify-center gap-2 rounded-md border border-red-500 bg-background p-4 text-center">
			<p className="font-bold uppercase text-muted-foreground">
				An Error Has Occurred
			</p>

			<p className="text-sm text-muted-foreground">
				This incident has been reported. <br /> Please try again or contact
				support if the issue persists.
			</p>

			<Button
				variant="outline"
				size="sm"
				className="mt-4 text-sm"
				onClick={reset}
			>
				<RotateCcw className="mr-2 h-4 w-4" /> Try Again
			</Button>
		</div>
	)
}

export default SettingsForm
