import { useMutation, useQueryClient } from '@tanstack/react-query'

import { toast } from 'sonner'

import { useCurrentTenant } from '../user/store'

import {
	changeUserRole,
	createTenantContact,
	createTenantUser,
	deleteTenantContact,
	deleteTenantUser,
	resetTenantUserPassword,
	updateTenantContact,
	updateTenantUser,
} from './api'
import { tenantKeys } from './queries'

import type { ConfirmationHeaders } from '../confirmation-dialog/types'
import type {
	ChangeUserRoleFormValues,
	CreateContactData,
	CreateUserData,
	UpdateUserFormValues,
} from './types'
import type { XiorError } from 'xior'

export const tenantMutations = {
	all: ['tenant'] as const,
	createContact: (tenant: string) =>
		[...tenantMutations.all, 'createContact', tenant] as const,
	deleteContact: (tenant: string) =>
		[...tenantMutations.all, 'deleteContact', tenant] as const,
	updateContact: (tenant: string) =>
		[...tenantMutations.all, 'updateContact', tenant] as const,
	createUser: (tenant: string) =>
		[...tenantMutations.all, 'createUser', tenant] as const,
	updateUser: (tenant: string) =>
		[...tenantMutations.all, 'updateUser', tenant] as const,
	deleteUser: (tenant: string) =>
		[...tenantMutations.all, 'deleteUser', tenant] as const,
	changeUserRole: (tenant: string) =>
		[...tenantMutations.all, 'changeUserRole', tenant] as const,
	resetUserPassword: (tenant: string) =>
		[...tenantMutations.all, 'resetUserPassword', tenant] as const,
}

/**
 * Hook to create a new Tenant Contact
 */
export const useCreateTenantContact = () => {
	const queryClient = useQueryClient()
	const tenant = useCurrentTenant()

	return useMutation({
		mutationKey: tenantMutations.createContact(tenant),
		mutationFn: (contact_data: Partial<CreateContactData>) =>
			createTenantContact(tenant as string, contact_data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: tenantKeys.contacts(tenant) })
			toast.success('Contact created successfully!')
		},
		onError: () => {
			toast.error('Error creating contact')
		},
	})
}

/**
 * Custom hook to delete a Tenant Contact
 */
export const useDeleteTenantContact = () => {
	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: tenantMutations.deleteContact(tenant),
		mutationFn: (name: string) => deleteTenantContact(tenant as string, name),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: tenantKeys.contacts(tenant) })
			toast.success('Contact deleted successfully!')
		},
		onError: () => {
			toast.error('Error deleting contact')
		},
	})
}

type UpdateContactData = {
	contact_name: string
	contact_data: Partial<CreateContactData>
}

/**
 * Custom hook to update a Tenant Contact
 */
export const useUpdateTenantContact = () => {
	const queryClient = useQueryClient()
	const tenant = useCurrentTenant()

	return useMutation({
		mutationKey: tenantMutations.updateContact(tenant),
		mutationFn: ({ contact_name, contact_data }: UpdateContactData) =>
			updateTenantContact(tenant as string, contact_name, contact_data),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: tenantKeys.contacts(tenant) })
			toast.success('Contact updated successfully!')
		},
		onError: () => {
			toast.error('Error updating contact')
		},
	})
}

/**
 * Hook to create a new Tenant User
 */
export const useCreateTenantUser = () => {
	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: tenantMutations.createUser(tenant),
		mutationFn: (user_data: Partial<CreateUserData>) =>
			createTenantUser(tenant as string, user_data),
		onSuccess: () => {
			toast.success('User created successfully!')
		},
		onError: (error) => {
			const err = error as XiorError

			toast.error('Error while creating user', {
				description:
					err.response?.data.error.message ?? 'An unknown error occurred',
			})
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: tenantKeys.users(tenant) })
		},
	})
}

/**
 * Custom hook to update a Tenant User
 */
export const useUpdateTenantUser = () => {
	const tenant = useCurrentTenant() as string
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (user_data: UpdateUserFormValues) =>
			updateTenantUser(tenant, user_data),
		onSuccess: () => {
			toast.success('User updated successfully')
		},
		onError: (error) => {
			const err = error as XiorError

			toast.error('Error updating user', {
				description:
					err.response?.data.error.message ?? 'An unknown error occurred',
			})
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: tenantKeys.users(tenant),
			})
		},
	})
}

/**
 * Custom hook to update user role with confirmation support
 */
export const useChangeTenantUserRole = () => {
	const tenant = useCurrentTenant() as string
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: (params: {
			user_data: ChangeUserRoleFormValues
			headers?: ConfirmationHeaders
		}) => changeUserRole(tenant, params),
		onSuccess: () => {
			toast.success('User role updated successfully')
		},
		onError: (error) => {
			const err = error as XiorError

			toast.error('Error updating user role', {
				description:
					err.response?.data.error.message ?? 'An unknown error occurred',
			})
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: tenantKeys.users(tenant),
			})
		},
	})
}

/**
 * Custom hook to delete a Tenant User
 */
export const useDeleteTenantUser = () => {
	const tenant = useCurrentTenant()
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: tenantMutations.deleteUser(tenant),
		mutationFn: (username: string) =>
			deleteTenantUser(tenant as string, username),
		onSuccess: () => {
			toast.success('User deleted successfully!')
		},
		onError: (error) => {
			const err = error as XiorError

			toast.error('Error deleting user', {
				description:
					err.response?.data.error.message ?? 'An unknown error occurred',
			})
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: tenantKeys.users(tenant) })
		},
	})
}

/**
 * Hook to create a new Tenant Contact
 */
export const useResetTenantUserPassword = () => {
	const tenant = useCurrentTenant()

	return useMutation({
		mutationKey: tenantMutations.resetUserPassword(tenant),
		mutationFn: (username: string | undefined) =>
			resetTenantUserPassword(tenant, username),
		onSuccess: () => {
			toast.success('User password reset successfully!')
		},
		onError: () => {
			toast.error('Error resetting user password')
		},
	})
}
