import { maskitoUpdateElement } from '@maskito/core'
import { maskitoEventHandler, maskitoWithPlaceholder } from '@maskito/kit'

import type { MaskitoOptions } from '@maskito/core'

const IPV4_MASK_PLACEHOLDER = '0.0.0.0'
const IPV6_MASK_PLACEHOLDER = '0:0:0:0:0:0:0:0'

const {
	removePlaceholder: removeIPv4Placeholder,
	plugins: ipv4Plugins,
	...ipv4PlaceholderOptions
} = maskitoWithPlaceholder(IPV4_MASK_PLACEHOLDER)

const {
	removePlaceholder: removeIPv6Placeholder,
	plugins: ipv6Plugins,
	...ipv6PlaceholderOptions
} = maskitoWithPlaceholder(IPV6_MASK_PLACEHOLDER)

/**
 * Mask for IP addresses
 * @see https://github.com/taiga-family/maskito/issues/252#issuecomment-1854265522
 */

export const ipv4Mask: MaskitoOptions = {
	preprocessors: ipv4PlaceholderOptions.preprocessors,
	mask: /^(((\d+\.){0,2}\d+\.?)|((\d+\.){3}\d+))(:([0-9]{0,5}))?$/,
	postprocessors: [
		...ipv4PlaceholderOptions.postprocessors,
		({ value, selection }) => {
			const [initialFrom, initialTo] = selection
			let [from, to] = selection

			// Ensures ip parts stays at or below 255
			const boundIpParts = (ip: string) => {
				let partsOfIp = ip.split('.')

				partsOfIp = partsOfIp.map((part) => {
					const partNum = parseInt(part)
					if (partNum > 255) return '255'
					// Handle when ip part contains multiple zeros
					if (part.startsWith('0') && part.endsWith('0')) return '0'
					else if (part.startsWith('0') && part !== '0')
						return (part = part.replaceAll('0', ''))
					return part
				})

				return partsOfIp.join('.')
			}

			// Ensures port stays at or below 65535
			const boundPort = (ip: string) => {
				const address = ip.split(':')[0] ?? ''
				let port = ip.split(':')[1] ?? ''
				// Handle when ip part contains multiple zeros
				if (port.startsWith('0') && port.endsWith('0')) port = '0'
				else if (port.startsWith('0') && port !== '0')
					port = port.replaceAll('0', '')
				const portNum = parseInt(port)
				if (portNum > 65535) return (port = '65535')
				return `${address}:${port}`
			}

			const processedIntegerPart = Array.from(value).reduce(
				(formattedValuePart, char, i) => {
					if (formattedValuePart.includes(':')) {
						return boundPort(formattedValuePart + char)
					}
					const partsOfIp = formattedValuePart.split('.')
					const lastPartOfIp = partsOfIp[partsOfIp.length - 1] ?? ''
					const isPositionForSeparator =
						!(char === '.') &&
						formattedValuePart.length &&
						(lastPartOfIp.length === 3 ||
							partsOfIp[partsOfIp.length - 1].startsWith('0'))
					if (partsOfIp.length > 4) {
						return formattedValuePart
					}
					if (!isPositionForSeparator) {
						return boundIpParts(formattedValuePart + char)
					}
					if (i <= initialFrom) {
						from++
					}
					if (i <= initialTo) {
						to++
					}
					if (partsOfIp.length >= 4) {
						if (char === ':') char = ''
						return formattedValuePart + ':' + char
					} else {
						return formattedValuePart + '.' + char
					}
				},
				'',
			)

			return {
				value: processedIntegerPart,
				selection: [from, to],
			}
		},
	],
	plugins: [
		...ipv4Plugins,
		maskitoEventHandler('focus', (element) => {
			const initialValue = element.value || ''

			maskitoUpdateElement(
				element,
				initialValue + IPV4_MASK_PLACEHOLDER.slice(initialValue.length),
			)
		}),
		maskitoEventHandler('blur', (element) => {
			const cleanValue = removeIPv4Placeholder(element.value)

			maskitoUpdateElement(element, cleanValue === '0.0.0.0' ? '' : cleanValue)
		}),
	],
}

export const ipv6Mask: MaskitoOptions = {
	mask: /^(?:[A-Fa-f0-9]{1,4}:){0,7}(?:[A-Fa-f0-9]{1,4})?$/,
	preprocessors: ipv6PlaceholderOptions.preprocessors,
	postprocessors: [
		...ipv6PlaceholderOptions.postprocessors,
		({ value, selection }) => {
			const [initialFrom, initialTo] = selection
			let [from, to] = selection

			// Ensures IPv6 parts are valid hexadecimal and at most 4 characters
			const boundIpv6Parts = (ip: string) => {
				const partsOfIp = ip.split(':')
				return partsOfIp
					.map((part) => {
						const validHex = part.replace(/[^0-9A-Fa-f]/g, '')
						return validHex.slice(0, 4)
					})
					.join(':')
			}

			// Ensures port stays at or below 65535
			const boundPort = (ip: string) => {
				const [address, port = ''] = ip.split(']:')
				const cleanPort = port.replace(/\D/g, '')
				const portNum = parseInt(cleanPort)
				const boundedPort = portNum > 65535 ? '65535' : cleanPort
				return `${address}]:${boundedPort}`
			}

			const processedIntegerPart = Array.from(value).reduce(
				(formattedValuePart, char, i) => {
					if (formattedValuePart.includes(']:')) {
						return boundPort(formattedValuePart + char)
					}

					const partsOfIp = formattedValuePart.split(':')
					const isPositionForSeparator =
						char !== ':' &&
						formattedValuePart.length &&
						partsOfIp[partsOfIp.length - 1].length === 4

					if (partsOfIp.length > 8) {
						return formattedValuePart
					}

					if (!isPositionForSeparator) {
						return boundIpv6Parts(formattedValuePart + char)
					}

					if (i <= initialFrom) from++
					if (i <= initialTo) to++

					if (partsOfIp.length === 8) {
						if (char === ']') return formattedValuePart + char
						return `${formattedValuePart}]:`
					} else {
						return formattedValuePart + ':' + char
					}
				},
				'',
			)

			return {
				value: processedIntegerPart,
				selection: [from, to],
			}
		},
	],
	plugins: [
		...ipv6Plugins,
		maskitoEventHandler('focus', (element) => {
			const initialValue = element.value || ''

			maskitoUpdateElement(
				element,
				initialValue + IPV6_MASK_PLACEHOLDER.slice(initialValue.length),
			)
		}),
	],
}

export const regexMask = (regex: RegExp): MaskitoOptions => {
	return {
		mask: regex,
	}
}
