import { useEffect } from 'react'

import { getRouteApi } from '@tanstack/react-router'

import { clearQueryCache } from '@/lib/react-query'

import { useUpdateSearch } from '@/hooks/useUpdateSearch'

const Route = getRouteApi('/_dashboard')

/**
 * Hook to clear the query cache when the forceCache search param is true
 */
export const useClearCache = () => {
	const forceCache = Route.useSearch({
		select: (search) => search.forceCache,
	})

	const { update } = useUpdateSearch()

	useEffect(() => {
		if (forceCache) {
			clearQueryCache()

			// Remove the forceCache search param after clearing the cache
			update({ forceCache: undefined })
		}

		return () => {
			update({ forceCache: undefined })
		}
	}, [forceCache, update])
}
