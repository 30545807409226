import { PRIORITIES } from './types'

import type { AlertPriority, Period } from './types'

/**
 * Get the priority label
 * @param priority - The priority
 * @returns {string} The priority label
 */
export const getPriorityLabel = (priority: AlertPriority) => {
	switch (priority) {
		case PRIORITIES.HIGH:
			return 'High'
		case PRIORITIES.MEDIUM:
			return 'Medium'
		case PRIORITIES.LOW:
			return 'Low'
	}
}

/**
 * Get the period label
 * @param period - The period
 * @returns {string} The period label
 */
export const getPeriodLabel = (period: Period) => {
	switch (period) {
		case 'LAST_7_DAYS':
			return 'Last 7 days'
		case 'LAST_30_DAYS':
			return 'Last 30 days'
		case 'LAST_60_DAYS':
			return 'Last 60 days'
		case 'LAST_90_DAYS':
			return 'Last 90 days'
		case 'LAST_180_DAYS':
			return 'Last 180 days'
		case 'CUSTOM':
			return 'Custom'
	}
}
