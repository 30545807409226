/**
 * Example from shadcn/ui
 * @see https://ui.shadcn.com/docs/components/date-picker
 */

import { useState } from 'react'

import { cn } from '@/utils'
import { format, subDays } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover'

import type { HTMLAttributes } from 'react'
import type { DateRange } from 'react-day-picker'

type DateRangePiciker = HTMLAttributes<HTMLDivElement> & {
	startDate?: string
	endDate?: string
	onUpdate?: (date: DateRange | undefined) => void
	className?: string
}
export function DatePickerWithRange({
	className,
	startDate,
	endDate,
	onUpdate,
}: DateRangePiciker) {
	const [date, setDate] = useState<DateRange | undefined>({
		from: startDate ? new Date(startDate) : subDays(new Date(), 7),
		to: endDate ? new Date(endDate) : new Date(),
	})

	const handleUpdate = (date: DateRange | undefined) => {
		setDate(date)
		onUpdate?.(date)
	}

	return (
		<div className={cn('grid gap-2', className)}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={'outline'}
						className={cn(
							'w-full justify-start gap-2 text-left font-normal',
							!date && 'text-muted-foreground',
							className,
						)}
					>
						<CalendarIcon size={16} />
						{date?.from ? (
							date.to ? (
								<>
									{format(date.from, 'LLL dd, y')} -{' '}
									{format(date.to, 'LLL dd, y')}
								</>
							) : (
								format(date.from, 'LLL dd, y')
							)
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						mode="range"
						defaultMonth={date?.from}
						selected={date}
						onSelect={handleUpdate}
						numberOfMonths={2}
					/>
				</PopoverContent>
			</Popover>
		</div>
	)
}

export default DatePickerWithRange
