import { useFormContext } from 'react-hook-form'

import type { InputProperty } from './types'

type FormMessageProps = {
	item: InputProperty
	id: string
}

export const FormMessage = ({ item, id }: FormMessageProps) => {
	const { formState } = useFormContext()

	return (
		<div className="f py-1">
			{/* Error message */}
			{formState.errors[id] && (
				<p className="text-sm text-destructive">
					{formState.errors[id].message as string}
				</p>
			)}

			{/* Description */}
			<p className="text-sm text-muted-foreground">{item?.description}</p>
		</div>
	)
}
