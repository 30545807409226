import { useState } from 'react'

import { cn } from '@/utils'
import { CircleCheck, CircleX, Eye, EyeOff } from 'lucide-react'

import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '../ui/form'
import { Input } from '../ui/input'
import {
	Tooltip,
	TooltipArrow,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '../ui/tooltip'

import type { ChangeEvent } from 'react'
import type { UseFormReturn } from 'react-hook-form'

type PasswordInputProps = {
	form: UseFormReturn<any>
	name: string
	label: string
	placeholder?: string
	isConfirm?: boolean
	required?: boolean
	popoverDirection?: 'left' | 'right'
	minLength?: number
}

/**
 * Generic Password Input component
 *
 * It includes a label, input field, and a tooltip for showing password requirements
 *
 * @param {UseFormReturn<any>} form - The form object
 * @param {string} name - The name of the input field
 * @param {string} label - The label of the input field
 * @param {string} placeholder - The placeholder text for the input field
 * @param {boolean} isConfirm - Whether the input field is for confirming the password
 *
 * @example
 * <PasswordInput form={form} name="password" label="New Password" />
 * <PasswordInput form={form} name="confirmPassword" label="Confirm Password" isConfirm />
 */

// TODO: too many props, this could be refactor in a composition manner
// TODO: eg PasswordInput/PasswordRequirement
export const PasswordInput = ({
	form,
	name,
	label,
	placeholder,
	isConfirm,
	required = true,
	popoverDirection = 'right',
	minLength = 16,
}: PasswordInputProps) => {
	// Toggles the visibility of the password input field (text/password)
	const [showPassword, setShowPassword] = useState(false)

	// Toggles the visibility of the password requirements tooltip
	const [showRequirements, setShowRequirements] = useState(false)

	// Keeps track of the password match state
	const [passwordMatch, setPasswordMatch] = useState(false)

	// Keeps track of the password requirements
	const [passwordRequirements, setPasswordRequirements] = useState({
		hasMinLength: false,
		hasNumber: false,
		hasLowerCase: false,
		hasUpperCase: false,
		hasSpecial: false,
	})

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev)
	}

	// If the field is a password input, use to check if password meet the requirements
	const checkPasswordRequirements = (event: ChangeEvent<HTMLInputElement>) => {
		const password = event.target.value

		const hasMinLength = password.length >= minLength
		const hasNumber = /\d/.test(password)
		const hasLowerCase = /[a-z]/.test(password)
		const hasUpperCase = /[A-Z]/.test(password)
		const hasSpecial = /[\^$*.[\]{}()?!"@#%&/\\,><':;|_~`=+-]/.test(password)

		setPasswordRequirements({
			hasMinLength,
			hasNumber,
			hasLowerCase,
			hasUpperCase,
			hasSpecial,
		})
	}

	// If the field is a password confirm, use to check if passwords match
	const checkPasswordMatch = (event: ChangeEvent<HTMLInputElement>) => {
		const confirmation = event.target.value
		const isValid = form.getValues()['password'] === confirmation

		setPasswordMatch(isValid)
	}

	// Use to check if password meet the requirements
	const validatePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
		if (isConfirm) {
			checkPasswordMatch(event)
		} else {
			checkPasswordRequirements(event)
		}
	}

	const handleBlur = (onBlur: () => void) => {
		setShowRequirements(false)
		onBlur()
	}

	return (
		<FormField
			control={form.control}
			name={name}
			render={({ field }) => (
				<FormItem className="mb-6 w-full">
					<FormLabel className="mb-2 text-text-primary">{label}</FormLabel>
					<FormControl>
						<div className="relative w-full">
							{/*  Input */}
							<Input
								type={showPassword ? 'text' : 'password'}
								{...field}
								onInput={validatePasswordInput}
								onFocus={() => setShowRequirements(true)}
								onBlur={() => handleBlur(field.onBlur)}
								placeholder={placeholder ?? 'New Password'}
								className="w-full rounded-md border border-text-secondary border-opacity-50 bg-transparent p-4 text-sm text-text-primary text-opacity-65 placeholder-text-secondary"
								min={16}
								role="textbox"
								required={required}
							/>

							{/* Show/Hide password */}
							<TooltipProvider delayDuration={0}>
								<Tooltip>
									<TooltipTrigger
										type="button"
										data-testid="password-toggle-icon"
										onClick={toggleShowPassword}
										className="absolute right-4 top-2 text-text-secondary"
									>
										{showPassword ? (
											<Eye className="h-6 w-6" />
										) : (
											<EyeOff className="h-6 w-6" />
										)}
									</TooltipTrigger>
									<TooltipContent sticky="always" side="right">
										{showPassword ? 'Hide password' : 'Show password'}
										<TooltipArrow />
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>

							{/* Requirements List */}
							{showRequirements && (
								<>
									{isConfirm ? (
										<PasswordConfirmRequirements match={passwordMatch} />
									) : (
										<PasswordRequirements
											requirements={passwordRequirements}
											direction={popoverDirection}
											minLength={minLength}
										/>
									)}
								</>
							)}
						</div>
					</FormControl>
					<FormMessage />
				</FormItem>
			)}
		/>
	)
}

type PasswordRequirementsProps = {
	requirements: {
		hasMinLength: boolean
		hasNumber: boolean
		hasLowerCase: boolean
		hasUpperCase: boolean
		hasSpecial: boolean
	}
	direction?: 'left' | 'right'
	minLength?: number
}

/**
 * Tooltip for showing password requirements (min length, number, special character, etc)
 * @param {PasswordRequirementsProps} requirements - The password requirements object
 * @returns
 */
const PasswordRequirements = ({
	requirements,
	direction = 'right',
	minLength = 16,
}: PasswordRequirementsProps) => {
	return (
		<div
			className={cn(
				'absolute -left-2 bottom-16 z-10 flex min-w-72 flex-col items-start justify-start gap-2 rounded-md bg-foreground p-4 text-text-primary shadow-lg sm:bottom-auto',
				{
					'sm:left-[110%] sm:top-0': direction === 'right',
					'sm:-left-[70%] sm:top-0': direction === 'left',
				},
			)}
		>
			<p className="text-base font-bold uppercase">Must contain:</p>
			<p
				className={cn(
					'flex flex-row items-center justify-start gap-2 text-sm',
					{
						'text-green-500': requirements.hasMinLength,
					},
				)}
			>
				{requirements.hasMinLength ? (
					<CircleCheck className="h-4 w-4" />
				) : (
					<CircleX className="h-4 w-4" />
				)}
				{minLength} characters
			</p>
			<p
				className={cn(
					'flex flex-row items-center justify-start gap-2 text-sm',
					{
						'text-green-500': requirements.hasNumber,
					},
				)}
			>
				{requirements.hasNumber ? (
					<CircleCheck className="h-4 w-4" />
				) : (
					<CircleX className="h-4 w-4" />
				)}
				A number [0-9]
			</p>
			<p
				className={cn(
					'flex flex-row items-center justify-start gap-2 text-sm',
					{
						'text-green-500': requirements.hasLowerCase,
					},
				)}
			>
				{requirements.hasLowerCase ? (
					<CircleCheck className="h-4 w-4" />
				) : (
					<CircleX className="h-4 w-4" />
				)}
				A lowercase letter [a-z]
			</p>
			<p
				className={cn(
					'flex flex-row items-center justify-start gap-2 text-sm',
					{
						'text-green-500': requirements.hasUpperCase,
					},
				)}
			>
				{requirements.hasUpperCase ? (
					<CircleCheck className="h-4 w-4" />
				) : (
					<CircleX className="h-4 w-4" />
				)}
				A uppercase letter [A-Z]
			</p>
			<p
				className={cn(
					'flex flex-row items-center justify-start gap-2 text-sm',
					{
						'text-green-500': requirements.hasSpecial,
					},
				)}
			>
				{requirements.hasSpecial ? (
					<CircleCheck className="h-4 w-4" />
				) : (
					<CircleX className="h-4 w-4" />
				)}
				A special character [!@#$%^&*]
			</p>
		</div>
	)
}

type PasswordConfirmRequirementsProps = {
	match: boolean
}

/**
 * Tooltip for confirming password requirements (match with password input field)
 * @param {PasswordConfirmRequirementsProps} match - The password match state
 * @returns
 */
const PasswordConfirmRequirements = ({
	match,
}: PasswordConfirmRequirementsProps) => {
	return (
		<div className="bg-foreground-dark absolute -left-2 bottom-16 z-10 flex min-w-72 flex-col items-start justify-start gap-2 rounded-md p-4 text-text-primary shadow-lg sm:bottom-auto sm:left-[110%] sm:top-0">
			<p className="text-base font-bold uppercase">Must contain:</p>
			<p
				className={cn(
					'flex flex-row items-center justify-start gap-2 text-sm',
					{
						'text-green-500': match,
					},
				)}
			>
				{match ? (
					<CircleCheck className="h-4 w-4" />
				) : (
					<CircleX className="h-4 w-4" />
				)}
				Match password
			</p>
		</div>
	)
}
