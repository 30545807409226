/**
 * Type assertion function
 * @param condition - The condition to assert
 * @param message - The error message to throw if the condition is false
 * @throws {Error} If the condition is false
 *
 * @example
 * ```ts
 * assert(isString(value), 'Value must be a string')
 * ```
 */
export function assert(condition: any, message?: string): asserts condition {
	if (!condition) {
		throw new Error(message || 'Assertion failed')
	}
}

/**
 * Type guard to check if a value is not null or undefined
 * @param value - The value to check
 * @returns True if the value is not null or undefined
 */
export function isDefined<T>(value: T | null | undefined): value is T {
	return value !== null && value !== undefined
}

/**
 * Type guard to check if a value is a string
 * @param value - The value to check
 * @returns True if the value is a string
 */
export function isString(value: unknown): value is string {
	return typeof value === 'string'
}

/**
 * Type guard to check if a value is a number
 * @param value - The value to check
 * @returns True if the value is a number
 */
export function isNumber(value: unknown): value is number {
	return typeof value === 'number' && !isNaN(value)
}
