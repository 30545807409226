import type { XiorError } from 'xior'

/**
 * Retry query if the error is not a 4XX or 5XX
 * @param failureCount - The number of times the query has been retried
 * @param error - The error object
 * @param maxRetries - Maximum number of retries (default: 3)
 * @returns - Whether to retry the query
 */
export function defaultQueryRetry(
	failureCount: number,
	error: Error,
	maxRetries: number = 3,
) {
	const err = error as XiorError

	// Only retry if the error is not a 4XX or 5XX
	if (err.response?.status && err.response?.status > 400) {
		return false
	}

	// Only retry maxRetries times
	if (failureCount > maxRetries) {
		return false
	}

	return true
}
