import { lazy, Suspense } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import ActivityLastUpdated from '@/features/activity/components/last-updated'
import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { QueryBoundary } from '@/components/common/query-boundary'
import { ErrorBoundaryComponent } from '@/components/environment/error-boundary-component'
import { Section, SectionTitle } from '@/components/environment/section'
import TableSkeleton from '@/components/skeletons/table-skeleton'
import { Skeleton } from '@/components/ui/skeleton'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const ActivitySearch = lazy(
	() => import('@/features/activity/components/search'),
)
const ActivitiesTable = lazy(
	() => import('@/features/activity/components/table'),
)
const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const Route = createFileRoute('/_dashboard/activity/')({
	component: ActionsActivityRoute,
	pendingComponent: ActionsActivityRouteSkeleton,
	errorComponent: ActionsActivityError,
})

/**
 * Actions Activity Route
 */
function ActionsActivityRoute() {
	return (
		<>
			<Suspense fallback={<Skeleton className="my-2 h-12 w-full" />}>
				<ActivityLastUpdated />
				<ActivitySearch />
			</Suspense>

			<QueryBoundary
				fallback={({ resetError }) => (
					<ErrorBoundaryComponent resetError={resetError} />
				)}
			>
				<Suspense fallback={<TableSkeleton />}>
					<ActivitiesTable />
				</Suspense>
			</QueryBoundary>
		</>
	)
}

/**
 * Loading state for Actions Activity
 */
function ActionsActivityRouteSkeleton() {
	return (
		<Section>
			<title>Activity | BitLyft Air®</title>
			<SectionTitle>Activity</SectionTitle>
			{/*  Search Bar Skeleton */}
			<Skeleton className="my-2 h-12 w-full" />

			{/*  Table Skeleton */}
			<TableSkeleton rows={24} />
			<TableSkeleton />
		</Section>
	)
}

/**
 * Error component for Actions Activity
 */
function ActionsActivityError({ error }: ErrorComponentProps) {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	// Show default unauthorized content if the error is 401
	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return <ErrorBoundaryComponent resetError={reset} />
}
