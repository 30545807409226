import { QueryErrorResetBoundary } from '@tanstack/react-query'

import { ErrorBoundary } from '@sentry/react'

import type { FallbackRender } from '@sentry/react'
import type { PropsWithChildren } from 'react'

export type QueryBoundaryProps = PropsWithChildren & {
	fallback: FallbackRender
}

export const QueryBoundary = ({ children, fallback }: QueryBoundaryProps) => {
	return (
		<QueryErrorResetBoundary>
			{({ reset }) => (
				<ErrorBoundary onReset={reset} fallback={fallback}>
					{children}
				</ErrorBoundary>
			)}
		</QueryErrorResetBoundary>
	)
}

export default QueryBoundary
