import { z } from 'zod'

export const schema = z
	.object({
		code: z.string().min(6, 'Code must be 6 digits').max(6),
		password: z.string().min(8, 'Password must be at least 8 characters'),
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: 'Passwords do not match',
		path: ['confirmPassword'],
	})

export type NewPasswordFormValues = z.infer<typeof schema>
