import { api } from '@/lib/api'

import type { ConfirmationHeaders } from '../confirmation-dialog/types'
import type { UserRequest } from '../user/types/api'
import type {
	BillingInformationResponse,
	ChangeUserRoleFormValues,
	CreateContactData,
	CreateUserData,
	TenantContact,
	TenantContract,
	TenantUser,
	TenantUsersRequest,
	UpdateUserFormValues,
} from './types'

/**
 * Fetch all tenant users
 * @param tenant - The tenant name
 * @param show_all - Whether to show all users across all tenants
 * @returns The tenant users
 */
export const fetchTenantUsers = async (
	tenant: string,
	show_all: boolean = false,
): Promise<TenantUser[]> => {
	if (!tenant) return []

	const {
		data: { users },
	} = await api.get<TenantUsersRequest>(
		`/api/tenants/${tenant}/users?limit=1000&show_all=${show_all}`,
	)

	return users
}

/**
 * Fetch all tenant contacts
 * @param tenant - The tenant name
 * @returns The tenant contacts
 */
export const fetchTenantContacts = async (tenant: string) => {
	const { data } = await api.get<TenantContact[]>(
		`/api/tenants/${tenant}/contacts`,
	)

	return data
}

/**
 * Fetch the tenant contract
 * @param tenant - The tenant name
 * @returns The tenant contract
 */
export const fetchContract = async (
	tenant: string,
): Promise<TenantContract> => {
	const { data } = await api.get<TenantContract>(
		`/api/tenants/${tenant}/contract`,
	)

	return data
}

/**
 * Fetch tenant contract files
 * @param tenant - The tenant name
 * @param type - The type of contract file to fetch
 * @returns The contract file URL
 */
export const fetchContractFiles = async (
	tenant: string,
	type: 'msa' | 'sow',
): Promise<{ url: string }> => {
	if (!tenant) return { url: '' }

	const { data } = await api.get<{ url: string }>(
		`/api/tenants/${tenant}/contract/download/${type}`,
	)

	return data
}

const EMPTY_BILLING: BillingInformationResponse = {
	address: '',
	city: '',
	contact_email: '',
	contact_name: '',
	contact_phone: '',
	state: '',
	tenant: '',
	zip_code: '',
}

/**
 * Fetch a tenant billing information
 * @param tenant - The tenant name
 * @returns The tenant billing information
 */
export const fetchTenantBillingInformation = async (
	tenant: string,
): Promise<BillingInformationResponse> => {
	if (!tenant) return EMPTY_BILLING

	const { data } = await api.get<BillingInformationResponse>(
		`/api/tenants/${tenant}/contract/billing`,
	)

	return data
}

/**
 * Create a new Tenant Contact
 * @param tenant - The tenant name
 * @param contact_data - The contact data
 * @returns The created contact
 */
export const createTenantContact = async (
	tenant: string,
	contact_data: Partial<CreateContactData>,
) => {
	const { data } = await api.post<CreateContactData>(
		`/api/tenants/${tenant}/contacts`,
		contact_data,
	)

	return data
}

/**
 * Delete a Tenant Contact
 * @param tenant - The tenant name
 * @param name - The contact name
 * @returns The deleted contact
 */
export const deleteTenantContact = async (tenant: string, name: string) => {
	const { data } = await api.delete(`/api/tenants/${tenant}/contacts/${name}`)

	return data
}

/**
 * Update a Tenant Contact
 * @param tenant - The tenant name
 * @param contact_name - The contact name
 * @param contact_data - The contact data
 * @returns The updated contact
 */
export const updateTenantContact = async (
	tenant: string,
	contact_name: string,
	contact_data: Partial<CreateContactData>,
) => {
	const { data } = await api.put<CreateContactData>(
		`/api/tenants/${tenant}/contacts/${contact_name}`,
		contact_data,
	)

	return data
}

/**
 * Create a new Tenant User
 * @param tenant - The tenant name
 * @param user_data - The user data
 * @returns The created user
 */
export const createTenantUser = async (
	tenant: string,
	user_data: Partial<CreateUserData>,
) => {
	const { data } = await api.put<CreateUserData>(
		`/api/tenants/${tenant}/users`,
		user_data,
	)

	return data
}

/**
 * Update a Tenant User
 * @param tenant - The tenant name
 * @param user_data - The user data
 * @returns The updated user
 */
export const updateTenantUser = async (
	tenant: string,
	user_data: UpdateUserFormValues,
) => {
	const { data } = await api.patch<UserRequest>(
		`/api/tenants/${tenant}/users`,
		user_data,
	)

	return data
}

/**
 * Update user role, optionally with confirmation headers
 * @param tenant - The tenant name
 * @param user_data - The user data
 * @param headers - The confirmation headers
 * @returns The updated user
 */
export const changeUserRole = async (
	tenant: string,
	{
		user_data,
		headers,
	}: {
		user_data: ChangeUserRoleFormValues
		headers?: ConfirmationHeaders
	},
) => {
	const { data } = await api.put(
		`/api/tenants/${tenant}/users/user_role`,
		user_data,
		{
			headers: headers
				? {
						'X-AIR-confirm': headers['X-AIR-confirm'],
						...(headers['X-AIR-confirm-passphrase'] && {
							'X-AIR-confirm-passphrase': headers['X-AIR-confirm-passphrase'],
						}),
					}
				: undefined,
		},
	)

	return data
}

/**
 * Delete a Tenant User
 * @param tenant - The tenant name
 * @param username - The username
 * @returns The deleted user
 */
export const deleteTenantUser = async (tenant: string, username: string) => {
	const { data } = await api.delete<string>(
		`/api/tenants/${tenant}/users?username=${encodeURIComponent(username)}`,
	)

	return data
}

/**
 * Reset a Tenant User password
 * @param tenant - The tenant name
 * @param username - The username
 * @returns The updated user
 */
export const resetTenantUserPassword = async (
	tenant: string,
	username: string | undefined,
) => {
	if (!username) return

	const { data } = await api.post<string>(
		`/api/tenants/${tenant}/users/admin_password_reset?username=${encodeURIComponent(username)}`,
	)

	return data
}
