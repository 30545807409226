import { redirect } from '@tanstack/react-router'

import { parseRedirect } from '@/utils'

import { getCurrentRole } from '../user/store'

import { isUserAuthenticated } from './queries'
import { ROLE_HIERARCHY } from './types'

import type { Roles } from './types'
import type { AuthSearch } from '@/routes/_auth'
import type { QueryClient } from '@tanstack/react-query'
import type { ParsedLocation } from '@tanstack/react-router'

/**
 * Check if the user has the required role
 * @param userRole - The user's role
 * @param requiredRole - The required role
 * @returns True if the user has the required role, false otherwise
 */
export const hasRequiredRole = (userRole: Roles, requiredRole: Roles) => {
	return ROLE_HIERARCHY[userRole] >= ROLE_HIERARCHY[requiredRole]
}

/**
 * Redirect to unauthorized if the user does not have the required role
 * @param requiredRole - The required role
 */
export const roleAuthGuard = (requiredRole: Roles) => {
	const role = getCurrentRole() as Roles

	if (role && !hasRequiredRole(role, requiredRole)) {
		return redirect({ to: '/unauthorized' })
	}
}

/**
 * Guard to check if the user is authenticated
 * @param queryClient - The query client
 * @param location - The location
 * @param search - The search
 */
export const isAuthenticatedGuard = async (
	queryClient: QueryClient,
	location: ParsedLocation,
	search: AuthSearch,
) => {
	// If the user is logging out, don't check if they're authenticated
	if (location.pathname === '/logout') return

	try {
		// If user is not authenticated, ignore redirect
		const isAuthenticated = await isUserAuthenticated(queryClient)
		if (!isAuthenticated) return

		// Grab the redirect URL from the search
		const { redirect: redirectURL } = search

		// If there's a redirect URL, redirect the user
		if (redirectURL) {
			const { pathname, search: parsedSearchObject } =
				parseRedirect(redirectURL)

			// Redirect the user to the page they were trying to access
			throw redirect({
				to: pathname,
				search: { ...parsedSearchObject },
			})
		} else {
			// If there's no redirect URL, redirect the user to the home page
			throw redirect({
				to: '/graylog',
			})
		}
	} catch (error) {
		if (error instanceof Error && error.name !== 'NotAuthenticatedException') {
			throw redirect({
				to: '/login',
				search: {
					redirect: location.pathname,
				},
			})
		}
	}
}
