import { useMutation, useQueryClient } from '@tanstack/react-query'

import {
	confirmResetPassword,
	confirmSignIn,
	resetPassword,
	signIn,
} from 'aws-amplify/auth'

import { authKeys } from './queries'

export const authMutations = {
	all: ['auth'] as const,
	login: () => [...authMutations.all, 'login'] as const,
	forgotPassword: () => [...authMutations.all, 'forgotPassword'] as const,
	resetPassword: () => [...authMutations.all, 'resetPassword'] as const,
	otp: () => [...authMutations.all, 'otp'] as const,
	setup: () => [...authMutations.all, 'setup'] as const,
}

/**
 * Custom hook to login a user using AWS Cognito
 */
export const useAuthLogIn = () => {
	return useMutation({
		mutationKey: authMutations.login(),
		mutationFn: async (data: { email: string; password: string }) => {
			const response = await signIn({
				username: data.email,
				password: data.password,
			})

			return response
		},
	})
}

/**
 * Custom hook to reset a user's password
 */
export const useAuthForgotPassword = () => {
	return useMutation({
		mutationKey: authMutations.forgotPassword(),
		mutationFn: async (data: { email: string }) => {
			const response = await resetPassword({
				username: data.email,
			})

			return response
		},
	})
}

/**
 * Custom hook to reset a user's password
 */
export const useAuthResetPassword = () => {
	return useMutation({
		mutationFn: async ({
			confirmationCode,
			newPassword,
			username,
		}: {
			confirmationCode: string
			newPassword: string
			username: string
		}) => {
			const response = await confirmResetPassword({
				confirmationCode,
				newPassword,
				username,
			})

			return response
		},
	})
}

/**
 * Custom hook to confirm a user's OTP
 */
export const useAuthOTP = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: async ({ code }: { code: string }) => {
			const response = await confirmSignIn({
				challengeResponse: code,
			})

			return response
		},
		onSettled: (data) => {
			queryClient.removeQueries({
				queryKey: authKeys.cognito(),
			})

			return data
		},
	})
}

/**
 * Mutation to setup both a new password and a TOTP secret
 * @param {SetupPasswordData} data - The data to be sent to the mutation (code || password)
 */
export const useAuthSetup = () => {
	return useMutation({
		mutationFn: async (data: { password: string }) => {
			const response = await confirmSignIn({
				challengeResponse: data.password,
			})

			return response
		},
	})
}
