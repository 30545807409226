import { createFileRoute, Link } from '@tanstack/react-router'

import { Divider, Subtitle, Title } from '@/features/auth/components/card'
import { AuthErrorComponent } from '@/features/auth/components/error-component'
import { SetupPasswordForm } from '@/features/auth/components/forms/setup-password'
import { AuthPendingComponent } from '@/features/auth/components/pending-component'

export const Route = createFileRoute('/_auth/setup-password')({
	component: SetupPasswordRoute,
	pendingComponent: AuthPendingComponent,
	errorComponent: AuthErrorComponent,
})

function SetupPasswordRoute() {
	return (
		<>
			<title>Setup Password | BitLyft Air®</title>
			<Title>Setup Password</Title>
			<Subtitle>Create a new password to access BitLyft AIR</Subtitle>

			<SetupPasswordForm />

			<Divider />

			<Link to="/forgot-password">Forgot your password?</Link>
		</>
	)
}
