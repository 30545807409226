import { Link } from '@tanstack/react-router'

import { cn } from '@/utils'

import type { LinkOptions } from '@tanstack/react-router'

type TabItemProps = {
	className?: string
	to: LinkOptions['to']
	children: React.ReactNode
	onClick?: () => void
}

export const TabItem = ({ className, to, children, onClick }: TabItemProps) => {
	return (
		<li>
			<Link
				className={cn(
					'inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[status=active]:bg-background data-[status=active]:text-text-primary data-[status=active]:shadow-sm',
					className,
				)}
				to={to}
				onClick={onClick}
			>
				{children}
			</Link>
		</li>
	)
}
