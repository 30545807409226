import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { getRouteApi, Link } from '@tanstack/react-router'

import { zodResolver } from '@hookform/resolvers/zod'

import { LoadingButton } from '@/components/buttons/loading-button'
import { Button } from '@/components/ui/button'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from '@/components/ui/input-otp'

import { schema } from './validation'

import type { ConfirmationCodeFormValues } from './validation'

const Route = getRouteApi('/_auth')

export const ConfirmCodeForm = () => {
	const email = Route.useSearch({
		select: (search) => search.email,
	})

	const navigate = Route.useNavigate()

	const [isLoading, setIsLoading] = useState(false)

	const form = useForm<ConfirmationCodeFormValues>({
		resolver: zodResolver(schema),
		defaultValues: {
			code: '',
		},
	})

	const onSubmit = form.handleSubmit(async (data) => {
		if (isLoading) return

		setIsLoading(true)

		const { code } = data
		try {
			navigate({
				to: '/reset-password',
				search: {
					email: email as string,
					code: code as string,
				},
			})
		} catch (error) {
			setIsLoading(false)
		}
	})

	return (
		<Form {...form}>
			<form
				onSubmit={onSubmit}
				className="my-4 flex w-full flex-col items-center justify-center gap-6"
			>
				<input
					type="email"
					name="email"
					value={email || ''}
					placeholder="Email address"
					className="w-full rounded-md border border-text-secondary bg-text-disabled/20 p-4 text-sm text-text-secondary text-opacity-65 placeholder-text-secondary"
					readOnly
					aria-readonly
					disabled
				/>

				<div className="divider bg-bl-placeholder-dark h-[1px] w-full" />

				<FormField
					control={form.control}
					name="code"
					render={({ field }) => (
						<FormItem className="flex w-full flex-col items-center justify-center">
							<FormLabel className="mb-2 text-text-primary">
								Confirmation Code
							</FormLabel>
							<FormControl>
								<InputOTP
									maxLength={6}
									className="w-full"
									{...field}
									autoFocus
									autoComplete="off"
									autoSave="off"
								>
									<InputOTPGroup>
										<InputOTPSlot index={0} />
										<InputOTPSlot index={1} />
										<InputOTPSlot index={2} />
									</InputOTPGroup>
									<InputOTPSeparator />
									<InputOTPGroup>
										<InputOTPSlot index={3} />
										<InputOTPSlot index={4} />
										<InputOTPSlot index={5} />
									</InputOTPGroup>
								</InputOTP>
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<div className="mt-8 w-full">
					<LoadingButton
						isLoading={isLoading}
						data-testid="form-button"
						type="submit"
						variant="outline"
						className="w-full bg-bl-primary text-white hover:bg-bl-primary hover:bg-opacity-65"
					>
						Continue
					</LoadingButton>
					<Button
						variant="ghost"
						type="button"
						className="mt-2 w-full rounded-md bg-slate-600 bg-opacity-100 px-6 py-3 text-white transition-all duration-200 hover:bg-opacity-60"
						asChild
					>
						<Link to="/login">Back</Link>
					</Button>
				</div>
			</form>
		</Form>
	)
}
