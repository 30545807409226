import { Link } from '@tanstack/react-router'

import { Button } from '@/components/ui/button'

import type { LinkProps } from '@tanstack/react-router'

type MenuLinkProps = LinkProps & {
	children: React.ReactNode
}

export const MenuLink = ({ children, to }: MenuLinkProps) => {
	return (
		<Button
			asChild
			variant="link"
			className="flex items-center justify-start text-start text-muted-foreground"
		>
			<Link
				to={to}
				activeOptions={{ exact: true, includeSearch: true }}
				activeProps={{
					className: 'text-primary font-semibold hover:no-underline',
				}}
			>
				{children}
			</Link>
		</Button>
	)
}
