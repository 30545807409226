import { useTransition } from 'react'

import { useNavigate } from '@tanstack/react-router'

type SearchType =
	| Record<
			string,
			// We pass every possible value of a search param in the application
			string | string[] | Record<string, string> | number | undefined | boolean
	  >
	| undefined

/**
 * Hook to update the search params in the URL
 */
export const useUpdateSearch = () => {
	const [isPending, startTransition] = useTransition()
	const navigate = useNavigate()

	function update(newSearch: SearchType) {
		startTransition(() => {
			navigate({
				to: '.',
				search: (old: SearchType) => {
					return {
						...old,
						...newSearch,
					}
				},
			})
		})
	}

	return { update, isPending }
}
