import { useNavigate } from '@tanstack/react-router'

import { useGraylogMappedAction } from '@/features/graylog/hooks'

import { Button } from '@/components/ui/button'

type MappedEventsProps = {
	action: string
	handleClose: () => void
}

export const MappedEvents = ({ action, handleClose }: MappedEventsProps) => {
	const navigate = useNavigate()

	const { eventsOnThisAction } = useGraylogMappedAction(action)

	const handleGoToEvent = (eventId: string) => {
		handleClose()

		navigate({
			to: '/graylog/automation-settings/edit/$id',
			params: {
				id: eventId,
			},
		})
	}

	return (
		<div className="flex w-full flex-col items-start justify-start gap-2">
			<p className="font-bold uppercase text-muted-foreground">Mapped Events</p>

			{eventsOnThisAction.length > 0 ? (
				<div className="flex flex-row items-start justify-start">
					{eventsOnThisAction?.map((event) => (
						<Button
							variant="outline"
							key={event?.id}
							onClick={() => handleGoToEvent(event?.id)}
						>
							<p>{event.name}</p>
						</Button>
					))}
				</div>
			) : (
				<p className="text-muted-foreground">
					No events found for this action.
				</p>
			)}
		</div>
	)
}

export default MappedEvents
