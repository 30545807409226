import { useMemo } from 'react'

import { getRouteApi } from '@tanstack/react-router'

import { useMe } from '@/features/user/queries'
import { useCurrentTenant } from '@/features/user/store'
import { getTenantNameById } from '@/features/user/utils'

import { useGetReport } from '../queries'

const today = new Date()
const lastThirtyDays = new Date(today.setDate(today.getDate() - 30))

const Route = getRouteApi('/_dashboard/report')

/**
 * Hook to get report data
 */
export const useReport = () => {
	const { start_time, end_time, tenant } = Route.useSearch()

	// Get Current Tenant
	const currentTenant = useCurrentTenant() as string
	// Report Tenant is the URL Tenant or the Current Tenant
	const reportTenant = tenant ? tenant : currentTenant

	// If no start_time, use last 30 days
	const reportStartTime = useMemo(() => {
		return start_time ? _formatDate(start_time) : _formatDate(lastThirtyDays)
	}, [start_time])

	// If no end_time, use today
	const reportEndTime = useMemo(() => {
		return end_time ? _formatDate(end_time) : _formatDate(today)
	}, [end_time])

	const monthStart = `${new Date(reportStartTime).toLocaleString('default', {
		month: 'short',
	})}, ${new Date(reportStartTime).toLocaleString('default', {
		year: 'numeric',
	})}`
	const monthEnd = `${new Date(reportEndTime).toLocaleString('default', {
		month: 'short',
	})}, ${new Date(reportEndTime).toLocaleString('default', {
		year: 'numeric',
	})}`

	// Fetch Report
	const { data } = useGetReport(reportTenant, reportStartTime, reportEndTime)

	const {
		data: { allowed_tenants },
	} = useMe()

	// Check if Report is Empty
	const isEmpty = !data?.action_count && !data?.playbook_count

	// Generated Date
	const generatedOn = new Date().toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	})

	const period = `${monthStart} - ${monthEnd}`

	// Get Report Tenant friendly name
	const tenantName = getTenantNameById(reportTenant, allowed_tenants)

	return {
		generatedOn,
		isEmpty,
		data,
		reportStartTime,
		reportEndTime,
		tenantName,
		period,
	}
}

function _formatDate(date: Date | string) {
	// date should be MM/DD/YYYY
	return new Date(date).toLocaleDateString('en-US', {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
	})
}
