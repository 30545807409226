import { api } from '@/lib/api'

import type { Activity, ActivityDTO } from './types'

/**
 * Fetch activity logs
 * @param {string} tenant - The tenant ID
 * @param {number} page - The page number
 * @param {string} status - The status of the activity
 * @param {string} type - The type of the activity
 */
export const fetchActivities = async (
	tenant: string,
	page: number = 1,
	status?: string,
	type?: 'adhoc' | 'workflow',
) => {
	if (!tenant) return []

	const baseURL = `/api/tenants/${tenant}/soar/actions_activity?page=${page}`

	const searchParams = new URLSearchParams()

	if (status) {
		searchParams.set('status', status)
	}
	if (type) {
		searchParams.set('type', type)
	}

	const { data } = await api.get<Activity[]>(
		`${baseURL}&${searchParams.toString()}`,
	)

	return data
}

const EMPTY_ACTIVITY: ActivityDTO = {
	summary: {
		status: 'pending',
		workflow_instance_id: '',
	},
	details: {
		action_output: undefined,
		error_info: undefined,
		action_name: '',
		context: {
			action_uuid: '',
			adhoc: false,
			attempt_count: 0,
			tenant_id: '',
			time_scheduled: '',
			workflow_action_id: '',
			workflow_instance_id: '',
		},
	},
}

/**
 * Fetch action activity details
 * @param {string} tenant - The tenant ID
 * @param {string} identifier - The identifier of the activity
 */
export const fetchActivityDetails = async (
	tenant: string,
	identifier: string | null,
): Promise<ActivityDTO> => {
	if (!tenant || !identifier) return EMPTY_ACTIVITY

	const { data } = await api.get<ActivityDTO>(
		`/api/tenants/${tenant}/soar/actions_activity/${identifier}`,
	)

	return data
}

/**
 * Check the status of the action
 * @param tenant - The tenant ID
 * @param action_uuid - The update URI of the module
 */
export async function getActionStatus(
	tenant: string,
	action_uuid: string | null,
): Promise<ActivityDTO> {
	return new Promise((resolve, reject) => {
		if (!action_uuid) return

		try {
			return api
				.get<ActivityDTO>(
					`/api/tenants/${tenant}/soar/actions_activity/${action_uuid}`,
				)
				.then(({ data }) => resolve(data))
		} catch (error) {
			reject(error)
		}
	})
}
