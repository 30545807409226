import { cn } from '@/utils'

import { HasRole } from '@/features/auth/components/has-role'
import { Roles } from '@/features/auth/types'

import { MenuLink } from './link'

type SettingsMenuProps = {
	open: boolean
}
export const SettingsMenu = ({ open }: SettingsMenuProps) => {
	return (
		<div
			className={cn(
				'flex h-0 w-52 shrink-0 grow-0 flex-col items-start justify-start gap-8 overflow-hidden transition-all duration-200 sm:sticky sm:top-10 sm:h-auto',
				{
					'h-auto': open,
				},
			)}
		>
			<div className="pb-4">
				<h4 className="mb-1 rounded-md px-2 py-1 text-sm font-semibold">
					Personal Settings
				</h4>

				<div className="items-ce flex flex-col justify-start text-sm">
					<MenuLink to="/settings/profile">Profile</MenuLink>
					<MenuLink to="/settings/notifications">Notifications</MenuLink>
				</div>
			</div>

			<HasRole role={Roles.enum.Admin}>
				<div className="pb-4">
					<h4 className="mb-1 rounded-md px-2 py-1 text-sm font-semibold">
						Tenant Settings
					</h4>
					<div className="items-ce flex flex-col justify-start text-sm">
						<MenuLink to="/settings/tenant-settings">Members</MenuLink>
					</div>
				</div>

				{/*<div className="pb-4">
					<h4 className="mb-1 rounded-md px-2 py-1 text-sm font-semibold">
						Account Settings
					</h4>
					<div className="items-ce flex flex-col justify-start text-sm">
						<MenuLink to="/settings/term-dates">Term Dates</MenuLink>
						<MenuLink to="/settings/contact-directory">
							Contact Directory
						</MenuLink>
					</div>
				</div>*/}
			</HasRole>
		</div>
	)
}
