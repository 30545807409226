/**
 * Helper function to delay execution
 * @param {number} ms - milliseconds to wait
 * @returns Promise that resolves after the specified delay
 */
export function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Helper function to convert time to milliseconds
 * @param amount - The amount of time
 * @param unit - The unit of time
 * @returns The time in milliseconds
 */
export function time(amount: number, unit: 's' | 'm' | 'h' | 'd') {
	if (unit === 's') return amount * 1000
	if (unit === 'm') return amount * 60 * 1000
	if (unit === 'h') return amount * 60 * 60 * 1000
	if (unit === 'd') return amount * 24 * 60 * 60 * 1000

	return 0
}
