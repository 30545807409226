import { arrayMove } from '@dnd-kit/helpers'
import { devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import type { TriggerAlertFilters } from '../types'
import type { Period, Widget } from './types'

// Make key a const so we can import it in other files
const GRAYLOG_WIDGET_STORE_KEY = '@bitlyft/graylog-alerts'

type WidgetState = {
	widgets: Widget[]
	filters?: TriggerAlertFilters
	period: Period
}

type WidgetActions = {
	setWidgets: (widgets: Widget[]) => void
	moveWidget: (sourceId: string, targetId: string) => void
	updateWidget: (id: string, data: Widget['data']) => void
	setFilters: (filters: TriggerAlertFilters) => void
	setPeriod: (period: Period) => void
	reset: () => void
}

// TODO: Pull from API instead of hardcoding
const initialState: WidgetState = {
	widgets: [
		{
			id: 'number-widget-0',
			type: 'number',
			data: {
				size: 1,
				period: 'LAST_7_DAYS',
				name: 'High Priority Alert',
				priority: 1,
			},
		},
		{
			id: 'table-widget',
			type: 'table',
			data: {
				size: 2,
				period: 'LAST_7_DAYS',
				name: 'Triggered Alerts',
			},
		},
		{
			id: 'number-widget-1',
			type: 'number',
			data: {
				size: 1,
				period: 'LAST_7_DAYS',
				name: 'Medium Priority Alert',
				priority: 2,
			},
		},
		{
			id: 'number-widget-2',
			type: 'number',
			data: {
				size: 1,
				period: 'LAST_7_DAYS',
				name: 'Low Priority Alert',
				priority: 3,
			},
		},
		{
			id: 'alert-history',
			type: 'graph',
			data: {
				size: 2,
				period: 'LAST_7_DAYS',
				name: 'Alert History',
			},
		},
	],
	period: 'LAST_7_DAYS',
}

type WidgetStore = WidgetState & {
	actions: WidgetActions
}

const widgetStore = createWithEqualityFn<WidgetStore>()(
	devtools(
		persist(
			(set, get) => ({
				// Set initial state
				...initialState,

				actions: {
					/**
					 * Set the list of widgets
					 * @param widgets - The list of widgets
					 */
					setWidgets: (widgets) =>
						set({ widgets }, false, 'graylog/alerts/setWidgets'),

					/**
					 * Change a widget position in the array
					 * @param sourceId - The ID of the widget to move
					 * @param targetId - The ID of the widget to move to
					 */
					moveWidget: (sourceId, targetId) =>
						set(
							(state) => {
								const { widgets } = state

								return {
									widgets: arrayMove(
										widgets,
										widgets.findIndex((widget) => widget.id === sourceId),
										widgets.findIndex((widget) => widget.id === targetId),
									),
								}
							},
							false,
							'graylog/alerts/moveWidget',
						),

					/**
					 * Update a widget's data
					 * @param id - The ID of the widget to update
					 * @param data - The new data for the widget
					 */
					updateWidget: (id, data) =>
						set(
							{
								widgets: get().widgets.map((widget) =>
									widget.id === id ? { ...widget, data } : widget,
								),
							},
							false,
							'graylog/alerts/updateWidget',
						),

					/**
					 * Get a widget's data
					 * @param id - The ID of the widget to get
					 * @returns The widget's data
					 */
					getWidgetData: (id: string) => {
						const widget = get().widgets.find((widget) => widget.id === id)

						return widget?.data
					},

					/**
					 * Set the filters
					 * @param filters - The filters to set
					 */
					setFilters: (filters) => set({ filters }),

					/**
					 * Set the period
					 * @param period - The period to set
					 */
					setPeriod: (period) => set({ period }),

					/**
					 * Reset state to initial state
					 */
					reset: () =>
						set(() => ({ ...initialState }), false, 'graylog/alerts/reset'),
				},
			}),

			/**
			 * We pass two more arguments to set so we can have a better dev experience
			 * and inspect with Redux DevTools
			 * @see: https://github.com/pmndrs/zustand?tab=readme-ov-file#redux-devtools
			 */

			// Name of the store in localStorage
			{
				name: GRAYLOG_WIDGET_STORE_KEY,
				// We don't want to persist the actions object
				partialize: ({ actions, ...state }) => state,
			},
		),

		// Name of the store in Redux DevTools
		{ name: GRAYLOG_WIDGET_STORE_KEY },
	),
	shallow,
)

/**
 * Export atomic selectors to avoid components subscribed to the whole store
 * @see https://tkdodo.eu/blog/working-with-zustand#prefer-atomic-selectors
 */

/**
 * Get the list of widgets
 * @returns {Widget[]} The list of widgets
 */
export const useWidgets = () => widgetStore((state) => state.widgets)

/**
 * Get the filters
 * @returns {TriggerAlertFilters | undefined} The filters
 */
export const useWidgetFilters = () => widgetStore((state) => state.filters)

/**
 * Get the period
 * @returns {Period} The period
 */
export const useWidgetPeriod = () => widgetStore((state) => state.period)

/**
 * Get the list of actions
 * @returns {WidgetActions}
 */
export const useWidgetActions = () => widgetStore((state) => state.actions)
