import { lazy, memo } from 'react'

import { inputHasNullValues } from '../utils'

import type { InputComponentProps } from './types'

const DateInput = lazy(() => import('./inputs/date-input'))
const EmailInput = lazy(() => import('./inputs/email-input'))
const NullableInput = lazy(() => import('./inputs/nullable-input'))
const NumberInput = lazy(() => import('./inputs/number-input'))
const PhoneInput = lazy(() => import('./inputs/phone-input'))
const SecretInput = lazy(() => import('./inputs/secret-input'))
const TabsInput = lazy(() => import('./inputs/tabs-input'))
const TextInput = lazy(() => import('./inputs/text-input'))
const TimeInput = lazy(() => import('./inputs/time-input'))
const URLInput = lazy(() => import('./inputs/url-input'))
const BooleanInput = lazy(() => import('./inputs/boolean-input'))
const ArrayInput = lazy(() => import('./inputs/array-input'))

/**
 * This component is used to render the input based on the item's properties.
 *
 * In order of priority:
 *
 * - If the input has `anyOf` property
 * 	- If one of the inputs has a null value, it renders a nullable input
 * 		- Nullable inputs are used to return null if the input not filled, instead of an empty string
 * 	- If not, it renders a tabs input
 * - If the input has `format` property
 * 	- It uses the format to determine the input type
 * 		- E.g. email, phone, url, etc.
 * - If the input has `type` property
 * 	- It uses the type to determine the input type
 * 		- E.g. string, number, integer, etc.
 * @returns the input component
 */
export const FormInput = memo(({ id, item }: InputComponentProps) => {
	// If the item has anyOf, it means it has multiple inputs
	if (item.anyOf) {
		// If one of the inputs has a null value, we remove it from the array
		if (inputHasNullValues(item)) {
			return <NullableInput id={id} item={item} />
		}

		// If not, it means it has multiple inputs
		return <TabsInput id={id} item={item} />
	}

	// If the item has a format, we need to use the format to determine the input type
	if (item.format) {
		switch (item.format) {
			case 'uri':
				return <URLInput id={id} item={item} />
			case 'email':
				return <EmailInput id={id} item={item} />
			case 'phone':
				return <PhoneInput id={id} item={item} />
			case 'date':
			case 'date-time':
				return <DateInput id={id} item={item} />
			case 'time':
				return <TimeInput id={id} item={item} />
			case 'duration':
				return <NumberInput id={id} item={item} />
			default:
				// We break here because we don't have any other formats, so we use the type
				break
		}
	}

	// If the item has a type, we need to use the type to determine the input type
	switch (item.type) {
		case 'string':
			if (item['ui-meta']?.secret) {
				return <SecretInput id={id} item={item} />
			}
			return <TextInput id={id} item={item} />
		case 'number':
		case 'integer':
			return <NumberInput id={id} item={item} />
		case 'boolean':
			return <BooleanInput id={id} item={item} />
		case 'array':
			return <ArrayInput id={id} item={item} />
		default:
			return null
	}
}, deepCompare)

function deepCompare(
	prevProps: InputComponentProps,
	nextProps: InputComponentProps,
) {
	return (
		prevProps.id === nextProps.id &&
		prevProps.item.title === nextProps.item.title
	)
}

export default FormInput
