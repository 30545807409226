import { cn } from '@/utils'

import { useNotifications } from '../queries'

type NotificationsCounterProps = {
	className?: string
}

export const NotificationsCounter = ({
	className,
}: NotificationsCounterProps) => {
	const {
		data: {
			hits: { total_results },
		},
	} = useNotifications({ is_read: false })

	// Limit the number of notifications to 9
	const limitedResults = total_results > 9 ? '9+' : total_results

	if (total_results > 0) {
		return (
			<div
				className={cn(
					'absolute z-20 flex size-5 items-center justify-center rounded-full bg-red-500 transition-all duration-200 ease-in-out',
					className,
				)}
			>
				<span className="text-xs">{limitedResults}</span>
			</div>
		)
	} else {
		return null
	}
}

export default NotificationsCounter
