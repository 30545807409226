import { usePanelActions } from '@/features/panel/store'

import NotificationPanel from '../components/panel'

export const useNotificationsPanel = () => {
	const { createPanel, open } = usePanelActions()

	const openNotificationsPanel = () => {
		createPanel({
			title: 'Notifications',
			content: <NotificationPanel />,
		})

		open()
	}

	return {
		open: openNotificationsPanel,
	}
}
