/**
 * All ReactQuery related functionality for Actions
 */

import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'

import { defaultQueryRetry, time } from '@/utils'
import { successStaleTime } from '@/utils/api/staleTime'

import { useCurrentTenant } from '../user/store'

import { fetchActionDetails, fetchActionList } from './api'

import type { Action } from './types'

type ActionFilters = Record<
	string,
	string | boolean | undefined | Record<string, string>
>

/**
 * Query keys for Actions
 * - list: Fetch all actions
 * - details: Fetch a single action
 * - run: Run an action
 * - runSynchronous: Run an action synchronously
 */
export const actionsKeys = {
	all: ['actions'] as const,
	lists: () => [...actionsKeys.all, 'list'] as const,
	list: (tenant: string, filters?: ActionFilters) =>
		[...actionsKeys.lists(), tenant, filters] as const,
	details: () => [...actionsKeys.all, 'details'] as const,
	detail: (tenant: string, id: string) =>
		[...actionsKeys.details(), tenant, id] as const,
	run: (id: string) => [...actionsKeys.all, 'run', id] as const,
	runSynchronous: (id: string) =>
		[...actionsKeys.all, 'run-synchronous', id] as const,
}

/**
 * Query Options for Actions
 *
 * Used for fetching data on both a custom hook and a query client (preload)
 */
export const actionQueries = {
	/**
	 * Query options for fetching all actions
	 * @param tenant - The tenant ID
	 * @param filters - The filters to apply
	 * @param select - The selector to apply
	 */
	list: (
		tenant: string,
		filters?: ActionFilters,
		select?: (data: Action[]) => Action[],
	) =>
		queryOptions({
			queryKey: actionsKeys.list(tenant, filters),
			queryFn: () => fetchActionList(tenant),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 1, 'h'),
			select: select ?? ((data) => data),
		}),

	/**
	 * Query options for fetching the details of an action
	 * @param tenant - The tenant ID
	 * @param id - The action ID
	 */
	detail: (tenant: string, id: string) =>
		queryOptions({
			queryKey: actionsKeys.detail(tenant, id),
			queryFn: () => fetchActionDetails(tenant, id),
			retry: defaultQueryRetry,
			gcTime: time(1, 'h'),
			staleTime: ({ state }) => successStaleTime(state, 1, 'h'),
		}),
}

/**
 * Custom hook to fetch all actions using ReactQuery
 * @param filters - The filters to apply
 * @param select - The selector to apply
 * @returns The actions
 */
export const useActionList = (
	filters?: ActionFilters,
	select?: (data: Action[]) => Action[],
) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery({
		...actionQueries.list(tenant, filters, select),
	})
}

/**
 * Custom hook to fetch the details of an action using ReactQuery
 * @param id - The action ID
 * @returns The action details
 */
export const useActionDetails = (id: string) => {
	const tenant = useCurrentTenant()

	return useSuspenseQuery({
		...actionQueries.detail(tenant, id),
	})
}
