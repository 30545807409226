import { lazy, Suspense } from 'react'

import { Link } from '@tanstack/react-router'

import { ErrorBoundary } from '@sentry/react'
import { Plus, TriangleAlert } from 'lucide-react'

import { HasRole } from '@/features/auth/components/has-role'
import { Roles } from '@/features/auth/types'

import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'

import { useIntegrationList } from '../queries'

import { IntegrationStatusCard } from './status-card'

import type { ErrorComponentProps } from '@tanstack/react-router'
import type { XiorError } from 'xior'

const UnauthorizedContent = lazy(
	() => import('@/components/environment/unauthorized-content'),
)

export const IntegrationsList = () => {
	const { data } = useIntegrationList()

	return (
		<div className="grid grid-cols-1 gap-4 sm:grid-cols-3 xl:grid-cols-4">
			{data?.map((integration) => (
				<ErrorBoundary
					key={integration.id}
					fallback={({ error, resetError }) => (
						<CardError error={error as XiorError} reset={resetError} />
					)}
				>
					<Suspense fallback={<Skeleton className="h-[200px] w-full" />}>
						<IntegrationStatusCard integration={integration} />
					</Suspense>
				</ErrorBoundary>
			))}

			<HasRole
				role={Roles.enum.SystemManager}
				fallback={<InsuficienteRoleFallback />}
			>
				<Link
					to="/integrations/new"
					className="flex h-auto min-h-[200px] w-full flex-col items-start justify-center gap-4 rounded-md bg-foreground/50 p-8"
				>
					<h3 className="flex items-center justify-center text-2xl font-bold leading-none">
						<Plus className="mr-2 size-6" /> New Integration
					</h3>
					<h4 className="text-sm leading-none text-muted-foreground">
						Setup a new integration
					</h4>
				</Link>
			</HasRole>
		</div>
	)
}

function InsuficienteRoleFallback() {
	return (
		<div className="flex h-auto min-h-[200px] w-full flex-col items-start justify-center gap-4 rounded-md bg-foreground/50 p-8">
			<h3 className="flex items-center justify-center text-xl font-bold">
				Insuficient Permissions
			</h3>
			<h4 className="text-sm text-muted-foreground">
				Please contact your administrator to be able to create new integrations.
			</h4>
		</div>
	)
}

function CardError({ error, reset }: ErrorComponentProps) {
	const err = error as XiorError

	if (err.response?.status === 401) {
		return <UnauthorizedContent />
	}

	return (
		<div className="flex h-[200px] w-full flex-col items-center justify-center gap-4 rounded-md border border-foreground bg-background p-6">
			<TriangleAlert className="size-6 text-red-500" />
			<h3 className="flex items-center justify-center text-lg font-bold text-red-500">
				Error fetching integration
			</h3>
			<div className="flex flex-row items-center justify-center gap-2">
				<Button variant="outline" size="sm" onClick={reset}>
					Retry
				</Button>
			</div>
		</div>
	)
}
