import { api } from '@/lib/api'

import type { Report } from './types'

/**
 * Fetch all enabled alerts
 * @param {string} tenant - The tenant ID
 * @param {string} start_time - The start time
 * @param {string} end_time - The end time
 */
export const fetchReport = async (
	tenant: string,
	start_time?: string,
	end_time?: string,
) => {
	if (!tenant)
		return {
			action_count: 0,
			playbook_count: 0,
			action_results: [],
			playbook_results: [],
		}

	const baseURL = `/api/tenants/${tenant}/reports/soar`
	const params = new URLSearchParams()

	if (start_time) params.set('start_time', start_time)
	if (end_time) {
		params.set('end_time', end_time)
	} else {
		params.set('end_time', new Date().toISOString())
	}

	const requestURL = params.toString()
		? `${baseURL}?${params.toString()}`
		: baseURL

	const { data } = await api.get<Report>(requestURL)

	return data
}
