import { useMutation, useQueryClient } from '@tanstack/react-query'

import { toast } from 'sonner'

import { sentry } from '@/lib/sentry'

import { markNotificationsAsRead, updateNotificationPreferences } from './api'
import { notificationsKeys } from './queries'

import type { NotificationPreferences, NotificationsDTO } from './types'
import type { XiorError } from 'xior'

export const notificationsMutations = {
	all: ['notifications'],
	markNotificationsAsRead: () => [...notificationsMutations.all, 'read'],
	updateNotificationPreferences: () => [
		...notificationsMutations.all,
		'preferences',
	],
}

/**
 * Mark notifications as read
 */
export const useMarkNotificationsAsRead = () => {
	const queryClient = useQueryClient()
	const key = notificationsKeys.list({ is_read: false })

	return useMutation({
		mutationKey: notificationsMutations.markNotificationsAsRead(),
		mutationFn: (notificationIds: string[]) =>
			markNotificationsAsRead(notificationIds),

		onMutate: async (notificationIds) => {
			await queryClient.cancelQueries({
				queryKey: key,
			})

			// Snapshot the previous data
			const previousData = queryClient.getQueryData(key) as NotificationsDTO

			// Update the data by filtering out the marked notifications
			await queryClient.setQueryData(key, (oldData: NotificationsDTO) => {
				const notifications = oldData.notifications.filter(
					(notification) => !notificationIds.includes(notification.id),
				)

				return { ...oldData, notifications }
			})

			return { previousData }
		},

		onError: (error, variables, context) => {
			const err = error as XiorError
			const message = err.response?.data.error.message

			// Capture the error with the notification_ids
			sentry.captureException(err, {
				extra: {
					notification_ids: variables,
					context: 'markNotificationsAsReadMutation',
				},
			})

			toast.error('Failed to mark notifications as read', {
				description: message,
			})

			// Rollback the data
			queryClient.setQueryData(key, context?.previousData)
		},

		onSettled: async () => {
			// Reset the notifications list
			queryClient.invalidateQueries({
				queryKey: key,
			})

			// Reset the notifications list
			queryClient.invalidateQueries({
				queryKey: notificationsKeys.lists(),
			})
		},
	})
}

/**
 * Update notification preferences
 */
export const useUpdateNotificationPreferences = () => {
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: notificationsMutations.updateNotificationPreferences(),
		mutationFn: (preferences: NotificationPreferences[]) =>
			updateNotificationPreferences(preferences),

		onSuccess: () => {
			toast.success('Notification preferences updated')
		},

		onError: (error, variables) => {
			const err = error as XiorError
			const message = err.response?.data.error.message

			// Capture the error with the preferences
			sentry.captureException(message, {
				extra: {
					preferences: variables,
					context: 'updateNotificationPreferencesMutation',
				},
			})

			toast.error('Failed to update notification preferences', {
				description: message,
			})
		},

		onSettled: async () => {
			queryClient.invalidateQueries({
				queryKey: notificationsKeys.preferences(),
			})
		},
	})
}
