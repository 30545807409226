import React, { Suspense } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { LoadingButton } from '@/components/buttons/loading-button'
import { Label } from '@/components/ui/label'
import { Skeleton } from '@/components/ui/skeleton'

import {
	getRequiredFields,
	shouldDisplayLabel,
	shouldDisplayMessage,
} from '../utils'

import { FormInput } from './form-input'
import { FormMessage } from './form-message'

import type { FormSchema } from './types'
import type { FieldValues } from 'react-hook-form'

type SchemaFormProps = {
	formSchema: FormSchema
	onSubmit: (data: FieldValues) => void
	isLoading: boolean
	setIsLoading: (isLoading: boolean) => void
	buttonLabel?: string
}

export const SchemaForm: React.FC<SchemaFormProps> = ({
	formSchema,
	onSubmit,
	isLoading,
	setIsLoading,
	buttonLabel = 'Run',
}) => {
	const inputs = getRequiredFields(formSchema.input_schema)

	// Create a form with React Hook Form
	const form = useForm({
		// Set the mode to onBlur to validate the fields when they lose focus
		mode: 'onBlur',
	})

	const handleSubmit = form.handleSubmit(
		(data) => {
			setIsLoading(true)
			onSubmit(data)
		},
		(errors) => {
			// This is an exception for DatePickers/TimePickers since they don't have ref into the input
			if (errors) {
				const firstError = Object.keys(errors)[0]

				if (firstError) {
					const firstErrorRef = errors[firstError]?.ref as HTMLElement

					firstErrorRef.scrollIntoView({ behavior: 'smooth' })
				}
			}
		},
	)

	return (
		<FormProvider {...form}>
			<form
				onSubmit={handleSubmit}
				className="flex w-full max-w-full flex-col gap-4"
			>
				{inputs.map(([id, item]) => {
					return (
						<div key={id} className="w-full max-w-full">
							{/* Label */}
							{shouldDisplayLabel(item) && (
								<Label htmlFor={id} className="capitalize">
									{item.title || id}
									{item?.required ? (
										<span className="pl-1 text-base text-red-500">*</span>
									) : (
										''
									)}
								</Label>
							)}

							{/* Input component */}
							<Suspense fallback={<Skeleton className="h-12 w-full" />}>
								<FormInput id={id} item={item} />
							</Suspense>

							{shouldDisplayMessage(item) && (
								<FormMessage item={item} id={id} />
							)}
						</div>
					)
				})}

				{/* Submit button */}
				<div className="my-2 w-full">
					<LoadingButton isLoading={isLoading} type="submit">
						{buttonLabel}
					</LoadingButton>
				</div>
			</form>
		</FormProvider>
	)
}
