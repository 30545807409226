import { lazy } from 'react'

import { sentry } from '@/lib/sentry'

import { useErrorBoundary } from '@/hooks/useErrorBoundary'

import { Button } from '@/components/ui/button'

import { Subtitle, Title } from './card'

import type { XiorError } from 'xior'

const ThemeToggle = lazy(() => import('@/components/common/theme-toggle'))

/**
 * Error component for the auth pages
 * @param error - The error generate from errorComponent
 * @returns The error component
 */
export const AuthErrorComponent = ({ error }: { error: Error }) => {
	const { reset } = useErrorBoundary()
	const err = error as XiorError

	sentry.captureException(err, {
		extra: {
			origin: 'forgot-password-page',
		},
	})

	return (
		<>
			<Title>Error</Title>
			<Subtitle>Something went wrong</Subtitle>

			<Button type="button" onClick={() => reset()} variant="outline">
				Try again
			</Button>

			<div className="fixed right-4 top-4">
				<ThemeToggle />
			</div>
		</>
	)
}
