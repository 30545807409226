import { createFileRoute } from '@tanstack/react-router'

import { Subtitle, Title } from '@/features/auth/components/card'
import { AuthErrorComponent } from '@/features/auth/components/error-component'
import { SetupOTPForm } from '@/features/auth/components/forms/setup-totp'
import { AuthPendingComponent } from '@/features/auth/components/pending-component'
import { usePreloadRoute } from '@/hooks/use-preload-route'

export const Route = createFileRoute('/_auth/setup-totp')({
	component: SetupTotpRoute,
	pendingComponent: AuthPendingComponent,
	errorComponent: AuthErrorComponent,
})

function SetupTotpRoute() {
	usePreloadRoute({ to: '/forgot-password' })

	return (
		<>
			<title>Setup Time-Based One-Time Password | BitLyft Air®</title>

			<Title>Setup Two-Factor Authentication</Title>
			<Subtitle className="text-center">
				Scan QR code with authenticator app to receive one time code for this
				platform
			</Subtitle>

			<SetupOTPForm />
		</>
	)
}
