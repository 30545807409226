import { Link as TSRLink } from '@tanstack/react-router'

import { cn } from '@/utils'

import type { LinkProps as TSRLinkProps } from '@tanstack/react-router'
import type { HTMLAttributes } from 'react'

type TitleProps = HTMLAttributes<HTMLHeadingElement> & {
	children: React.ReactNode
}
/**
 * Title of the card
 * @param {React.ReactNode} children - Text of the title
 */
export const Title = ({ children, ...props }: TitleProps) => {
	return (
		<h1
			className="my-4 text-center text-2xl font-bold text-text-primary"
			{...props}
		>
			{children}
		</h1>
	)
}

type SubtitleProps = HTMLAttributes<HTMLParagraphElement> & {
	children: React.ReactNode
}
/**
 * Subtitle of the card
 * @param {React.ReactNode} children- Text of the subtitle
 */
export const Subtitle = ({ children, className, ...props }: SubtitleProps) => {
	return (
		<p
			className={cn(
				'mb-2 text-sm font-light leading-5 text-text-secondary',
				className,
			)}
			{...props}
		>
			{children}
		</p>
	)
}

type DividerProps = HTMLAttributes<HTMLDivElement>
/**
 * Horizontal line divider
 */
export const Divider = ({ ...props }: DividerProps) => {
	return (
		<div className="divider my-4 h-[1px] w-full bg-text-disabled" {...props} />
	)
}

type LinkProps = TSRLinkProps & {
	children: React.ReactNode
}
/**
 * Link at the bottom of the card
 * @param {string} to - Link to another page
 * @param {React.ReactNode} children - Text to display
 * @returns
 */
export const Link = ({ to, children, ...props }: LinkProps) => {
	return (
		<TSRLink to={to} {...props}>
			<p className="text-sm font-light leading-5 text-text-secondary hover:underline">
				{children}
			</p>
		</TSRLink>
	)
}
