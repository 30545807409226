import { useEffect } from 'react'

import { createFileRoute, Link, useRouter } from '@tanstack/react-router'

import { Divider, Subtitle, Title } from '@/features/auth/components/card'
import { AuthErrorComponent } from '@/features/auth/components/error-component'
import { LoginForm } from '@/features/auth/components/forms/login'
import { AuthPendingComponent } from '@/features/auth/components/pending-component'

export const Route = createFileRoute('/_auth/login')({
	component: LoginRoute,
	pendingComponent: AuthPendingComponent,
	errorComponent: AuthErrorComponent,
})

function LoginRoute() {
	const router = useRouter()

	// Preload the TOTP route since it's the most likely next step
	useEffect(() => {
		async function preload() {
			try {
				await router.preloadRoute({
					to: '/totp',
				})
			} catch (error) {
				console.error(error)
			}
		}

		preload()
	}, [router])

	return (
		<>
			<title>Login | BitLyft Air®</title>

			<Title>Log in</Title>
			<Subtitle>Sign in to access BitLyft AIR</Subtitle>

			<LoginForm />

			<Divider />

			<Link to="/forgot-password" preload="intent">
				Forgot your password?
			</Link>
		</>
	)
}
