import { createFileRoute, Link } from '@tanstack/react-router'

import { Divider, Subtitle, Title } from '@/features/auth/components/card'
import { AuthErrorComponent } from '@/features/auth/components/error-component'
import { ResetPasswordForm } from '@/features/auth/components/forms/reset-password'
import { AuthPendingComponent } from '@/features/auth/components/pending-component'

export const Route = createFileRoute('/_auth/reset-password')({
	component: ResetPasswordRoute,
	pendingComponent: AuthPendingComponent,
	errorComponent: AuthErrorComponent,
})

function ResetPasswordRoute() {
	const navigate = Route.useNavigate()

	const handleSuccess = () => {
		navigate({
			to: '/login',
		})
	}

	return (
		<>
			<title>Reset Password | BitLyft Air®</title>
			<Title>Reset Password</Title>

			<Subtitle>Enter your new password</Subtitle>

			<ResetPasswordForm onSuccess={handleSuccess} />

			<Divider />

			<Link to="/login">Return to Sign In</Link>
		</>
	)
}
