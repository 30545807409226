import { cn } from '@/utils'
import { X } from 'lucide-react'

import { Button } from '../ui/button'
import { Input } from '../ui/input'

type SearchBarProps = {
	query: string
	placeholder?: string
	setQuery: (query: string) => void
	className?: string
}

export const SearchBar = ({
	query,
	setQuery,
	placeholder,
	className,
}: SearchBarProps) => {
	return (
		<div
			className={cn(
				'relative order-2 flex w-full flex-row items-center justify-start gap-4 sm:order-1',
				className,
			)}
		>
			<Input
				type="text"
				name="search"
				data-testid="search-bar"
				onChange={(event) => {
					setQuery(event.target.value)
				}}
				value={query}
				placeholder={placeholder ?? 'Search for "Name" or "Description"'}
				className="h-12 max-w-full"
				tabIndex={0}
			/>
			{query && query.length > 0 && (
				<Button
					size="icon"
					variant="ghost"
					title="Clear search"
					data-testid="clear-search-button"
					name="clear_search"
					onClick={() => setQuery('')}
					className="absolute inset-y-1 right-1"
				>
					<X />
				</Button>
			)}
		</div>
	)
}
