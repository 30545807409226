import { env } from '@/env'

import packageJson from '../../package.json'

// ENVIRONMENT
export const isDEV = import.meta.env.DEV
export const isPROD = import.meta.env.PROD
export const isTEST = env.VITE_TEST
export const isMOCK = env.VITE_USE_MOCK_DATA
export const appVersion = packageJson.version

// API
export const API_URL = env.VITE_AIR_API_URL
