import { Hub } from 'aws-amplify/utils'

import { env } from '@/env'

import type { ResourcesConfig } from 'aws-amplify'

// AMPLIFY/COGNITO
export const amplifyConfig: ResourcesConfig = {
	Auth: {
		Cognito: {
			signUpVerificationMethod: 'code',
			userPoolId: env.VITE_AWS_USER_POOLS_ID,
			userPoolClientId: env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
			loginWith: {
				email: true,
				phone: false,
				username: true,
			},
		},
	},
}

/**
 * Listen to Amplify events
 * @see https://docs.amplify.aws/react/build-a-backend/auth/connect-your-frontend/listen-to-auth-events/
 */
Hub.listen('auth', ({ payload }) => {
	switch (payload.event) {
		case 'signedIn':
			console.log('user have been signedIn successfully.')
			break
		case 'signedOut':
			console.log('user have been signedOut successfully.')
			break
		case 'tokenRefresh':
			console.log('auth tokens have been refreshed.')
			break
		case 'tokenRefresh_failure':
			console.log('failure while refreshing auth tokens.')
			break
		case 'signInWithRedirect':
			console.log('signInWithRedirect API has successfully been resolved.')
			break
		case 'signInWithRedirect_failure':
			console.log('failure while trying to resolve signInWithRedirect API.')
			break
		case 'customOAuthState':
			console.log('custom state returned from CognitoHosted UI')
			break
	}
})
