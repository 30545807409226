import { useMemo, useState } from 'react'

import { Contact, Settings } from 'lucide-react'

import { useMe } from '@/features/user/queries'
import { getTenantNameById } from '@/features/user/utils'
import { useIsDesktop } from '@/hooks/useIsDesktop'

import { LoadingButton } from '@/components/buttons/loading-button'
import { DataTable } from '@/components/common/data-table'
import { Button } from '@/components/ui/button'
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { useDeleteTenantContact } from '../mutations'
import { useTenantContacts } from '../queries'

import ContactDirectorySearch from './contact-search'

import type { TenantContact } from '@/features/tenant/types'
import type { ColumnDef, TableOptions } from '@tanstack/react-table'

type ContactDirectoryTableProps = {
	query: string
	onQueryChange: (query: string) => void
	onCreateContact: () => void
	onEditContact: (contact: TenantContact) => void
}

export const ContactDirectoryTable = ({
	query,
	onQueryChange,
	onCreateContact,
	onEditContact,
}: ContactDirectoryTableProps) => {
	const {
		data: { allowed_tenants },
	} = useMe()
	const { data } = useTenantContacts()
	const { mutate } = useDeleteTenantContact()

	const isDesktop = useIsDesktop()

	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [selectedContact, setSelectedContact] = useState<TenantContact | null>(
		null,
	)

	const handleDeleteContact = (contact: TenantContact) => {
		setSelectedContact(contact)
		setIsDialogOpen(true)
	}

	const deleteContact = async () => {
		setIsLoading(true)

		mutate(selectedContact?.name as string, {
			onSettled: () => {
				setIsDialogOpen(false)
				setSelectedContact(null)
				setIsLoading(false)
			},
		})
	}

	const columns: ColumnDef<TenantContact>[] = useMemo(
		() => [
			{
				accessorKey: 'tenant',
				id: 'tenant',
				header: 'Tenant',
				cell: ({ row }) => {
					return (
						<p>{getTenantNameById(row.getValue('tenant'), allowed_tenants)}</p>
					)
				},
			},
			{
				accessorKey: 'email',
				id: 'email',
				header: 'Email',
			},
			{
				accessorKey: 'name',
				id: 'name',
				header: 'Name',
			},

			{
				accessorKey: 'title',
				id: 'title',
				header: 'Title',
			},
			{
				enableSorting: false,
				enableColumnFilter: false,
				size: 50,
				maxSize: 100,
				id: 'actions',
				cell: ({ row }) => {
					return (
						<div className="flex items-center justify-end">
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button variant="outline" size="icon">
										<Settings className="h-5 w-5" />
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent className="flex w-32 flex-col gap-2 bg-background p-2">
									<DropdownMenuItem asChild>
										<Button
											variant="ghost"
											onClick={() => onEditContact(row.original)}
											className="w-full cursor-pointer"
										>
											Edit
										</Button>
									</DropdownMenuItem>
									<DropdownMenuItem asChild>
										<Button
											onClick={() => handleDeleteContact(row.original)}
											variant="destructive"
											className="w-full cursor-pointer"
										>
											Delete
										</Button>
									</DropdownMenuItem>
								</DropdownMenuContent>
							</DropdownMenu>
						</div>
					)
				},
			},
		],
		[],
	)

	const tableOptions: Partial<TableOptions<TenantContact>> = {
		enableSorting: true,
		enableSortingRemoval: false,
		initialState: {
			sorting: [
				{
					id: 'name',
					desc: false,
				},
			],
		},
		state: {
			globalFilter: query,
			columnVisibility: {
				// True means visible, false means hidden
				name: isDesktop,
				tenant: isDesktop,
				title: isDesktop,
			},
		},
	}

	return (
		<div className="flex w-full flex-col items-start justify-start gap-6">
			<div className="flex w-full flex-row items-center justify-between gap-2">
				<ContactDirectorySearch
					query={query}
					onQueryChange={onQueryChange}
					onEditContact={onEditContact}
				/>

				<Button
					variant="outline"
					className="h-12 gap-2"
					onClick={onCreateContact}
				>
					Add Contact
					<Contact className="h-5 w-5" />
				</Button>
			</div>

			<DataTable data={data} columns={columns} options={tableOptions} />

			<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Are you sure?</DialogTitle>
						<DialogDescription>
							This action cannot be undone. This will permanently delete the
							user{' '}
							<code className="bg-foreground p-1">{selectedContact?.name}</code>
							.
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<DialogClose asChild>
							<Button type="button" variant="secondary">
								Cancel
							</Button>
						</DialogClose>
						<LoadingButton
							isLoading={isLoading}
							type="button"
							variant="destructive"
							onClick={deleteContact}
						>
							Delete
						</LoadingButton>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</div>
	)
}
